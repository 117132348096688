<template>
  <b-modal
    id="payment-options"
    ref="payment-options-modal"
    v-model="showModal"
    centered
    hide-footer
    hide-header
    scrollable
    size="xl"
  >
    <!-- Payment Options -->

    <template #default="{ close }">
      <b-row align-h="end" class="mb-3">
        <b-col>
          <strong class="title">결제 플랜 선택</strong>
        </b-col>
        <b-col class="text-right">
          <b-icon-x @click="close()" style="cursor: pointer" />
        </b-col>
      </b-row>

      <Options
        :address="team.address"
        :number="team.number"
        :teamId="team.teamId"
      />

      <small class="mt-3">
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </template>
  </b-modal>
</template>

<script>
import Options from "@/components/Payment/Options.vue";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    this.showModal = this.show;
  },
  watch: {
    show(val) {
      this.showModal = val;
    },
    showModal(val) {
      this.$emit("change", val);
    },
  },
  components: {
    Options,
  },
};
</script>