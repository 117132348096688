<template>
  <div>
    <b-overlay
      :show="overlay"
      spinner-variant="dark"
      style="min-height: 90vh"
      spinner-type="grow"
    >
      <div v-if="user != null">
        <!-- Scan -->
        <b-modal id="scan" centered hide-footer no-close-on-backdrop>
          <template #modal-header v-if="scanInProgress">
            <h3><strong class="title">시험지 바로 스캔</strong></h3>
          </template>
          <template v-slot:modal-title>
            <h3>
              <strong class="title">시험지 바로 스캔</strong>
            </h3>
            <b-row class="mt-2" align-h="start">
              <b-col cols="auto">
                <b-form-checkbox v-model="scanImage" size="lg" switch>
                  {{ scanImage ? "카메라 스캔 ON" : "PDF 스캔 ON" }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="scanImage"
                  v-model="camera"
                  size="lg"
                  switch
                >
                  {{
                    camera
                      ? "카메라 (갤러리 모드: OFF)"
                      : "갤러리 (카메라 모드: OFF)"
                  }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </template>
          <Scan
            v-if="scanImage"
            @refresh="getUser"
            @scan="scanInProgress = !scanInProgress"
            :userId="user.userId"
            :camera="camera"
          />
          <ScanPDF
            v-else
            @refresh="getUser"
            @scan="scanInProgress = !scanInProgress"
            :userId="user.userId"
          />
          <small>
            문제를 겪고 계시다면,
            <b-link :href="`mailto:erudiolabs@gmail.com`"
              >여기로 연락 부탁드립니다.</b-link
            >
          </small>
        </b-modal>

        <TopHero
          :teamName="user.teamName"
          :admin="user.admin"
          :tier="user.tier"
          :balance="user.balance"
          :imageUrl="user.imageUrl"
        />
        <b-container fluid class="my-5">
          <b-row class="mt-4 justify-content-md-center">
            <b-col xl="8">
              <b-tabs content-class="mt-3" pills>
                <b-tab v-if="user.admin" active lazy>
                  <template #title> 팀 관리 (Manage Team) </template>
                  <ManageTeam @refresh="getUser"
                /></b-tab>

                <b-tab v-if="user.admin && user.users > 1" lazy>
                  <template #title> 팀 강의실 (Team Classrooms) </template>
                  <TeamClassrooms :teamId="user.teamId"
                /></b-tab>

                <b-tab
                  title="나의 강의들 (My Classrooms)"
                  :active="!user.admin"
                  lazy
                  ><p>
                    <Classrooms
                      :userId="user.userId"
                      :classroomIds="user.classroomIds"
                    /></p
                ></b-tab>

                <b-tab title="스캔 내역 (Recently Submitted)" lazy
                  ><Results :userId="user.userId"
                /></b-tab>
                <b-tab title="학생 데이터 조회 (Students)" lazy
                  ><Students :admin="user.admin"
                /></b-tab>

                <b-tab title="설정 (Settings)" lazy
                  ><Settings @refresh="getUser"
                /></b-tab>

                <b-tab title="툴박스 (Toolbox)" lazy><Toolbox /></b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-overlay>
    <Footer />
  </div>
</template>

<script>
import ChannelService from "../ChannelService.js";

import TopHero from "@/components/Home/TopHero.vue";
import Footer from "@/components/Footer.vue";

import ManageTeam from "@/components/Admin/ManageTeam.vue";
import Students from "@/components/Student/Students.vue";
import Settings from "@/components/User/Settings.vue";
import Results from "@/components/Results/Results.vue";

import TeamClassrooms from "@/components/Classroom/TeamClassrooms.vue";
import Classrooms from "@/components/Classroom/Classrooms.vue";
import Scan from "@/components/Home/Scan.vue";
import ScanPDF from "@/components/Home/ScanPDF.vue";

import Toolbox from "@/components/Toolbox.vue";

export default {
  components: {
    TeamClassrooms,
    TopHero,
    Footer,
    ManageTeam,
    Classrooms,
    Students,
    Results,
    Settings,
    Scan,
    ScanPDF,
    Toolbox,
  },
  data() {
    return {
      scanInProgress: false,
      scanImage: true,
      camera: true,
      overlay: true,
      user: null,
    };
  },
  computed: {
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
  },
  mounted() {
    ChannelService.shutdown();
    window.scrollTo(0, 0);

    if (!this.email) {
      alert("먼저 로그인을 해주시기 바랍니다.");
      this.$router.push({ path: "/" });
    } else {
      this.getUser();
      ChannelService.boot({
        pluginKey: "46970e6f-ad3f-456a-bdbe-577520715687", //please fill with your plugin key
        profile: {
          name: `${this.name} (${this.email})`, //fill with user name
          email: this.email, //fill with user phone number
        },
      });
    }
  },
  methods: {
    getUser: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/user/info`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.user = json;
            this.$store.dispatch("setTeamNameAction", json.teamName);
            this.overlay = false;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // verify: function() {
    //     const token = localStorage.getItem('token')
    //     if(token) {
    //         console.log(token)
    //     } else {
    //         alert("unauthorized access!")
    //         this.$router.push({ path: "/" });
    //     }
    // }
  },
};
</script>