<template>
  <div>
    <b-modal
      id="add-students-modal"
      ref="add-students-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">학생정보 연동</strong></h3>
      </template>
      <div class="mb-3">
        <b-tabs pills content-class="mt-3" justified>
          <b-tab title="학생 바로 추가" active
            ><b-card-text><AddStudent @imported="added" /></b-card-text></b-tab
          >
          <b-tab title="학생 엑셀 연동"
            ><b-card-text> <ImportStudents @imported="added" /> </b-card-text
          ></b-tab>
        </b-tabs>
      </div>

      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>
  </div>
</template>

<script>
import AddStudent from "@/components/Student/AddStudent.vue";
import ImportStudents from "@/components/Admin/ImportStudents.vue";
export default {
  components: {
    AddStudent,
    ImportStudents
  },
  methods: {
    added: function () {
      this.$refs["add-students-modal"].hide();
      alert("성공적으로 학생(들)이 추가 되었습니다.")
      this.$emit("added");
    },
  }
}
</script>