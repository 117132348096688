<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="verify">
        <div class="form-group">
          <b-form-input
            type="text"
            size="lg"
            name="code"
            class="form-control"
            placeholder="학생 코드 *"
            v-model="code"
            autocomplete="off"
            :disabled="overlay"
            :state="code.length == 8 ? true : false"
            required
          />
        </div>

        <b-button
          block
          type="submit"
          size="lg"
          variant="dark"
          :disabled="overlay || code.length != 8"
          ><strong>확인하기</strong></b-button
        >

        <small class="form-text text-muted text-right">
          확인과 동시에, 오남용 시 추적을 위한 IP 주소 및 관련 유저 정보 기록에
          동의합니다. <br />
          {{ classroomId }}
        </small>
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "student",
  props: {
    teamId: String,
    classroomId: String,
  },
  data: function () {
    return {
      code: "",
      overlay: false,
    };
  },
  methods: {
    verify: function (e) {
      const url = this.$url;

      this.overlay = true;
      e.preventDefault();

      const student = {
        classroomId: this.classroomId,
        code: this.code,
        teamId: this.teamId,
      };

      fetch(url + "/api/class/student", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(student),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            throw new Error(json.error);
          } else {
            this.overlay = false;
            this.$emit("verified", json.student);
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
          this.name = "";
          this.code = "";
        });
    },
  },
};
</script>
