<template>
  <div id="section">
    <b-jumbotron
      :style="`background: ${content.backgroundColor}; min-height: 50vh;  margin: 0`"
      class="d-flex align-items-center justify-content-center rounded-0"
      :ref="`section-${idx}`"
    >
      <b-row align-h="center" >
        <b-col>
          <div
            :style="
              animation
                ? `animation: ${content.animation}`
                : `visibility:hidden`
            "
          >
            <b-row align-v="center" align-h="center" style="min-width: 100%">
              <b-col class="my-3" md="6" lg="5" xl="4" v-if="content.imageLeft">
                <b-img-lazy
                  :src="content.image"
                  rounded
                  fluid
                  show
                ></b-img-lazy>
              </b-col>
              <b-col
                :class="content.imageLeft ? `text-right my-auto` : 'my-auto'"
                md="6"
                xl="4"
              >
                <b-badge
                  variant="primary"
                  class="my-2 mr-1"
                  v-for="(tag, tagIndex) in content.tags"
                  v-bind:key="tagIndex"
                  >{{ tag }}</b-badge
                >

                <h1>
                  <strong class="sub-title" v-html="content.title"></strong>
                </h1>
                <small v-html="content.desc"> </small>
                <br />
                <div class="mt-3" v-if="content.buttons || content.audio">
                  <!-- Audio -->
                  <div
                    class="mt-2 mx-2 d-flex align-items-center title"
                    v-for="(audio, audioIdx) in content.audio"
                    v-bind:key="`audio-${audioIdx}`"
                  >
                    <strong class="mr-3">{{ audio.title }}</strong>
                    <audio controls :src="audio.src">
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                  </div>

                  <!-- Buttons -->
                  <b-button
                    class="mr-2 title"
                    target="_blank"
                    variant="light"
                    v-for="(button, buttonIdx) in content.buttons"
                    v-bind:key="`actionButton-${buttonIdx}`"
                    :to="{ path: button.path }"
                    :href="button.href"
                    ><strong>{{ button.title }}</strong></b-button
                  >
                </div>
              </b-col>
              <b-col class="my-3" md="6" lg="5" xl="4" v-if="!content.imageLeft">
                <b-img-lazy
                  :src="content.image"
                  rounded
                  show
                  fluid
                ></b-img-lazy>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  props: {
    idx: Number,
    content: Object,
  },
  mounted() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    let observer = new IntersectionObserver((e) => {
      console.log(e);
      if (e[0].isIntersecting) {
        this.animation = true;
      } else {
        this.animation = false;
      }
      console.log(this.animation);
    }, options);

    observer.observe(this.$refs[`section-${this.idx}`]);
  },
  data() {
    return {
      animation: false,
    };
  },
};
</script>