<template>
  <div>
    <!-- Exam Settings -->
    <b-modal
      id="exam-settings"
      :ref="`${classroomId}-exam-settings-modal`"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">시험설정</strong></h3>
      </template>
      <ExamSettings
        :examId="examSelected"
        :classroomId="classroomId"
        @deleted="examDeleted"
        @edited="examEdited"
      />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Exam evaluation Settings -->
    <b-modal
      id="exam-evaluation-settings"
      :ref="`${classroomId}-exam-evaluation-settings-modal`"
      centered
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <template v-slot:modal-title>
        <h3><strong class="title">평가 코멘트 추가하기</strong></h3>
      </template>
      <EvaluationComments
        :examId="examSelected"
        :classroomId="classroomId"
        @edited="examEdited"
      />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Edit Exam -->
    <b-modal id="edit-exam" :ref="`edit-exam-modal`" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">시험 수정</strong></h3>
      </template>
      <EditExam :examId="examSelected" @edited="examEdited" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <div v-if="exams != null">
      <div v-if="exams.length > 0">
        <b-row align-v="center">
          <b-col class="text-left my-1" md="9">
            <h3 class="ml-3">강의 시험내역</h3>
          </b-col>
          <b-col class="text-right my-1" md="3">
            <b-form-input
              v-model="filter"
              placeholder="시험 검색"
            ></b-form-input>
          </b-col>
        </b-row>

        <hr />

        <b-row align-v="center">
          <b-col style="width: 100%; overflow-x: auto">
            <b-table hover :items="exams" :fields="fields" :filter="filter">
              <template #cell(name)="row">
                {{ row.item.name }}({{ row.item.questionNum }})
              </template>

              <template #cell(examCreated)="row">
                {{
                  new Date(row.item.examCreated * 1000).toLocaleDateString(
                    "en-US"
                  )
                }}
              </template>

              <template #cell(action)="row">
                <b-button
                  size="sm"
                  variant="white"
                  class="mx-1"
                  :id="`dl-exam-result-${row.item.examId}`"
                  @click="downloadResults(row.item.examId)"
                >
                  <b-icon-cloud-download />
                </b-button>
                <b-tooltip
                  :target="`dl-exam-result-${row.item.examId}`"
                  triggers="hover"
                  top
                >
                  결과 엑셀 다운로드
                </b-tooltip>

                <b-button
                  size="sm"
                  variant="white"
                  class="mx-1"
                  :id="`exam-evaluation-${row.item.examId}`"
                  @click="examEvaluation(row.item.examId)"
                >
                  <b-icon-journal-text />
                </b-button>
                <b-tooltip
                  :target="`exam-evaluation-${row.item.examId}`"
                  triggers="hover"
                  top
                >
                  평가 코멘트 추가
                </b-tooltip>

                <b-button
                  size="sm"
                  variant="white"
                  class="mx-1"
                  :id="`edit-exam-${row.item.examId}`"
                  @click="editExam(row.item.examId)"
                >
                  <b-icon-pen />
                </b-button>
                <b-tooltip
                  :target="`edit-exam-${row.item.examId}`"
                  triggers="hover"
                  top
                >
                  시험 수정
                </b-tooltip>

                <b-button
                  size="sm"
                  variant="white"
                  class="mx-1"
                  :id="`exam-settings-${row.item.examId}`"
                  @click="examSettings(row.item.examId)"
                >
                  <b-icon-gear />
                </b-button>
                <b-tooltip
                  :target="`exam-settings-${row.item.examId}`"
                  triggers="hover"
                  top
                >
                  시험 설정
                </b-tooltip>
                <!-- <b-button
                size="sm"
                variant="danger"
                class="mx-1"
                @click="deleteExam(row.item.examId)"
              >
                <b-icon-trash />
              </b-button> -->
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <div v-else><NotFound obj="시험" /></div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import NotFound from "@/components/Exam/NotFound.vue";

import EditExam from "@/components/Exam/EditExam.vue";
import ExamSettings from "@/components/Exam/ExamSettings.vue";
import EvaluationComments from "@/components/Exam/EvaluationComments.vue";
const saveAs = require("file-saver");

export default {
  components: {
    NotFound,
    EditExam,
    ExamSettings,
    EvaluationComments,
  },
  props: {
    classroomId: String,
    examIds: Array,
  },
  data() {
    return {
      filter: "",
      examSelected: null,
      exams: null,
      fields: [
        {
          key: "code",
          label: "시험 코드",
          sortable: true,
        },
        {
          key: "name",
          label: "시험 이름",
          sortable: true,
        },

        // {
        //   key: "questionNum",
        //   label: "문항 수",
        //   sortable: true,
        // },
        {
          key: "examCreated",
          label: "생성 시점",
          sortable: true,
        },
        {
          key: "action",
          label: "액션",
        },
      ],
    };
  },
  methods: {
    downloadResults: function (examId) {
      const url = `${this.$apiUrl}/scanner/download/result`;

      let token = localStorage.getItem("token");

      fetch(url + `/exam`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ examId }),
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          saveAs(blob, `exam-${examId}.csv`);
        })
        .catch((error) => {
          alert(error);
        });
    },
    examSettings: function (examId) {
      this.examSelected = examId;
      this.$refs[`${this.classroomId}-exam-settings-modal`].show();
      console.log(examId);
    },
    examEvaluation: function (examId) {
      this.examSelected = examId;
      this.$refs[`${this.classroomId}-exam-evaluation-settings-modal`].show();
      console.log(examId);
    },
    editExam: function (examId) {
      this.examSelected = examId;
      this.$refs[`edit-exam-modal`].show();
      console.log(examId);
    },
    examDeleted: function () {
      this.$refs[`${this.classroomId}-exam-settings-modal`].hide();

      this.$emit("deleted");
    },

    examEdited: function () {
      this.$refs[`${this.classroomId}-exam-settings-modal`].hide();
      this.$refs[`edit-exam-modal`].hide();
      // this.$refs[`${this.classroomId}-exam-evaluation-settings-modal`].hide();
      this.getExams();
    },
    getExams: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      fetch(url + `/api/class/exams/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ classroomId: this.classroomId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.exams = json.exams;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    getExamInfo: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const exams = {
        examIds: this.examIds,
      };

      fetch(url + `/api/class/exams/info/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exams),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.exams = json.exams;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  mounted() {
    this.getExams();
  },
};
</script>