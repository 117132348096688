<template>
  <b-jumbotron
    :style="`background-image: url('${imageUrl}'); min-height: 20rem;  background-size: cover;`"
    class="rounded-0"
  >
    <AddStudentModal />
    <!-- Enquiry -->
    <b-modal id="enquiry-modal" ref="enquiry-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">OMR 스캐너와 함깨</strong></h3>
        아래 정보들을 입력하여 문의 해주시기 바랍니다.
      </template>
      <Enquiry @submitted="submitted" :userName="name" :userEmail="email" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다.</b-link
        >
      </small>
    </b-modal>

    <!-- Team Scanners -->
    <b-modal id="team-scanners-modal" ref="team-scanners-modal" centered hide-footer hide-header>
       <template #default="{ close }">
        <b-row align-h="end" class="mb-3">
          <b-col>
            <strong class="title">{{teamName}} 스캐너</strong>
          </b-col>
          <b-col class="text-right">
            <b-icon-x @click="close()" style="cursor: pointer" />
          </b-col>
        </b-row>

      <Scanners />



        <small class="mt-3">
          문제를 겪고 계시다면,
          <b-link :href="`mailto:erudiolabs@gmail.com`"
            >여기로 연락 부탁드립니다</b-link
          >
        </small>
      </template>
    </b-modal>

    <b-row class="justify-content-md-center">
      <b-col xl="10">
        <b-card
          tag="article"
          style="max-width: 27.5rem; min-height: 14rem"
          class="mb-2 justify-content-center"
        >
          <b-row class="py-3">
            <b-col>
              <b-card-text>
                {{ teamName }}, <span v-if="admin">관리자 권한</span
                ><span v-else>맴버 권한</span>
                <b-button
                  size="sm"
                  variant="white"
                  id="team-scanners"
                  v-b-modal.team-scanners-modal
                >
                  <b-icon icon="people" ></b-icon
                ></b-button>
                <br />
                <small v-if="tier == 0"
                  ><strong
                    >잔고: {{ balance.toLocaleString() }} 코인</strong
                  ></small
                >
              </b-card-text>

              <b-tooltip target="team-scanners" triggers="hover" top>
                팀원들의 스캐너
              </b-tooltip>

              <h2>
                <strong
                  >환영합니다, <br />
                  {{ name }} 님</strong
                >
              </h2>
              <b-card-text class="lead mt-2">
                <b-button
                  variant="white"
                  v-b-tooltip.hover.topright="'도움이 필요하시면 눌러주세요!'"
                  v-b-modal.enquiry-modal
                  style="margin-left: -5px"
                  ><b-icon-question-circle /></b-button
                >{{ email }}
              </b-card-text>

              <b-card-text>
                <b-button block variant="dark" v-b-modal.scan
                  ><b-icon icon="file-earmark-break-fill"></b-icon> 시험지
                  스캔하기</b-button
                >
              </b-card-text>


              <b-card-text>
                <b-button
                  variant="success"
                  v-b-modal.add-students-modal
                  block
                  ><b-icon icon="person-fill"></b-icon> 학생 연동</b-button
                >
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-jumbotron>
</template>

<script>
import Enquiry from "@/components/Home/Enquiry.vue";
import AddStudentModal from "@/components/Student/AddStudentModal.vue";

import Scanners from "@/components/Home/TeamScanners.vue";

export default {
  components: {
    Enquiry,
    Scanners,
    AddStudentModal
  },
  props: {
    teamName: String,
    admin: Boolean,
    balance: Number,
    tier: Number,
    imageUrl: String,
  },
  computed: {
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
  },
  methods: {
    submitted: function () {
      this.$refs["enquiry-modal"].hide();
    },
  },
};
</script>