<template>
  <b-container class="my-5">
    <b-row class="justify-content-md-center" cols="1">
      <b-col
        col
        id="option"
        md="3"
        class="text-center my-2 d-flex align-items-stretch"
        v-for="(card, cardIndex) in cards"
        v-bind:key="cardIndex"
      >
        <b-card
          style="
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            min-width: 100%;
          "
          border-variant="light"
          @click="paymentRequest(card.title, card.price)"
        >
          <div class="mt-3">
            <h3>
              <strong>{{ card.title }}</strong>
            </h3>
            <p class="my-4" v-html="card.desc"></p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import { v4 as uuidv4 } from "uuid";

import { loadTossPayments } from "@tosspayments/sdk";
const clientKey = ["8080", "3000"].includes(window.location.port)
  ? "test_ck_aBX7zk2yd8yy5oJLRRL8x9POLqKQ"
  : "live_ck_OyL0qZ4G1VO4dG15PJvroWb2MQYg";

export default {
  props: {
    address: String,
    number: String,
    teamId: String,
  },
  computed: {
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
  },
  data() {
    return {
      animation: false,
      cards: [
        {
          title: "200 코인",
          desc: "<mark><strong>10,000원</strong></mark>으로 <br/><strong>200건</strong>의 SMS 문자 전송 & <strong>OMR 스캐너의 모든 기능</strong> 제공",
          price: "10000",
        },
        {
          title: "700 코인",
          desc: "<mark><strong>30,000원</strong></mark>으로 <br/><strong>700건</strong>의 SMS 문자 전송 & <strong>OMR 스캐너의 모든 기능</strong> 제공",
          price: "30000",
        },
        {
          title: "1,200 코인",
          desc: "<mark><strong>50,000원</strong></mark>으로 <br/><strong>1,200건</strong>의 SMS 문자 전송 & <strong>OMR 스캐너의 모든 기능</strong> 제공",
          price: "50000",
        },
      ],
    };
  },
  methods: {
    paymentRequest: function (name, price) {
      console.log(clientKey);
      let orderId = `${uuidv4()}`;
      console.log(uuidv4());

      loadTossPayments(clientKey).then((tossPayments) => {
        tossPayments.requestPayment("카드", {
          amount: price,
          orderId: orderId,
          orderName: name,
          customerName: this.name,
          customerEmail: this.email,
          customerMobilePhone: this.number,
          successUrl: window.location.origin + "/payment/success",
          failUrl: window.location.origin + "/payment/fail",
          maxCardInstallmentPlan: 3,
        });
      });
    },
  },
};
</script>

<style scoped>
#option {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  cursor: pointer;
}

#option:hover {
  top: -10px;
}
</style>