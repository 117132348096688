<template>
  <div>
    <div v-if="exam == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else>
      <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
        <form @submit.prevent="editEvaluationComment">
          <div
            class="form-group"
            v-for="(evaluation, idx) in evaluationComments"
            v-bind:key="`eval-${idx}`"
          >
            <b-row class="mb-1">
              <b-col>
                <b-form-select
                  v-model="evaluation.category"
                  :options="categories"
                >
                  <b-form-select-option :value="null"
                    >종합 평가</b-form-select-option
                  >
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-input
                  type="number"
                  v-model="evaluation.range[0]"
                  placeholder="점수범위 시작"
                  required
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-input
                  type="number"
                  v-model="evaluation.range[1]"
                  placeholder="점수범위 끝"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button
                  block
                  variant="danger"
                  class="mb-3"
                  @click="removeEvaluation(idx)"
                  ><b-icon-x />
                </b-button>
              </b-col>
            </b-row>

            <b-form-input
              type="text"
              class="form-control"
              v-model="evaluation.content"
              placeholder="해당 점수에 적용되는 평가 멘트 추가하기"
              :disabled="overlay"
              autocomplete="off"
              required
            />
            <hr />
          </div>

          <b-button block variant="dark" class="mb-3" @click="addEvaluation">
            평가 추가 하기 <b-icon-plus />
          </b-button>

          <b-row class="mt-3" align-h="center">
            <b-col>
              <b-button
                block
                type="submit"
                variant="success"
                :disabled="overlay"
                ><strong>저장하기</strong></b-button
              >
            </b-col>
          </b-row>
          <small v-if="false">{{ examId }} - {{ classroomId }}</small>
        </form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examId: String,
    classroomId: String,
  },
  data() {
    return {
      evaluationComments: [],
      categories: [],
      overlay: false,
      exam: null,
    };
  },
  mounted() {
    this.getExam();
  },
  methods: {
    addEvaluation: function () {
      this.evaluationComments.push({
        category: null,
        range: [null, null],
        content: "",
      });
    },
    removeEvaluation: function (idx) {
      this.evaluationComments.splice(idx, 1);
    },
    editEvaluationComment: function () {
      console.log(this.evaluationComments);

      const url = this.$url;

      let token = localStorage.getItem("token");

      const exam = {
        examId: this.examId,
        classroomId: this.classroomId,
        evaluationComments: this.evaluationComments,
      };
      this.overlay = true;

      fetch(url + `/api/class/edit/exam/comments`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            if (json.status) {
              this.$emit("edited");
              alert("성공적으로 저장되었습니다!");
              this.overlay = false;
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
        });
    },
    getExam: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const exam = { examId: this.examId, classroomId: this.classroomId };

      fetch(url + `/api/class/exam/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.exam = json.exam;
            this.categories = json.exam.answers.reduce((a, e) => {
              // if(i == 0){
              //     a = [...a, e.questionCategory]
              // } else {

              // }
              a = [...new Set([...a, e.questionCategory])];
              return a;
            }, []);
            this.evaluationComments = json.exam.evaluationComments;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>