<template>
  <div>
    <div>
      <div class="d-flex justify-content-center mb-3">
        <div class="text-center">
          <b-spinner
            class="mt-5 mb-4"
            label="Loading..."
            style="width: 3rem; height: 3rem"
          >
          </b-spinner>
          <div>
            <h5 class="px-4" v-if="processing">Processing Payment...</h5>
            <h5 class="px-4" v-else>Redirecting...</h5>
            <small class="text-muted">orderId: {{ param.orderId }} </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      processing: true,
      param: {},
    };
  },
  computed: {
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
  },
  methods: {
    payment() {
      const url = `${this.$apiUrl}/payment/success`;

      let token = localStorage.getItem("token");

      fetch(url, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.$route.query),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            alert("Payment Successful, Redirecting...");
            this.$router.push({ path: "/home" });
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          alert(
            "하단 파란채팅을 통해 orderId를 포함 결제 오류 문의 부탁드립니다."
          );
        });
    },
  },
  mounted() {
    this.payment();
  },
  created() {
    this.param = this.$route.query;
    console.log(JSON.stringify(this.$route.query));
  },
};
</script>