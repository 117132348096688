<template>
  <div>
    <b-overlay
      :show="overlay"
      spinner-variant="dark"
      style="min-height: 90vh"
      spinner-type="grow"
    >
      <div v-if="data != null">
        <b-container fluid class="my-3 text-center">
          <b-row align-h="center" style="min-height: 80vh">
            <b-col lg="4" class="text-center my-auto">
              <div class="text-left">
                <h2>
                  <strong>
                    {{ data.user.name }} 선생님 |
                    {{ data.classroom.name }} 강의실
                  </strong>
                </h2>

                <div v-if="student == null" class="mt-4">
                  <Student
                    :teamId="data.team.teamId"
                    @verified="setStudent"
                    :classroomId="$route.query.id"
                  />
                </div>
                <div v-else>
                  <b-row>
                    <b-col cols="auto">
                      <b-form-checkbox
                        v-model="camera"
                        size="lg"
                        class="mt-2"
                        switch
                      >
                        {{
                          camera
                            ? "스캐너 (시험지 모드: OFF)"
                            : "시험지 (스캐너 모드: OFF)"
                        }}
                      </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        v-if="!camera"
                        v-model="math"
                        size="lg"
                        class="mt-2"
                        switch
                      >
                        수학 시험지
                      </b-form-checkbox>
                    </b-col>
                  </b-row>

                  <hr />
                  <Scan
                    v-if="camera"
                    :camera="camera"
                    :student="student"
                    :classroomId="data.classroom.classroomId"
                    :ip="ip"
                  />
                  <b-row v-else align-h="center">
                    <b-col>
                      <AnswerSheet
                        :math="math"
                        :student="student"
                        :data="data"
                        :ip="ip"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-overlay>
    <Footer />
  </div>
</template>

<script>
import AnswerSheet from "@/components/Class/AnswerSheet.vue";

import Student from "@/components/Class/Student.vue";

import Scan from "@/components/Class/Scan.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Class",
  components: {
    AnswerSheet,
    Student,
    Scan,
    Footer,
  },
  data() {
    return {
      overlay: true,
      data: null,
      math: false,
      student: null,
      camera: true,
      ip: null,
    };
  },
  methods: {
    checkIp: function () {
      fetch("https://ipapi.co/json/")
        .then((res) => res.json())
        .then((json) => {
          this.ip = json.ip;
          console.log(json);
        });
    },
    setStudent: function (e) {
      this.student = e;
    },
    getClass: function () {
      if (this.$route.query.id) {
        const url = this.$url;

        fetch(`${url}/api/class/id/${this.$route.query.id}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              console.log(json);

              this.data = json;
              this.overlay = false;
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            this.$router.push({ path: "/" });
          });
      } else {
        alert("Classroom id가 제공되지 않았습니다.");
        this.$router.push({ path: "/" });
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getClass();
    this.checkIp();
  },
};
</script>