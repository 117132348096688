<template>
  <div class="text-center">
    <b-overlay
      :show="overlay"
      rounded="sm"
      style="margin-bottom: 10px"
      variant="white"
    >
      <b-form @submit="uploadFile" @submit.prevent>
        <b-card>
          <a href="/download/sr">이 링크를 눌러 엑셀 파일을 다운받아 </a>
          <br />학생정보를 입력후 업로드 진행해주시기 바랍니다.
        </b-card>
        <b-form-group>
          <!-- Styled -->
          <b-form-file
            class="mt-2 text-left"
            v-model="file"
            :state="Boolean(file)"
            placeholder="정보가 추가된 엑셀 파일을 첨부해 주십시오..."
            drop-placeholder="Drop file here..."
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></b-form-file>
          <!-- <small >* 새롭게 학생정보를 추가 할게 될경우 기존 학생정보가 삭제됩니다</small>  -->
        </b-form-group>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="file == null || overlay"
        >
          <strong class="sub-title">학생 정보 추가하기</strong>
        </b-button>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import xlsx from "xlsx";
export default {
  data() {
    return {
      overlay: false,
      file: null,
    };
  },
  methods: {
    uploadFile() {
      this.processXlsx(this.file);
    },
    processXlsx: function (file) {
      let reader = new FileReader();

      reader.onload = (e) => {
        let data = e.target.result;
        let workbook = xlsx.read(data, {
          type: "binary",
        });

        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          let xlData = xlsx.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          xlData = xlData.slice(1);

          let students = xlData.reduce((s, e) => {
            if ("학생이름" in e) {
              let name = e["학생이름"]
                .toString()
                .replace(/^[ \t]+|[ \t]+$/, "")
                .replace(/\s\s+/g, " ");
              let code = e["학생연락처"]
                .toString()
                .replace(new RegExp("[^0-9]", "g"), "");
              let number = e["결과 수신 연락처"]
                .toString()
                .replace(new RegExp("[^0-9]", "g"), "");
              if (code.length == 11 && number.length == 11) {
                s.push({
                  name: name,
                  code: code.slice(code.length - 8),
                  number: number,
                });
              } else {
                alert(
                  `에러: ${name}의 데이터가 잘못 됬습니다, 다시 확인 해주시기 바랍니다.`
                );
              }
            }
            return s;
          }, []);

          // console.log(students);
          this.uploadStudents(students);
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
        alert(`에러: ${ex}`);
      };

      reader.readAsBinaryString(file);
    },
    uploadStudents: function (students) {
      this.overlay = true;

      let token = localStorage.getItem("token");

      console.log(students);
      if (this.file != null) {
        fetch(`${this.$apiUrl}/scanner/student/add`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
            // "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify({ students: students }),
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              this.file = null;
              this.overlay = false;
              this.$emit("imported");
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            this.overlay = false;
          });
      }
    },
  },
};
</script>