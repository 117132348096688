<template>
  <div class="text-center">
    <b-overlay
      :show="overlay || balance == null"
      rounded="sm"
      style="margin-bottom: 10px"
      variant="white"
    >
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..."></b-spinner>
          <div class="mt-3" v-if="files.length !== 0">
            <h5 id="cancel-label" class="px-4">
              스캔 중 ... ({{ processed }}/{{ files.length }})
            </h5>
            <b-progress
              :value="(processed / files.length) * 100"
              striped
              animated
              variant="dark"
            ></b-progress>
          </div>
        </div>
      </template>

      <canvas id="the-canvas" style="border: 1px solid black" hidden></canvas>

      <b-form @submit="processPDF" @submit.prevent>
        <b-form-group>
          <!-- Styled -->
          <b-form-file
            class="mt-1 text-left"
            v-model="pdf_files"
            multiple
            :state="Boolean(pdf_files)"
            placeholder="이곳을 눌러 주세요 :)"
            drop-placeholder="여기에 놓아주세요..."
            accept="application/pdf"
          ></b-form-file>

          <!-- <div class="mt-3">Selected file: {{ file ? file.name : "" }}</div> -->
        </b-form-group>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="pdf_files.length == 0 || overlay || balance < 1"
        >
          <strong class="sub-title"
            >스캔하기
            <span v-if="balance"
              >(잔고: {{ balance.toLocaleString() }} 코인)</span
            ></strong
          >
        </b-button>
        <p class="text-right">
          <small
            >* 정확한 결과를 위해 밝은 환경, 정면 사진으로 스캔 부탁드립니다.
          </small>
        </p>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
// const saveAs = require("file-saver");
import * as pdfjsLib from "pdfjs-dist";

export default {
  props: {
    userId: String,
  },
  data() {
    return {
      balance: null,
      overlay: false,
      processed: 0,
      pdf_files: [],
      files: [],
      file: null,
      errors: [],
    };
  },
  mounted() {
    this.checkBalance();
  },
  watch: {
    files(val) {
      if (val.length !== 0) {
        console.log("changeddd files", val);
        this.uploadFiles();
      }
    },
  },
  methods: {
    checkBalance: function () {
      let token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/team/balance`, {
        method: "POST",
        headers: {
          authorization: token,
          // "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.balance = json.balance;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    processPDF: async function () {
      var reader = new FileReader();
      let images = [];
      reader.onload = async (e) => {
        const dataURLtoFile = function (dataurl, filename) {
          var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, { type: mime });
        };

        // binary data
        let pdf_doc = await pdfjsLib.getDocument({ data: e.target.result });
        console.log(pdf_doc.numPages);
        console.log(pdf_doc);

        for (const i of [...Array(pdf_doc.numPages).keys()]) {
          let page = await pdf_doc.getPage(i + 1);
          console.log(page);
          var scale = 1.5;
          var viewport = page.getViewport(scale);

          //
          // Prepare canvas using PDF page dimensions
          //
          var canvas = document.getElementById("the-canvas");
          var context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          //
          // Render PDF page into canvas context
          //
          await page.render({
            canvasContext: context,
            viewport: viewport,
          });

          await images.push(
            dataURLtoFile(canvas.toDataURL("image/png"), "image.png")
          );
          // const blob = new Blob([images[i]],{type: 'image/png'})
          // saveAs(blob, `image-${i}.png`);
        }
        await console.log(images);
        await this.files.push(...images);
      };
      reader.onerror = function (e) {
        // error occurred
        console.log("Error : " + e.type);
      };
      reader.readAsBinaryString(this.pdf_files[0]);
    },
    uploadFiles: async function () {
      this.$emit("scan");

      this.overlay = true;
      if (this.files.length != 0) {
        let result;
        for (let fileIdx in this.files) {
          this.processed = this.processed + 1;
          console.log(this.progress);
          result = await this.uploadFile(this.files[fileIdx], fileIdx);
          console.log(result);
        }

        if (this.errors.length == 0) {
          alert("스캔이 성공적으로 완료되었습니다");
          if (this.files.length == 1) {
            this.$bvModal
              .msgBoxConfirm("해당 시험지의 결과를 바로 확인 하시겠습니까?", {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: "확인하기",
                cancelTitle: "취소",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then((res) => {
                if (res) {
                  window.open(`/result?id=${result.resultId}`);
                }
              });
          }
        } else {
          alert(
            `${this.errors.reduce((a, e) => {
              return (a += `"${e}" `);
            }, "")} 파일(들)에 에러가 있었습니다`
          );
        }
      }

      this.files = [];
      this.$emit("refresh");
      this.$emit("scan");
      this.overlay = false;
      this.errors = [];
      this.processed = 0;
    },
    uploadFile: function (file, fileIdx) {
      return new Promise((resolve) => {
        const url = `${this.$apiUrl}/scanner/scan`;

        let token = localStorage.getItem("token");
        if (file != null) {
          console.log(file);
          let formData = new FormData();
          formData.append("image", file);
          formData.append("name", file.name);
          formData.append("userId", this.userId);

          fetch(url, {
            method: "POST",
            headers: {
              authorization: token,
              // 'Content-Type': 'multipart/form-data',
            },
            body: formData,
          })
            .then((res) => res.json())
            .then((json) => {
              if (!json.error) {
                this.checkBalance();
                resolve(json);
              } else {
                throw new Error(json.error);
              }
            })
            .catch((error) => {
              this.errors.push(`${parseInt(fileIdx) + 1}번: ${file.name}`);
              alert(error);

              resolve(error);
            });
        }
      });
    },
  },
};
</script>