<template>
  <div>
    <b-card class="text-center px-3" no-body>
      <b-container class="my-4" fluid>
        <div v-if="results == null || loading">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>

        <div v-else>
          <b-row align-v="end">
            <b-col class="text-left my-1" md="4">
              <h3>Results</h3>
            </b-col>

            <b-col class="text-right my-1" md="4" offset-md="4">
              <b-input-group>
                <b-form-input
                  v-model="search"
                  type="text"
                  placeholder="학생 이름"
                ></b-form-input>
                <!-- <b-input-group-append>
                  <b-button variant="dark"><b-icon-search/></b-button>
                </b-input-group-append> -->
              </b-input-group>
            </b-col>
          </b-row>

          <b-row align-v="end" class="mb-3 mt-1">
            <b-col class="text-right my-1" md="4">
              <b-form-select v-model="userSelected" :options="userOptions">
                <b-form-select-option :value="null"
                  >팀원 선택 (Select All)</b-form-select-option
                >
              </b-form-select>
            </b-col>
            <b-col class="text-right my-1" md="4">
              <!-- <b-form-input
                v-model="filter"
                placeholder="결과 검색"
              ></b-form-input> -->
              <b-form-select v-model="selected" :options="options">
                <b-form-select-option :value="null"
                  >강의실 선택 (Select All)</b-form-select-option
                >
              </b-form-select>
            </b-col>
            <b-col class="text-right my-1" md="4">
              <b-form-select
                v-model="examSelected"
                :options="
                  exams
                    .filter(
                      (e) => e.userId == userSelected || userSelected == null
                    )
                    .filter(
                      (e) => e.classroomId == selected || selected == null
                    )
                    .map((e) => {
                      return {
                        text: `${e.name} (${e.code})`,
                        value: e.examId,
                      };
                    })
                "
              >
                <b-form-select-option :value="null"
                  >시험 선택 (Select All)</b-form-select-option
                >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col v-if="results.length == 0">
              <NoResultFound />
            </b-col>

            <b-col v-else-if="results != null">
              <b-table
                stacked="sm"
                head-variant="dark"
                :fields="fields"
                :filter="search"
                :items="
                  results
                    .filter(
                      (e) =>
                        users.map((u) => u.userId).includes(e.userId) &&
                        (userSelected == null || e.userId == userSelected)
                    )
                    .filter(
                      (e) =>
                        classrooms
                          .map((c) => c.classroomId)
                          .includes(e.classroomId) &&
                        (selected == null || e.classroomId == selected)
                    )
                    .filter(
                      (e) =>
                        exams.map((exam) => exam.examId).includes(e.examId) &&
                        (examSelected == null || e.examId == examSelected)
                    )
                    .map((e) => {
                      return {
                        userId: e.userId,
                        examId: e.examId,
                        classroom: classrooms.filter(
                          (c) => c.classroomId == e.classroomId
                        )[0].name,
                        student:
                          students.filter((s) => s.studentId == e.studentId)
                            .length > 0
                            ? students.filter(
                                (s) => s.studentId == e.studentId
                              )[0].name
                            : '삭제된 학생',
                        score: e.score,
                        resultId: e.resultId,
                        lastUpdated: e.lastUpdated,
                      };
                    })
                "
              >
                <template #cell(user)="row">
                  {{ users.filter((e) => e.userId == row.item.userId)[0].name }}
                </template>

                <template #cell(exam)="row">
                  <b-link
                    :href="`/result?id=${row.item.resultId}`"
                    target="_blank"
                  >
                    {{
                      exams.filter((e) => row.item.examId == e.examId)[0].name
                    }}
                  </b-link>
                </template>

                <template #cell(score)="row">
                  {{ row.item.score }}/{{
                    exams.filter((e) => row.item.examId == e.examId)[0]
                      .totalScore
                  }}
                </template>

                <template #cell(lastUpdated)="row">
                  {{
                    new Date(row.item.lastUpdated * 1000).toLocaleDateString()
                  }}
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import NoResultFound from "@/components/Student/NoResultFound.vue";

export default {
  components: {
    NoResultFound,
  },
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      filter: "",
      fields: [
        {
          key: "user",
          label: "팀원",
          sortable: true,
        },
        {
          key: "classroom",
          label: "강의실",
          sortable: true,
        },
        {
          key: "exam",
          label: "시험",
          sortable: true,
        },
        {
          key: "score",
          label: "결과 점수",
          sortable: true,
        },
        {
          key: "student",
          label: "학생",
          sortable: true,
        },
        {
          key: "lastUpdated",
          label: "시간",
          sortable: true,
        },
      ],
      search: null,
      selected: null,
      userSelected: null,
      examSelected: null,
      userOptions: null,
      options: null,
      results: null,
      students: null,
      exams: null,
      classrooms: null,
    };
  },
  mounted() {
    this.getResults();
  },
  watch: {
    selected(val) {
      if (this.userSelected != null) {
        this.getClassroomResults(val);
      }
      this.examSelected = null;
    },

    userSelected(val) {
      this.getUserResults(val);
      this.selected = null;
      this.examSelected = null;
    },
  },
  methods: {
    openTeamScan: function () {
      this.copyUrl(`/scan?id=${this.userId}`);
      window.open(`/scan?id=${this.userId}`, "_blank");
    },
    copyUrl: function (path) {
      let url = window.location.href;
      this.fallbackCopyTextToClipboard(
        `${url.slice(0, url.length - 5)}${path}`
      );
      alert("클립보드에 URL 주소가 복사되었습니다.");
    },

    fallbackCopyTextToClipboard: function (text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },

    getClassroomResults: function (classroomId) {
      if (classroomId != null && this.userSelected != null) {
        this.loading = true;
        const url = this.$url;

        let token = localStorage.getItem("token");

        fetch(
          url + `/api/result/team/recent/${this.userSelected}/${classroomId}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              console.log(json);
              this.results = json.results;

              this.results = this.results.filter(
                (e) =>
                  this.classrooms
                    .map((c) => c.classroomId)
                    .includes(e.classroomId) &&
                  this.exams.map((exam) => exam.examId).includes(e.examId)
              );
              this.loading = false;
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            this.loading = false;
            alert(error);
          });
      } else {
        this.getUserResults(this.userSelected);
      }
    },

    getUserResults: function (userId) {
      if (userId != null) {
        this.loading = true;
        const url = this.$url;

        let token = localStorage.getItem("token");

        fetch(url + `/api/result/team/recent/${userId}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              console.log(json);
              this.results = json.results;

              this.results = this.results.filter(
                (e) =>
                  this.classrooms
                    .map((c) => c.classroomId)
                    .includes(e.classroomId) &&
                  this.exams.map((exam) => exam.examId).includes(e.examId)
              );

              this.options = json.classrooms.map((e) => {
                return { text: `${e.name} (${e.code})`, value: e.classroomId };
              });

              this.loading = false;
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            this.loading = false;
            alert(error);
          });
      } else {
        this.getResults();
      }
    },

    getResults: function () {
      this.loading = true;
      const url = this.$url;

      let token = localStorage.getItem("token");

      fetch(url + `/api/result/team/recent`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.results = json.results;
            this.users = json.users;
            this.students = json.students;
            this.exams = json.exams;
            this.classrooms = json.classrooms;

            this.results = this.results.filter(
              (e) =>
                this.classrooms
                  .map((c) => c.classroomId)
                  .includes(e.classroomId) &&
                this.exams.map((exam) => exam.examId).includes(e.examId)
            );

            this.options = json.classrooms.map((e) => {
              return { text: `${e.name} (${e.code})`, value: e.classroomId };
            });

            this.userOptions = json.users.map((e) => {
              return { text: `${e.name}`, value: e.userId };
            });

            this.loading = false;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>