<template>
  <b-container
    class="coin-prices-container my-5 pt-3"
    ref="coin-prices-container"
  >
    <b-row class="justify-content-md-center mb-5">
      <b-col class="text-center">
        <h1 class="sub-title mb-3">
          <strong>OMR 스캐너™ <br class="d-lg-none" />결제 플랜.</strong>
        </h1>
        <h4>
          토스 페이먼트와의 제휴를 통해 다양한 결제 수단 및 금액을 지원하고
          있습니다.
        </h4>
      </b-col>
    </b-row>
    <b-row
      class="justify-content-md-center"
      cols="1"
      :style="
        animation ? `animation: fadein 2s, slidein 1s` : `visibility:hidden`
      "
    >
      <b-col
        col
        id="option"
        lg="4"
        class="text-center my-3 d-flex align-items-stretch"
        v-for="(card, cardIndex) in cards"
        v-bind:key="cardIndex"
      >
        <b-card
          style="
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            min-width: 100%;
            min-height: 100%;
          "
          :border-variant="card.variant"
        >
          <div class="mt-3">
            <h3 class="bold">
              <strong>{{ card.title }}</strong>
            </h3>
            <p class="my-3" v-html="card.desc"></p>
          </div>

          <!-- <b-list-group class="text-left" style="min-height: 140px" flush>
            <b-list-group-item
              v-for="(feature, featureIndex) in card.features"
              v-bind:key="featureIndex"
              class="d-flex align-items-center"
              ><b-icon-check2-square class="pr-4" />
              {{ feature }}</b-list-group-item
            >
          </b-list-group>
          <p></p>
          <b-button
            :variant="card.actionButton.variant"
            class="mt-4 mb-2"
            block
            :disabled="name != false"
            @click="openModal(card.actionButton.modal)"
            >{{ card.actionButton.content }}</b-button
          > -->
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  computed: {
    name() {
      return this.$store.getters.name;
    },
  },
  mounted() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    let observer = new IntersectionObserver((e) => {
      console.log(e);
      if (e[0].isIntersecting) {
        this.animation = true;
      } else {
        this.animation = false;
      }
      console.log(this.animation);
    }, options);

    observer.observe(this.$refs["coin-prices-container"]);
  },
  data() {
    return {
      animation: false,
      cards: [
        {
          title: "200 코인",
          variant: "white",
          desc: "<mark><strong>10,000원</strong></mark>으로 <br/><strong>200건</strong>의 SMS 문자 전송 & <br/><strong>OMR 스캐너의 모든 기능</strong> 제공",
        },
        {
          title: "700 코인",
          variant: "white",

          desc: "<mark><strong>30,000원</strong></mark>으로 <br/><strong>700건</strong>의 SMS 문자 전송 & <br/><strong>OMR 스캐너의 모든 기능</strong> 제공",
        },
        {
          title: "1,200 코인",
          variant: "white",

          desc: "<mark><strong>50,000원</strong></mark>으로 <br/><strong>1,200건</strong>의 SMS 문자 전송 & <br/><strong>OMR 스캐너의 모든 기능</strong> 제공",
        },
      ],
    };
  },
  methods: {
    openModal(name) {
      this.$emit("open-modal", name);
    },
  },
};
</script>

<style scoped>
#option {
  position: relative;

  top: 0;
  transition: top ease 0.5s;
}

#option:hover {
  top: -20px;
}
</style>