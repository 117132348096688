<template>
  <div>
    <!-- Import Student -->
    <b-modal
      id="import-students"
      ref="import-students-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">학생정보 연동</strong></h3>
      </template>
      <ImportStudents @imported="added" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Claim Coupon -->
    <b-modal id="claim-coupon" ref="claim-coupon-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">쿠폰 사용하기</strong></h3>
      </template>
      <ClaimCoupon @claimed="couponClaimed" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Add User -->
    <b-modal id="add-user" ref="add-user-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">팀원 계정 추가</strong></h3>
      </template>
      <AddUser @added="userAdded" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <Payment :show="showPayment" :team="team" @change="paymentChangeHandler" />

    <b-card class="text-center">
      <b-container class="my-2" fluid>
        <div v-if="team == null">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>

        <div v-else-if="team.studentIds.length == 0">
          <Addstudents />
        </div>
        <div v-else>
          <b-row align-v="end" class="mb-1">
            <b-col md="3" class="text-left">
              <h3>Team details</h3>
            </b-col>
            <b-col md="3" offset-md="3">
              <b-button
                variant="success"
                class="my-1 mx-1"
                v-b-modal.claim-coupon
                block
                ><b-icon icon="gift-fill"></b-icon> 쿠폰 사용</b-button
              >
            </b-col>
            <b-col md="3">
              <b-button
                variant="primary"
                block
                @click="togglePaymentModal"
                class="my-1 mx-1"
                ><b-icon icon="credit-card" /> 코인 충전
              </b-button>
            </b-col>
          </b-row>
          <hr />
          <b-overlay
            :show="overlay"
            rounded="sm"
            class="text-left"
            style="margin-bottom: 10px"
          >
            <form @submit.prevent @submit="updateTeam">
              <div class="text-left">
                <label for="teamName">이름</label>
                <b-form-input
                  id="teamName"
                  v-model="name"
                  type="text"
                  required
                ></b-form-input>

                <div v-if="team.tier == 0">
                  <label for="teamName" class="mt-2">잔고</label>
                  <b-list-group>
                    <b-list-group-item>
                      <div>{{ team.balance }}</div></b-list-group-item
                    >
                  </b-list-group>
                </div>

                <label for="address" class="mt-2"
                  >결제용 주소 (정확히 기재 부탁드립니다)</label
                >
                <b-form-input
                  id="address"
                  v-model="address"
                  name="address"
                  type="text"
                  required
                  autocomplete="on"
                ></b-form-input>

                <label for="number" class="mt-2"
                  >결제용 전화번호 (010 포함 11자리)</label
                >
                <b-form-input
                  id="number"
                  name="number"
                  v-model="number"
                  autocomplete="on"
                  type="text"
                  required
                ></b-form-input>

                <hr />
                <b-row>
                  <b-col lg="9">
                    <label for="teamName" class="mt-2">계정(들)</label>
                  </b-col>
                  <b-col lg="3">
                    <b-button
                      variant="success"
                      class="mx-1 my-1"
                      block
                      v-b-modal.add-user
                      ><b-icon icon="person-fill"></b-icon> 팀원 추가</b-button
                    >
                  </b-col>
                </b-row>
                <UserInfo
                  class="my-2"
                  v-if="team != null"
                  :userIds="team.userIds"
                  @user-deleted="userDeleted"
                />

                <!-- <label for="teamName" class="mt-2">관리자 계정</label>

                <b-list-group>
                  <b-list-group-item
                    v-for="adminId in team.adminIds"
                    v-bind:key="`admin-${adminId}`"
                    >{{ adminId }}</b-list-group-item
                  >
                </b-list-group> -->
                <hr />

                <div>
                  <label for="teamName" class="mt-2">계정 생성 시간</label>
                  <b-list-group>
                    <b-list-group-item>
                      <div>
                        {{ new Date(team.teamCreated * 1000) }}
                      </div></b-list-group-item
                    >
                  </b-list-group>
                </div>

                <hr />

                <label for="teamImageUrl" class="mt-1"
                  ><b-icon-exclamation-triangle /> Experimental
                  <b-icon-exclamation-triangle /> · 상단 이미지 URL (https
                  포함)</label
                >
                <b-form-input
                  id="teamImageUrl"
                  v-model="imageUrl"
                  type="text"
                  required
                ></b-form-input>

                <small class="text-white">{{ team.teamId }}</small>
              </div>

              <b-button
                type="submit"
                variant="dark"
                size="lg"
                :disabled="
                  overlay ||
                  (team.name == name &&
                    team.imageUrl == imageUrl &&
                    team.number == number &&
                    team.address == address)
                "
              >
                <strong class="sub-title">업데이트 하기</strong>
              </b-button>
            </form>
          </b-overlay>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Payment from "@/components/Admin/Payment.vue";
import AddUser from "@/components/Admin/AddUser.vue";
import UserInfo from "@/components/Admin/UserInfo.vue";

import ImportStudents from "@/components/Admin/ImportStudents.vue";
import Addstudents from "@/components/Admin/AddStudents.vue";
import ClaimCoupon from "@/components/Admin/ClaimCoupon.vue";

export default {
  components: {
    Payment,
    Addstudents,
    UserInfo,
    AddUser,
    ImportStudents,
    ClaimCoupon,
  },
  data() {
    return {
      showPayment: false,

      team: null,
      userSelected: null,
      overlay: true,
      address: "",
      number: "",
      name: "",
      imageUrl: "",
    };
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    togglePaymentModal: function () {
      if (this.address != "" && this.number != "") {
        this.showPayment = !this.showPayment;
      } else {
        alert("결제용 주소와 번호를 먼저 입력하여 업데이트 부탁드립니다.");
      }
    },
    paymentChangeHandler(e) {
      this.showPayment = e;
    },
    added: function () {
      this.$refs["import-students-modal"].hide();
      this.overlay = true;
      this.getTeam();
    },
    userAdded: function () {
      this.$refs["add-user-modal"].hide();
      this.overlay = true;
      this.getTeam();
    },
    couponClaimed: function () {
      this.$refs["claim-coupon-modal"].hide();
      this.$emit("refresh");
      this.getTeam();
    },
    userDeleted: function () {
      this.overlay = true;
      this.getTeam();
    },
    updateTeam: function () {
      this.overlay = true;

      const team = {
        name: this.name,
        imageUrl: this.imageUrl,
        address: this.address,
        number: this.number.toString().replace(new RegExp("[^0-9]", "g"), ""),
      };

      if (team.number.length == 11) {
        const token = localStorage.getItem("token");

        fetch(`${this.$apiUrl}/scanner/team/update`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(team),
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              alert("성공적으로 업데이트 됬습니다.");
              this.$emit("refresh");
              this.getTeam();
            } else {
              console.log(json);
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            console.log(error);

            alert(error);
            this.overlay = false;
            this.name = "";
          });
      } else {
        alert(
          "결제용 전화번호 (010 포함 11자리) 해당란에 오류가 발견되었습니다."
        );
        this.overlay = false;
      }
    },
    getTeam: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/team/details`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.team = json.team;
            this.address = json.team.address;
            this.number = json.team.number;
            this.team.studentIds = json.studentIds;
            this.name = json.team.name;
            this.imageUrl = json.team.imageUrl;
            this.overlay = false;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
};
</script>