<template>
  <div>
    <!-- Import Student -->
    <!-- <AddStudentModal @added="refresh" /> -->

    <!-- Edit Student Info -->
    <b-modal
      id="edit-student-info"
      ref="edit-student-info-modal"
      centered
      hide-footer
      v-if="students != null"
    >
      <template v-slot:modal-title>
        <h3><strong class="title">학생 수정하기</strong></h3>
      </template>
      <EditStudentInfo
        :student="students.filter((e) => e.studentId == studentSelected)[0]"
        @edited="studentInfoEdited"
      />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Edit Tags -->
    <b-modal
      id="edit-tags"
      ref="edit-tags-modal"
      centered
      hide-footer
      v-if="students != null"
    >
      <template v-slot:modal-title>
        <h3><strong class="title">태그 추가/수정</strong></h3>
      </template>
      <EditTags
        :students="students.filter((e) => selected.includes(e.studentId))"
        @edited="tagEdited"
      />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <b-card class="text-center">
      <b-container class="my-2" fluid>
        <div v-if="students == null">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else-if="students.length == 0">
          <b-row align-v="center">
            <b-col>
              <img
                src="@/assets/icons/info.svg"
                class="mb-2"
                width="150rem"
                height="150rem"
              />
              <h3 class="my-2 mb-5">
                <strong>
                  학생 데이터가 존재하지 않습니다, 팀 관리자에게 문의하여
                  주십시오.
                </strong>
              </h3>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <div class="mb-4">
            <b-row align-v="end" class="">
              <b-col class="text-left my-1" md="3">
                <h3>
                  Students
                  <span v-if="students != null">({{ students.length }})</span>
                </h3>
              </b-col>

              <b-col class="text-right my-1" md="3" offset-md="3">
                <b-form-input
                  v-model="filter"
                  placeholder="학생 검색"
                ></b-form-input>
              </b-col>
              <b-col class="text-right my-1" md="3">
                <b-form-select v-model="tagSelected" :options="tags">
                  <b-form-select-option :value="null"
                    >태그 선택 (Selected All)</b-form-select-option
                  >
                </b-form-select>
              </b-col>
            </b-row>

            <b-row class="my-2" align-h="end">
              <b-col md="4">
                <b-button
                  variant="success"
                  class="mx-1 my-1"
                  @click="selectAll"
                  block
                  ><b-icon icon="check"></b-icon> 전체선택/해제</b-button
                >
              </b-col>
              <b-col md="4">
                <b-button
                  variant="success"
                  class="mx-1 my-1"
                  v-b-modal.edit-tags
                  block
                  ><b-icon icon="tag-fill"></b-icon> 태그 추가/수정</b-button
                >
              </b-col>
              <b-col md="4">
                <b-button
                  variant="success"
                  class="mx-1 my-1"
                  v-b-modal.add-students-modal
                  block
                  ><b-icon icon="person-fill"></b-icon> 학생 연동</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-form-checkbox-group v-model="selected">
            <b-row align-v="center">
              <b-col>
                <b-table
                  stacked="sm"
                  head-variant="dark"
                  :fields="fields"
                  :filter="filter"
                  :items="
                    students.filter((e) =>
                      tagSelected != null ? e.tags.includes(tagSelected) : e
                    )
                  "
                >
                  <template #cell(tags)="row">
                    <b-badge
                      variant="light"
                      v-for="(tag, tagIdx) in row.item.tags"
                      v-bind:key="tagIdx"
                      >{{ tag }}</b-badge
                    >
                    <span v-if="row.item.tags.length == 0"> N/A </span>
                  </template>
                  <template #cell(name)="row">
                    <b-form-checkbox :value="row.item.studentId" v-if="true"
                      >{{ row.item.name }} |
                      {{ row.item.code }}</b-form-checkbox
                    >
                    <span v-else>
                      {{ row.item.name }} | {{ row.item.code }}
                    </span>
                  </template>

                  <template #cell(show_details)="row">
                    <b-button
                      size="sm"
                      :id="`recent-result-${row.item.studentId}`"
                      variant="white"
                      @click="getRecentResult(row.item.studentId)"
                    >
                      <b-icon icon="file-earmark-check"></b-icon>
                    </b-button>
                    <b-tooltip
                      :target="`recent-result-${row.item.studentId}`"
                      triggers="hover"
                      top
                    >
                      최근 테스트 결과
                    </b-tooltip>
                    <b-button
                      size="sm"
                      :id="`show-more-${row.item.studentId}`"
                      @click="row.toggleDetails"
                      class="mx-2"
                      variant="white"
                    >
                      <b-icon-three-dots />
                    </b-button>
                    <b-tooltip
                      :target="`show-more-${row.item.studentId}`"
                      triggers="hover"
                      top
                    >
                      자세히 보기
                    </b-tooltip>
                  </template>

                  <template #row-details="row">
                    <div class="mt-3">
                      <b-row class="mb-2">
                        <b-col>
                          <Results :studentId="row.item.studentId" />
                        </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col class="text-right" xl="4">
                          <b-button
                            @click="editStudent(row.item.studentId)"
                            variant="success"
                            block
                            class="mx-2 mt-1"
                            ><b-icon icon="person-fill"></b-icon> 학생
                            수정하기</b-button
                          >
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import EditTags from "@/components/Student/EditTags.vue";
import EditStudentInfo from "@/components/Student/EditStudentInfo.vue";

// import AddStudentModal from "@/components/Student/AddStudentModal.vue";

import Results from "@/components/Student/Results.vue";
export default {
  props: {
    admin: Boolean,
  },
  components: {
    EditTags,
    EditStudentInfo,
    Results,
  },
  data() {
    return {
      tags: [],
      tagSelected: null,
      selected: [],
      filter: "",
      students: null,
      studentSelected: null,
      fields: [
        {
          key: "tags",
          label: "태그",
          sortable: true,
        },
        {
          key: "name",
          label: "이름/코드",
          sortable: true,
        },
        {
          key: "show_details",
          label: "자세히 보기",
        },
      ],
    };
  },
  watch: {
    tagSelected() {
      this.selected = [];
    },
  },
  methods: {
    editStudent: function (sId) {
      this.studentSelected = sId;
      this.$refs["edit-student-info-modal"].show();
    },
    tagEdited: function () {
      this.$refs["edit-tags-modal"].hide();
      this.getStudents();
    },
    studentInfoEdited: function () {
      this.$refs["edit-student-info-modal"].hide();
      this.getStudents();
    },
    refresh: function () {
      this.getStudents();
    },
    genReport: function () {
      alert("개발중인 기능입니다.");
    },
    getRecentResult: function (sId) {
      const url = this.$url;

      const token = localStorage.getItem("token");

      fetch(url + "/api/result/recent/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({ studentId: sId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            if (json.result != null) {
              window.open(`${url}/result?id=${json.result.resultId}`);
            } else {
              alert(
                "시험 결과가 발견되지 않았습니다, 스캔을 통해 추가 해주시기 바랍니다"
              );
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    selectAll: function () {
      if (
        this.selected.length <
        this.students.filter((e) =>
          this.tagSelected != null ? e.tags.includes(this.tagSelected) : e
        ).length
      ) {
        this.selected = this.students
          .filter((e) =>
            this.tagSelected != null ? e.tags.includes(this.tagSelected) : e
          )
          .map((e) => e.studentId);
      } else {
        this.selected = [];
      }
    },

    getStudents: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/student`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);

            this.students = json.students.sort((a, b) =>
              a.name > b.name ? 1 : -1
            );

            this.tags = json.students.reduce(
              (a, e, i) => {
                if (i == 0) {
                  a = e.tags;
                } else {
                  a = [...new Set([...e.tags, ...a])];
                }
                return a;
              },
              [null]
            );
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  mounted() {
    // console.log('hello')
    this.getStudents();
  },
};
</script>