<template>
  <b-navbar toggleable="lg">
    <b-navbar-brand :to="{ path: '/' }" tag="h1" class="mb-0"
      ><strong style="font-size: 1rem">
        <span v-if="$route.name == 'Report'">시험결과 리포트</span>
        <span v-else-if="!teamName">OMR 스캐너™ | 에루디오 랩스</span
        ><span v-else-if="teamName">{{ teamName }}</span></strong
      ></b-navbar-brand
    >

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#" v-if="email && name" class="mb-0">
          <Notice />
        </b-nav-item>
        <!-- <b-nav-item>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-item> -->

        <b-nav-item v-if="status != null" @click="checkStatus" class="mb-0">
          <b-overlay
            :show="overlay"
            spinner-variant="dark"
            spinner-type="grow"
            spinner-small
            rounded="sm"
            style="max-width: 320px"
          >
            <b-badge variant="success" v-if="status" class="mb-0"
              >스캐너 작동 중</b-badge
            ><b-badge variant="danger" v-else class="mb-0"
              >스캐너 점검 중</b-badge
            >
          </b-overlay>
        </b-nav-item>

        <b-nav-item href="#" v-if="email && name" @click="logout" class="mb-0"
          >로그아웃</b-nav-item
        >
        <!-- <b-nav-item>
          <b-dropdown :text="`${name} 님`" size="sm" variant="light" right>
            <b-dropdown-item><Balance /></b-dropdown-item>
            <b-dropdown-item>{{ email }}</b-dropdown-item>

            <b-dropdown-item @click="logout">로그아웃</b-dropdown-item>
          </b-dropdown>

        </b-nav-item> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Notice from "@/components/Notice.vue";
export default {
  components: {
    Notice,
  },
  computed: {
    teamName() {
      return this.$store.getters.teamName;
    },
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      overlay: true,
      status: null,
    };
  },
  mounted() {
    this.getStatus();
    setInterval(() => {
      this.getStatus();
    }, 6000 * 3);
  },
  watch: {
    status(val) {
      if (val == false) {
        this.$bvModal.msgBoxOk(
          "현재 인터넷 연결이 원활하지 않거나, 스캐너 점검 중입니다.",
          {
            title: "스캐너 점검",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "확인하기",
            footerClass: "p-2",
            centered: true,
            noCloseOnBackdrop: true,
          }
        );
      }
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logoutAction");
    },
    checkStatus: function () {
      this.overlay = true;
      this.getStatus();
    },
    getStatus: function () {
      const url = this.$apiUrl;

      fetch(url + "/")
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.status = json.status;
            this.overlay = false;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          this.status = false;
          this.overlay = false;

          console.log(err);
        });
    },
  },
};
</script>