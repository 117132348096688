<template>
  <div class="mb-2">
    <form @submit.prevent="edit">
      <div class="form-group">
        <b-tabs v-model="tabIndex" pills fill>
          <b-tab title="객관식" active
            ><b-form-checkbox-group
              class="text-center mt-3"
              v-model="answer"
              size="lg"
              :options="options"
              name="radio-options"
            ></b-form-checkbox-group
          ></b-tab>
          <b-tab title="주관식(수학)">
            <b-form-input
              type="number"
              class="text-center mt-3"
              v-model="answer[0]"
              size="lg"
            ></b-form-input>
          </b-tab>
        </b-tabs>
      </div>

      <b-overlay :show="overlay" rounded="sm">
        <b-button
          block
          type="submit"
          variant="dark"
          :disabled="
            overlay || (tabIndex == 1 && answer[0] == null) || answer[0] == ''
          "
          >수정하기</b-button
        >
      </b-overlay>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    resultId: String,
    answerIdx: Number,
    answerChosen: Array,
  },
  data() {
    return {
      tabIndex: 0,
      overlay: false,
      options: [1, 2, 3, 4, 5],
      answer: [],
    };
  },
  mounted() {
    this.answer = this.answerChosen.filter((e) => this.options.includes(e));
  },
  watch: {
    tabIndex() {
      this.answer = [];
    },
  },
  methods: {
    edit: function () {
      this.overlay = true;
      const url = `${this.$apiUrl}/scanner/edit/answer`;

      let token = localStorage.getItem("token");

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          resultId: this.resultId,
          answerIdx: this.answerIdx,
          answer:
            this.tabIndex == 0
              ? this.answer.map((e) => parseInt(e)).sort()
              : [parseInt(this.answer[0])],
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.overlay = false;

            this.$emit("edited");
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
        });
    },
  },
};
</script>