<template>
  <div>
    <div v-if="users == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <b-list-group v-else>
      <b-list-group-item
        v-for="user in users"
        v-bind:key="`user-info-${user.userId}`"
        :class="user.admin ? '' : ''"
      >
        <b-row align-h="between" align-v="center">
          <b-col cols="8">
            <strong>{{ user.name }}</strong
            ><br />
            <small>{{ user.email }} </small></b-col
          >

          <b-col cols="4" class="text-right">
            <b-button
              variant="white"
              size="sm"
              :id="`${user.userId}-scanner`"
              @click="openTeamScan(user.userId)"
            >
              <b-icon icon="printer"></b-icon>
            </b-button>

            <b-tooltip :target="`${user.userId}-scanner`" triggers="hover" top>
              {{ user.name }}의 스캐너
            </b-tooltip>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: null,
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo: function () {
      let token = localStorage.getItem("token");
      fetch(`${this.$apiUrl}/scanner/team/users`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.users = json.users;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    openTeamScan: function (userId) {
      this.copyUrl(`/scan?id=${userId}`);
      setTimeout(() => {
        window.open(`/scan?id=${userId}`, "_blank");
      }, 400);
    },

    copyUrl: function (path) {
      let url = window.location.href;
      this.copyTextToClipboard(`${url.slice(0, url.length - 5)}${path}`);
      alert("클립보드에 URL 주소가 복사되었습니다.");
    },
    fallbackCopyTextToClipboard: function (text) {
      var textArea = document.createElement("input");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        // navigator.clipboard
        //   .writeText(text)
        //   .then(function () {
        //     alert("yeah!"); // success
        //   })
        //   .catch(function () {
        //     alert("err"); // error
        //   });

        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    // copyUrl: function (path) {
    //   let url = window.location.href;
    //   this.fallbackCopyTextToClipboard(
    //     `${url.slice(0, url.length - 5)}${path}`
    //   );
    //   alert("클립보드에 URL 주소가 복사되었습니다.");
    // },
    // fallbackCopyTextToClipboard: function (text) {
    //   var textArea = document.createElement("textarea");
    //   textArea.value = text;

    //   // Avoid scrolling to bottom
    //   textArea.style.top = "0";
    //   textArea.style.left = "0";
    //   textArea.style.position = "fixed";

    //   document.body.appendChild(textArea);
    //   textArea.focus();
    //   textArea.select();

    //   try {
    //     var successful = document.execCommand("copy");
    //     var msg = successful ? "successful" : "unsuccessful";
    //     console.log("Fallback: Copying text command was " + msg);
    //   } catch (err) {
    //     console.error("Fallback: Oops, unable to copy", err);
    //   }

    //   document.body.removeChild(textArea);
    // },
  },
};
</script>