<template>
    <div class="text-center">
        <b-container class="my-4">
          <b-row align-v="center">
            <b-col>
              <img
              v-if="image"
                src="@/assets/icons/sync.svg"
                class="mb-2"
                width="150rem"
                height="150rem"
              />
              <h3 class="my-2 ">
                <strong>
                 해당 강의실의 데이터가 발견되지 않았습니다,<br/> 시험,학생 추가 및 시험지 스캔을 통해 데이터를 추가해주십시오.
                </strong>
              </h3>
            </b-col>
          </b-row>
        </b-container>
      </div>
</template>

<script>
export default {
  props: {
    image: {
    default: true,
    type: Boolean
  },
    obj: String
  }
}
</script>