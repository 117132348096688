<template>
  <div>
    <!-- Classroom Overview -->
    <b-modal
      id="classroom-overview"
      ref="classroom-overview-modal"
      centered
      hide-footer
      hide-header
      scrollable
      size="xl"
    >
      <template #default="{ close }">
        <b-row align-h="end" class="mb-4">
          <b-col>
            <strong class="title">강의실 Overview</strong>
          </b-col>
          <b-col class="text-right">
            <b-icon-x @click="close()" style="cursor: pointer" />
          </b-col>
        </b-row>
        <Overview :classroomId="selected" />
        <small class="mt-3">
          문제를 겪고 계시다면,
          <b-link :href="`mailto:erudiolabs@gmail.com`"
            >여기로 연락 부탁드립니다</b-link
          >:)
        </small>
      </template>
    </b-modal>

    <!-- Classroom QR -->
    <b-modal
      id="classroom-qr-modal"
      ref="classroom-qr-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">강의실 QR코드 보기</strong></h3>
      </template>
      <ShowQR :data="`${originUrl}/class?id=${selected}`" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>
    <b-card class="text-center px-3" no-body>
      <b-container class="my-4" fluid>
        <div v-if="classrooms == null">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else-if="classrooms.length == 0">
          <b-row align-v="center">
            <b-col>
              <img
                src="@/assets/icons/connect.svg"
                class="mb-2"
                width="150rem"
                height="150rem"
              />
              <h3 class="my-2">
                <strong>
                  시험지 정보 분석 및 리포트 제작, 손쉬운 OMR 스캔과 함께
                  하세요.
                </strong>
              </h3>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row align-v="end" class="mb-4">
            <b-col class="text-left" md="4">
              <h3 class="my-1">Classrooms</h3>
            </b-col>
            <b-col class="text-right my-1" md="3" offset-md="2">
              <b-form-select
                v-model="userSelected"
                :options="
                  users.map((e) => {
                    return { text: e.name, value: e.userId };
                  })
                "
              >
                <b-form-select-option :value="null"
                  >강사 선택 (Select All)</b-form-select-option
                >
              </b-form-select>
            </b-col>
            <b-col class="text-left my-1" md="3">
              <b-form-select
                v-model="classroomSelected"
                :options="
                  classrooms
                    .filter((e) =>
                      userSelected != null ? e.userId == userSelected : e
                    )
                    .map((e) => {
                      return { text: e.name, value: e.classroomId };
                    })
                "
              >
                <b-form-select-option :value="null"
                  >강의실 선택 (Select All)</b-form-select-option
                >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col v-if="classrooms != null">
              <b-table
                stacked="sm"
                head-variant="dark"
                :fields="fields"
                :items="
                  classrooms
                    .filter((e) =>
                      userSelected != null ? e.userId == userSelected : e
                    )
                    .filter((e) =>
                      classroomSelected != null
                        ? e.classroomId == classroomSelected
                        : e
                    )
                "
              >
                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="overview(row.item.classroomId)"
                    :id="`overview-classroom-${row.item.classroomId}`"
                    variant="white"
                  >
                    <b-icon-bar-chart-line />
                  </b-button>
                  <b-tooltip
                    :target="`overview-classroom-${row.item.classroomId}`"
                    triggers="hover"
                    top
                  >
                    Overview
                  </b-tooltip>
                </template>

                <!-- <template #cell(user)="row">
                  {{ users.filter(u => u.userId == row.item.userId)[0].name }}
                </template> -->

                <template #cell(name)="row">
                  {{ row.item.name }}
                </template>

                <!-- <template #cell(classroomCreated)="row">
                  {{
                    new Date(
                      row.item.classroomCreated * 1000
                    ).toLocaleDateString("en-US")
                  }}
                </template> -->

                <template #cell(public)="row">
                  {{ row.item.public ? "공개" : "비공개" }}

                  <span v-if="row.item.public">
                    <b-button
                      class="ml-1"
                      size="sm"
                      variant="white"
                      @click="openClassroom(row.item.classroomId)"
                    >
                      <b-icon-share />
                    </b-button>

                    <b-button
                      size="sm"
                      @click="showClassroomQR(row.item.classroomId)"
                      :id="`show-classroom-qr-${row.item.classroomId}`"
                      variant="white"
                    >
                      <b-icon-upc-scan />
                    </b-button>
                    <b-tooltip
                      :target="`show-classroom-qr-${row.item.classroomId}`"
                      triggers="hover"
                      top
                    >
                      강의실 QR 코드 보기
                    </b-tooltip>
                  </span>
                </template>

                <template #row-details="row">
                  <div class="my-3">
                    <div>
                      <b-row>
                        <b-col>
                          <Exams
                            :classroomId="row.item.classroomId"
                            :examIds="row.item.examIds"
                            :filter="filter"
                          />
                        </b-col>
                      </b-row>
                      <div v-if="row.item.studentIds.length > 0">
                        <hr />

                        <b-row>
                          <b-col>
                            <Students :classroomId="row.item.classroomId" />
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row class="text-center">
                      <b-col>
                        <span class="text-white">
                          <small> {{ row.item.classroomId }}</small></span
                        >
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Overview from "@/components/Overview/Classroom.vue";
import ShowQR from "@/components/Home/ShowQR.vue";

import Students from "@/components/Classroom/Students.vue";
import Exams from "@/components/Exam/TeamExams.vue";
// import NotFound from "@/components/Exam/NotFound.vue";

export default {
  components: {
    Overview,
    ShowQR,
    Students,
    Exams,
  },
  data() {
    return {
      originUrl: window.location.origin,
      filter: "",
      fields: [
        {
          key: "user",
          label: "강사",
          sortable: true,
        },
        {
          key: "code",
          label: "강의실 코드",
          sortable: true,
        },
        {
          key: "name",
          label: "강의실 이름",
          sortable: true,
        },
        {
          key: "actions",
          label: "오버뷰",
        },
      ],
      selected: null,
      classrooms: null,
      users: null,
      userSelected: null,
      classroomSelected: null,
    };
  },
  watch: {
    userSelected() {
      this.classroomSelected = null;
    },
  },
  methods: {
    showClassroomQR: function (cId) {
      this.selected = cId;
      this.$refs[`classroom-qr-modal`].show();
    },
    overview: function (cId) {
      this.selected = cId;
      this.$refs[`classroom-overview-modal`].show();
    },
    openClassroom: function (id) {
      this.copyUrl(id);
      window.open(`/class?id=${id}`, "_blank");
    },
    copyUrl: function (id) {
      let url = window.location.href;
      this.fallbackCopyTextToClipboard(
        `${url.slice(0, url.length - 5)}/class?id=${id}`
      );
      alert("클립보드에 리포트 주소가 복사되었습니다.");
    },
    fallbackCopyTextToClipboard: function (text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    updateClassrooms: function () {
      this.getClassrooms();
    },

    getAllClassrooms() {
      const url = this.$url;

      const token = localStorage.getItem("token");

      fetch(url + "/api/class/team", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({ teamId: this.teamId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.users = json.users;
            this.classrooms = json.classrooms.map((e) => {
              e.user = this.users.filter((u) => u.userId == e.userId)[0].name;
              return e;
            });
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  mounted() {
    this.getAllClassrooms();
  },
};
</script>