<template>
  <div>
    <div v-if="classroom == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else class="mb-3">
      <div v-if="exams.length == 0">
        <NotFound />
      </div>
      <div v-else-if="exams.length != 0">
        <!-- <b-row  class="mx-2 my-3 text-center" v-if="exams != null">
          <b-col>
            <b-card
              style="
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
                border-radius: 40px;
              "
              class="pt-2"
              border-variant="light"
            >
              <strong> 총 테스트 </strong>

              <h2><strong>{{ exams.length }} 개</strong></h2>
            </b-card>
          </b-col>
          <b-col>
            <b-card
              style="
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
                border-radius: 40px;
              "
              class="pt-2"

              border-variant="light"
            >
              <strong> 테스트 인원 </strong>

              <h2><strong>{{ classroom.studentIds.length }} 명</strong></h2>
            </b-card>
          </b-col>
        </b-row>
        <hr /> -->

        <b-form-select
          v-model="selected"
          :options="
            exams.map((e) => {
              return { text: `${e.name} (${e.code})`, value: e.examId };
            })
          "
        >
        </b-form-select>
        <hr />
        <div v-if="students.length == 0">
          <NotFound />
        </div>
        <div v-else>
          <Exam
            :students="students"
            :exam="exams.filter((e) => e.examId == selected)[0]"
            :key="selected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Exam from "@/components/Overview/Exam.vue";
import NotFound from "@/components/Overview/NotFound.vue";

export default {
  components: {
    Exam,
    NotFound,
  },
  props: {
    classroomId: String,
  },
  data() {
    return {
      selected: null,
      classroom: null,
      exams: null,
      students: null,
    };
  },
  mounted() {
    this.getClassroom();
  },
  methods: {
    getClassroom: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const classroom = { classroomId: this.classroomId };

      fetch(url + `/api/class/overview/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(classroom),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.classroom = json.classroom;
            this.students = json.students;
            this.exams = json.exams;
            if (this.exams.length > 0) {
              this.selected =
                json.exams[
                  json.exams.reduce((iMax, x, i, arr) => {
                    return x.examCreated > arr[iMax].examCreated ? i : iMax;
                  }, 0)
                ].examId;
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>