<template>
  <div>
    <div v-if="students != null">
      <b-row align-v="center">
        <b-col class="text-left my-1" md="9">
          <h3 class="ml-3">수강생 리스트 ({{ students.length }} 명)</h3>
        </b-col>
        <b-col class="text-right my-1" md="3">
          <b-form-input v-model="filter" placeholder="학생 검색"></b-form-input>
        </b-col>
      </b-row>

      <hr />

      <b-row align-v="center">
        <b-col style="width: 100%; overflow-x: auto">
          <b-table hover :items="students" :fields="fields" :filter="filter">
            <template #cell(name)="row">
              {{ row.item.name }} | {{ row.item.code }}
            </template>

            <template #cell(action)="row">
              <b-button
                size="sm"
                :id="`recent-result-classroom-${row.item.studentId}`"
                variant="white"
                @click="getRecentResult(row.item.studentId)"
              >
                <b-icon icon="file-earmark-check"></b-icon>
              </b-button>
              <b-tooltip
                :target="`recent-result-classroom-${row.item.studentId}`"
                triggers="hover"
                top
              >
                최근 테스트 결과
              </b-tooltip>

              <b-button
                :id="`delete-student-classroom-${row.item.studentId}`"
                size="sm"
                @click="deleteStudent(row.item.studentId)"
                variant="white"
              >
                <b-icon icon="x-circle"></b-icon>
              </b-button>
              <b-tooltip
                :target="`delete-student-classroom-${row.item.studentId}`"
                triggers="hover"
                top
              >
                학생 강의실에서 삭제하기
              </b-tooltip>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center mb-3">
        <!-- Loading... -->
        <!-- <b-spinner class="m-5"  label="Loading..."></b-spinner> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classroomId: String,
  },
  data() {
    return {
      students: null,
      filter: "",
      fields: [
        {
          key: "name",
          label: "수강생 이름/번호",
          sortable: true,
        },
        {
          key: "action",
          label: "액션",
        },
      ],
    };
  },
  mounted() {
    this.getStudents();
  },
  methods: {
    deleteStudent: function (sId) {
      this.overlay = true;
      const url = this.$url;

      const token = localStorage.getItem("token");

      const studentId = {
        studentId: sId,
        classroomId: this.classroomId,
      };

      fetch(url + "/api/class/delete/student", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(studentId),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.getStudents();
            this.$emit("removed", studentId);
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getRecentResult: function (sId) {
      const url = this.$url;

      const token = localStorage.getItem("token");

      fetch(url + `/api/result/recent/${this.classroomId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({ studentId: sId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            if (json.result != null) {
              window.open(`${url}/result?id=${json.result.resultId}`);
            } else {
              alert(
                "시험 결과가 발견되지 않았습니다, 스캔을 통해 추가 해주시기 바랍니다"
              );
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getStudents: function () {
      const url = this.$url;

      const token = localStorage.getItem("token");

      fetch(url + "/api/class/students", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({ classroomId: this.classroomId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.students = json.students;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
};
</script>