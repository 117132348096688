<template>
  <div>
    <!-- Edit Answer -->
    <b-modal
      id="edit-answer-modal"
      :ref="`edit-answer-modal`"
      centered
      hide-footer
      v-if="data != null"
    >
      <template v-slot:modal-title>
        <h3>
          <strong class="title">정답 바로 수정</strong>
        </h3>
      </template>
      <EditAnswer
        @edited="edited"
        :resultId="data.result.resultId"
        :answerIdx="answerSelected"
        :answerChosen="data.result.answersChosen[answerSelected]"
      />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다.</b-link
        >
      </small>
    </b-modal>

    <b-overlay
      :show="overlay"
      spinner-variant="dark"
      style="min-height: 90vh"
      spinner-type="grow"
    >
      <div v-if="data != null">
        <TopHero
          :teamName="data.team.name"
          :userName="data.user.name"
          :classroomName="data.classroom.name"
          :examName="data.exam.name"
          :url="data.exam.url"
          :resultCreated="data.result.resultCreated"
          :lastUpdated="data.result.lastUpdated"
          :name="data.student.name"
          :imageUrl="imageUrl"
          :admin="data.user.email == email && data.user.name == name"
          @deleteReport="deleteReport"
        />
        <b-container fluid class="my-3 text-center">
          <b-row
            align-h="center"
            class="mt-4"
            v-if="
              data.result.content != '' ||
              (data.user.email == email && data.user.name == name)
            "
          >
            <b-col xl="8">
              <h3 class="display text-left ml-3 mb-3">
                <strong>강사 코멘트</strong>
              </h3>
              <hr />
              <div v-if="data.user.email == email && data.user.name == name">
                <form @submit.prevent @submit="saveResult()">
                  <div class="form-group">
                    <b-form-textarea
                      id="textarea"
                      size="lg"
                      class="form-control"
                      v-model="content"
                      placeholder="(옵션) 코멘트을 입력해 주십시오... "
                      rows="6"
                      max-rows="10"
                      :disabled="overlay"
                    ></b-form-textarea>
                  </div>
                  <b-row align-h="end">
                    <b-col md="3">
                      <b-button variant="dark" type="submit" block
                        >저장하기</b-button
                      >
                    </b-col>
                  </b-row>
                </form>
              </div>
              <div v-else>
                <b-row align-h="center">
                  <b-col class="my-2">
                    <b-card>
                      <!-- <span v-html="data.result.content" /> -->
                      <h5>
                        <pre
                          class="mb-0 mt-2 text-left"
                          style="
                            white-space: pre-wrap;
                            overflow-wrap: break-word;
                            overflow: hidden;
                          "
                          >{{ data.result.content }}</pre
                        >
                      </h5>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row
            align-h="center"
            class="mt-4"
            v-if="evaluationComments.length > 0"
          >
            <b-col xl="8">
              <h3 class="display text-left ml-3 mb-3">
                <strong>영역/종합 평가</strong>
              </h3>
              <hr />
              <b-row align-h="center">
                <b-col class="my-2">
                  <b-card class="text-left">
                    <h5
                      v-for="(category, idx) in Object.keys(categories)"
                      v-bind:key="`cat-${idx}`"
                    >
                      <div
                        v-if="
                          evaluationComments.filter(
                            (e) =>
                              e.category == category &&
                              categories[category][0] >= e.range[0] &&
                              e.range[1] >= categories[category][0]
                          ).length > 0
                        "
                      >
                        <strong
                          >{{ category }} ({{ categories[category][0] }}점/{{
                            categories[category][1]
                          }}점)</strong
                        >
                        <hr />
                        <p>
                          <small>
                            {{
                              evaluationComments.filter(
                                (e) =>
                                  e.category == category &&
                                  categories[category][0] >= e.range[0] &&
                                  e.range[1] >= categories[category][0]
                              )[0].content
                            }}
                          </small>
                        </p>
                      </div>
                    </h5>

                    <h5>
                      <div
                        v-if="
                          evaluationComments.filter(
                            (e) =>
                              e.category == null &&
                              data.result.score >= e.range[0] &&
                              e.range[1] >= data.result.score
                          ).length > 0
                        "
                      >
                        <strong>종합 평가</strong>
                        <hr />
                        <p>
                          <small>
                            {{
                              evaluationComments.filter(
                                (e) =>
                                  e.category == null &&
                                  data.result.score >= e.range[0] &&
                                  e.range[1] >= data.result.score
                              )[0].content
                            }}
                          </small>
                        </p>
                      </div>
                    </h5>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-4 justify-content-md-center">
            <b-col xl="8">
              <h3 class="display text-left ml-3 mb-3">
                <strong>시험 통계</strong>
              </h3>
              <hr />
              <b-row>
                <b-col lg="4" class="my-2"
                  ><b-card title="원점수/총점/시험평균">
                    <b-card-text>
                      <h3>
                        <strong
                          >{{ data.result.score }}점·{{
                            data.exam.totalScore
                          }}점·{{
                            Math.round(scoreAverage * 100) / 100
                          }}점</strong
                        >
                      </h3>
                    </b-card-text>
                  </b-card></b-col
                >
                <b-col lg="4" class="my-2"
                  ><b-card title="맞은 문항수/총 문항수">
                    <b-card-text>
                      <h3>
                        <strong
                          >{{
                            answers.reduce((acc, e, idx) => {
                              if (
                                arraysEqual(e, data.result.answersChosen[idx])
                              ) {
                                acc = acc + 1;
                              }
                              return acc;
                            }, 0)
                          }}개·{{ answers.length }}개</strong
                        >
                      </h3>
                    </b-card-text>
                  </b-card></b-col
                >
                <b-col lg="4" class="my-2"
                  ><b-card title="표준편차(수강자 수)/백분율">
                    <b-card-text>
                      <h3>
                        <strong
                          >{{ studentSD }}({{ data.scores.length }})·{{
                            Math.round(studentPercentile * 100) / 100
                          }}%</strong
                        >
                      </h3>
                    </b-card-text>
                  </b-card></b-col
                >
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-4 justify-content-md-center">
            <b-col xl="8">
              <h3 class="display text-left ml-3 mb-3">
                <strong>성적 추이/유형</strong>
              </h3>
              <hr />
              <b-row align-h="center">
                <b-col
                  xl="6"
                  class="text-center my-2 d-flex align-items-stretch"
                >
                  <b-card style="min-width: 100%">
                    <RadarChart
                      :answers="data.exam.answers"
                      :student="data.result.answersChosen"
                      :results="data.results"
                    />
                  </b-card>
                </b-col>
                <b-col
                  xl="6"
                  class="text-center my-2 d-flex align-items-stretch"
                >
                  <b-card style="min-width: 100%">
                    <LineChart
                      :chart-data="history.chartData"
                      :options="history.options"
                    />
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-4 justify-content-md-center">
            <b-col xl="8">
              <h3 class="display text-left ml-3 mb-3">
                <strong>문항별 정오표</strong>
              </h3>
              <b-table
                hover
                :fields="fields"
                :items="
                  data.result.answersChosen.map((e, idx) => {
                    return {
                      idx: idx,
                      question: `${idx + 1} | ${
                        data.exam.answers[idx].questionCategory == undefined
                          ? '기본'
                          : data.exam.answers[idx].questionCategory
                      }`,
                      studentAnswer: e,
                      answer: answers[idx],
                      correct: convertBool(arraysEqual(answers[idx], e)),
                      _rowVariant: arraysEqual(answers[idx], e)
                        ? 'white'
                        : 'danger',
                    };
                  })
                "
              >
                <template #cell(answer)="row">
                  {{ row.item.answer }} |
                  <b-link
                    @click="editAnswer(row.item.idx)"
                    v-if="data.user.email == email && data.user.name == name"
                    >{{ row.item.studentAnswer }}</b-link
                  ><span v-else>{{ row.item.studentAnswer }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row align-h="center" class="mt-4">
            <b-col xl="8">
              <h3 class="display text-left ml-3 mb-3">
                <strong>오답 평가</strong>
              </h3>
              <hr />
              <b-row>
                <b-col class="my-2">
                  <b-card>
                    <b-row align-h="center" cols="1" cols-xl="3">
                      <b-col
                        class="text-center my-1"
                        v-for="(e, eIdx) in evaluations"
                        v-bind:key="eIdx"
                      >
                        <b-card style="min-height: 100%">
                          <strong> {{ e.question }} 번</strong> <br />

                          <p v-if="e.evaluation" class="text-left mt-2 mb-0">
                            {{ e.evaluation }}
                          </p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-overlay>
    <Footer />
  </div>
</template>

<script>
import ChannelService from "../ChannelService.js";

import EditAnswer from "@/components/Report/EditAnswer.vue";

import TopHero from "@/components/Report/TopHero.vue";
import LineChart from "@/components/Report/LineChart.vue";
import RadarChart from "@/components/Report/RadarChart.vue";

import Footer from "@/components/Footer.vue";

export default {
  name: "Report",
  components: {
    EditAnswer,
    TopHero,
    LineChart,
    RadarChart,
    Footer,
  },
  mounted() {
    ChannelService.shutdown();

    window.scrollTo(0, 0);
    this.getExamResult();
  },
  computed: {
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
    evaluations() {
      return this.data.result.answersChosen.reduce((a, e, idx) => {
        if (!this.arraysEqual(this.answers[idx], e)) {
          console.log(this.data.exam.answers[idx]);
          a.push({
            question: idx + 1,
            evaluation: `${
              this.data.exam.answers[idx].desc
                ? this.data.exam.answers[idx].desc + " "
                : ""
            }${
              this.data.exam.answers[idx].evaluation &&
              this.data.exam.answers[idx].evaluation[e]
                ? this.data.exam.answers[idx].evaluation[e]
                : ""
            }`,
          });
        }
        return a;
      }, []);
    },
    scoreAverage() {
      return this.average(this.data.scores);
    },
    studentSD() {
      return Math.round(this.standardDeviation(this.data.scores) * 1000) / 1000;
    },
    studentPercentile() {
      return this.percentile(this.data.scores, this.data.result.score);
    },
  },

  data() {
    return {
      answerSelected: null,
      content: "",
      overlay: true,
      imageUrl: "",
      data: null,
      answers: null,
      evaluationComments: [],
      categories: {},
      fields: [
        { key: "question", label: "번호 | 유형", sortable: true },
        { key: "answer", label: "정답 | 학생 답안" },
        { key: "correct", label: "정오", sortable: true },
      ],
      history: {
        chartData: {},
        options: {
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "테스트",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "시험 점수",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      },
    };
  },

  methods: {
    deleteReport: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      fetch(url + `/api/result/delete/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          resultId: this.data.result.resultId,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            alert("성공적으로 삭제 되었습니다!");
            this.$router.push({ path: "/home" });
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
        });
    },
    editAnswer: function (idx) {
      this.$refs["edit-answer-modal"].show();
      this.answerSelected = idx;
    },
    edited: function () {
      this.$refs["edit-answer-modal"].hide();

      this.getExamResult();
    },
    saveResult: function () {
      console.log(this.content);

      const url = this.$url;

      let token = localStorage.getItem("token");

      fetch(url + `/api/result/edit/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          resultId: this.data.result.resultId,
          content: this.content,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.getExamResult();
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.content = this.data.result.content;
          this.overlay = false;
        });
    },
    percentile: function (array, val) {
      let sortedScores = array.sort((a, b) => b - a);
      let rank = sortedScores.findIndex((e) => e == val) + 1;
      const r = array.reduce((a, e) => {
        if (e == val) {
          a = a + 1;
        }
        return a;
      }, 0);
      let percentile = ((array.length - rank + 0.5 * r) / array.length) * 100;
      // console.log(array.sort((a, b) => b - a));
      return percentile;
    },
    average: function (array) {
      return array.reduce((a, b) => a + b) / array.length;
    },
    standardDeviation: function (values) {
      let avg = this.average(values);

      let squareDiffs = values.map(function (value) {
        let diff = value - avg;
        let sqrDiff = diff * diff;
        return sqrDiff;
      });

      let avgSquareDiff = this.average(squareDiffs);

      let stdDev = Math.sqrt(avgSquareDiff);
      return stdDev;
    },
    convertBool: function (e) {
      return e ? "O" : "X";
    },
    arraysEqual: function (a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    getExamResult: function () {
      this.overlay = true;
      if (this.$route.query.id) {
        const url = this.$url;

        fetch(`${url}/api/result/exam/${this.$route.query.id}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              console.log(json);

              this.data = json;
              this.content = json.result.content;
              this.imageUrl = json.team.imageUrl;
              this.evaluationComments = json.exam.evaluationComments;

              this.categories = json.exam.answers.reduce((a, e) => {
                if (!a[e.questionCategory]) {
                  a = Object.assign(a, {
                    [e.questionCategory]: [0, e.questionPoints],
                  });
                } else {
                  a[e.questionCategory][1] += e.questionPoints;
                }
                if (
                  this.arraysEqual(
                    e.questionAnswer,
                    this.data.result.answersChosen[e.questionNumber - 1]
                  )
                ) {
                  a[e.questionCategory][0] += e.questionPoints;
                }
                return a;
              }, {});

              console.log(this.categories);

              this.answers = this.data.exam.answers.map(
                (e) => e.questionAnswer
              );

              this.history.chartData = {
                labels: [
                  "",
                  ...this.data.history.map((e) =>
                    new Date(e.resultCreated * 1000).toLocaleDateString()
                  ),
                  "",
                ],
                datasets: [
                  {
                    label: "시험 결과",
                    backgroundColor: "#ffffff",
                    borderColor: "#FC2525",
                    // pointBackgroundColor: "#da3e2f",
                    borderWidth: 1,
                    // pointBorderColor: "#da3e2f",
                    data: [
                      null,
                      ...this.data.history.map((e) => e.score),
                      null,
                    ],
                  },
                ],
              };

              this.history.options = {
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                  mode: "index",
                  intersect: false,
                },
                hover: {
                  mode: "nearest",
                  intersect: true,
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: "테스트",
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: "시험 점수",
                      },
                      ticks: {
                        beginAtZero: true,
                        suggestedMax: this.data.exam.totalScore,
                      },
                    },
                  ],
                },
              };

              this.overlay = false;
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            this.$router.push({ path: "/" });
          });
      } else {
        alert("result id가 제공되지 않았습니다.");
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>