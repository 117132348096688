<template>
  <div>
    <div v-if="exam == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else>
      <form @submit.prevent="editExam">
        <!-- {{exam}} -->
        <div v-if="!math">
          <b-form-group
            :label="`${answerIdx + 1}번 문항`"
            v-for="(answer, answerIdx) in answers"
            v-bind:key="answerIdx"
          >
            <b-form-checkbox-group
              class="text-center"
              :id="`radio-group-${answerIdx}`"
              v-model="answers[answerIdx].questionAnswer"
              size="lg"
              :options="options"
              name="radio-options"
            ></b-form-checkbox-group>

            <b-row class="my-1">
              <b-col>
                <b-form-input
                  v-model="answers[answerIdx].questionCategory"
                  placeholder="유형"
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-input
                  v-model="answers[answerIdx].desc"
                  placeholder="설명/세부유형"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </div>

        <div v-else>
          <b-form-group
            :label="`${answerIdx + 1}번 문항`"
            v-for="(answer, answerIdx) in answers"
            v-bind:key="answerIdx"
          >
            <b-form-checkbox-group
              v-if="answerIdx < 21"
              class="text-center"
              :id="`radio-group-${answerIdx}`"
              v-model="answers[answerIdx].questionAnswer"
              size="lg"
              :options="options"
              name="radio-options"
            ></b-form-checkbox-group>

            <b-form-input
              v-else
              type="number"
              size="lg"
              class="form-control"
              placeholder="단답형 정답 *"
              v-model="answers[answerIdx].questionAnswer[0]"
              :disabled="overlay"
              autocomplete="off"
              min="0"
              max="999"
              required
            />
          </b-form-group>
        </div>

        <b-overlay :show="overlay" rounded="sm">
          <b-button
            block
            type="submit"
            size="lg"
            variant="dark"
            class="mt-4 mb-1"
            :disabled="overlay"
            ><strong>수정하기 </strong></b-button
          >
        </b-overlay>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examId: String,
  },
  data() {
    return {
      exam: null,
      math: false,
      answers: [],
      overlay: false,
      options: [1, 2, 3, 4, 5],
    };
  },
  mounted() {
    this.getExam();
  },
  methods: {
    arraysEqual: function (a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    editExam: function () {
      this.overlay = true;
      let url = `${this.$apiUrl}/scanner/exam/update`;

      let token = localStorage.getItem("token");

      let newExam = JSON.parse(JSON.stringify(this.exam));
      newExam.answers = this.exam.answers.map((e, i) => {
        e.questionAnswer = this.answers[i].questionAnswer
          .map((e) => parseInt(e))
          .sort();
        return e;
      });

      if (
        !this.arraysEqual(
          this.exam.answers.map((e) => e.questionAnswer),
          this.answers.map((e) => e.questionAnswer)
        )
      ) {
        fetch(`${url}`, {
          method: "POST",
          headers: {
            authorization: token,
          },
          body: JSON.stringify({
            examId: this.examId,
            exam: newExam,
          }),
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              console.log(json);
              this.$emit("edited");
              alert("성공적으로 수정 반영되었습니다.");
              this.overlay = false;
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            this.overlay = false;
          });
      } else {
        alert("수정된 항목이 존재하지 않습니다.");
        this.overlay = false;
      }
    },
    getExam: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      fetch(url + `/api/class/exam/${this.examId}`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);

            this.exam = json.exam;
            this.answers = json.exam.answers.map((a) => ({ ...a }));
            this.math =
              this.answers.filter((e) => e[0] > 5 || e[0] < 1).length > 0;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>