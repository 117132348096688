<template>
  <div style="padding-bottom: 5vh">
    <!-- <b-jumbotron
      class="rounded-0 text-white"
      style="background: black; padding-bottom: 10vh"
    > -->
    <div style="animation: fadein 3s, slidein 2s" class="pt-5">
      <b-row align-h="center">
        <b-col xl="10">
          <hr style="background: white" />
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center" class="text-center my-3">
        <b-col xl="4">
          <strong> 누적 학생 수</strong> <small>*</small><br />
          <h1>
            <strong class="sub-title" style="font-weight: 900">
              {{ students.toLocaleString() }}
            </strong>
          </h1></b-col
        ><b-col xl="4">
          <strong> OMR 스캐너로 채점된 문제수 </strong><small>*</small><br />
          <h1>
            <strong>{{ questions.toLocaleString() }}</strong>
          </h1>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col xl="10">
          <hr style="background: white" />
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col xl="10" class="text-right text-muted">
          <small> * 자체 알고리즘을 통해 계산된 근삿값입니다. </small>
        </b-col>
      </b-row>
    </div>
    <!-- </b-jumbotron> -->
  </div>
</template>


<script>
export default {
  mounted() {
    this.getStats();
  },
  data() {
    return {
      students: 0,
      questions: 0,
    };
  },
  methods: {
    getStats: function () {
      const url = `${this.$apiUrl}/scanner/stats`;

      fetch(`${url}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.animateStudents(json.students);
            this.animateQuestions(json.results * 80);
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    animateStudents: function (end) {
      let inc = Math.round(end / 200);
      if (inc == 0) {
        inc = 1;
      }
      let timer = setInterval(() => {
        this.students += inc;
        if (this.students >= end) {
          clearInterval(timer);
        }
      }, 1);
    },
    animateQuestions: function (end) {
      let inc = Math.round(end / 200);
      if (inc == 0) {
        inc = 1;
      }
      let timer = setInterval(() => {
        this.questions += inc;
        if (this.questions >= end) {
          clearInterval(timer);
        }
      }, 1);
    },
  },
};
</script>