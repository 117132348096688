<template>
  <div class="text-center">
    <b-overlay
      :show="overlay"
      rounded="sm"
      style="margin-bottom: 10px"
      variant="white"
    >
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..."></b-spinner>
          <div class="mt-3" v-if="files.length !== 0">
            <h5 id="cancel-label" class="px-4">
              데모용 스캐너 스캔 중 ... ({{ processed }}/{{ files.length }})
            </h5>
            <b-progress
              :value="(processed / files.length) * 100"
              striped
              animated
              variant="dark"
            ></b-progress>
          </div>
        </div>
      </template>

      <b-row class="mt-2" align-h="start">
        <b-col xl="8" class="text-left">
          <b-form-checkbox v-model="camera" class="my-1" size="lg" switch>
            {{
              camera ? "카메라 (갤러리 모드: OFF)" : "갤러리 (카메라 모드: OFF)"
            }}
          </b-form-checkbox>
        </b-col>
        <b-col xl="4">
          <b-button
            variant="light"
            target="_blank"
            size="sm"
            class="my-1"
            block
            href="https://drive.google.com/file/d/1677RPmHxmQ0ooS1wGWXVfxSnHgdrRT-C/view?usp=sharing"
            ><strong> OMR 용지</strong></b-button
          >
        </b-col>
      </b-row>

      <hr />

      <b-form @submit="showPrompt" @submit.prevent>
        <b-form-group>
          <!-- Styled -->
          <b-form-file
            class="my-1 text-left"
            v-model="files"
            :state="Boolean(files)"
            multiple
            placeholder="이곳을 눌러 주세요 :)"
            drop-placeholder="여기에 놓아주세요..."
            accept="image/*"
            :capture="camera"
          ></b-form-file>
        </b-form-group>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="files.length == 0 || overlay"
        >
          <strong class="sub-title">스캔하기 (데모용 스캐너 속도: 원활)</strong>
        </b-button>

        <p class="text-right">
          <small
            >* 정확한 결과를 위해 밝은 환경, 정면 사진으로 스캔 부탁드립니다.
          </small>
        </p>

        <div v-if="results.length > 0">
          <hr />

          <b-row class="mt-3">
            <b-col>
              {{ results }}
            </b-col>
          </b-row>
        </div>

        <div v-if="errors.length > 0">
          <hr />

          <b-row class="mt-3">
            <b-col>
              {{ errors }}
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      camera: true,
      processed: 0,
      files: [],
      errorIdx: [],
      errors: [],
      file: null,
      result: null,
      results: [],
    };
  },
  methods: {
    showPrompt: function () {
      this.uploadFiles();
    },
    uploadFiles: async function () {
      this.overlay = true;
      this.result = null;
      this.results = [];
      this.errors = [];
      if (this.files.length != 0) {
        let result;
        for (let fileIdx in this.files) {
          this.processed = this.processed + 1;
          console.log(this.progress);
          result = await this.uploadFile(this.files[fileIdx], fileIdx);
          if (!result.error) {
            this.results.push(result.result);
          } else {
            console.log(result);
            this.errors.push(result);
          }
        }

        if (this.errorIdx.length == 0) {
          alert("스캔이 성공적으로 완료되었습니다");
        } else {
          alert(
            `${this.errorIdx.reduce((a, e) => {
              return (a += `"${parseInt(e) + 1}번" `);
            }, "")} 파일(들)에 에러가 있었습니다`
          );
        }
      }

      this.files = [];
      this.overlay = false;
      this.processed = 0;
    },
    uploadFile: function (file, fileIdx) {
      return new Promise((resolve) => {
        if (file != null) {
          console.log(file);
          let formData = new FormData();
          formData.append("image", file);
          formData.append("name", file.name);

          fetch(`${this.$apiUrl}/scanner/demo`, {
            method: "POST",
            body: formData,
          })
            .then((res) => res.json())
            .then((json) => {
              if (!json.error) {
                resolve(json);
              } else {
                throw new Error(json.error);
              }
            })
            .catch((error) => {
              this.errorIdx.push(fileIdx);
              alert(error);

              resolve({ error: error.toString(), index: fileIdx });
            });
        }
      });
    },
  },
};
</script>