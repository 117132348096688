<template>
  <div>
    <div v-if="results == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>

    <div v-else-if="results.length != 0" class="mb-3">
      <b-row class="text-center">
        <b-col class="my-1" md="4">
          <b-card>
            <b-card-text>
              <strong>시험제출 현황 </strong>
            </b-card-text>

            <h1>
              <strong
                >{{
                  Math.round((results.length / students.length) * 10000) / 100
                }}%</strong
              >
            </h1>
            <b-card-text
              >총 {{ students.length }}명 중 {{ results.length }}명
              완료</b-card-text
            >
          </b-card>
        </b-col>
        <b-col class="my-1" md="4">
          <b-card>
            <b-card-text>
              <strong>시험 평균</strong>
            </b-card-text>

            <h1>
              <strong>{{ Math.round(scoreAverage * 100) / 100 }}점</strong>
            </h1>
            <b-card-text
              >총 {{ exam.questionNum }} 문항,
              {{ exam.totalScore }}점</b-card-text
            >
          </b-card>
        </b-col>
        <b-col class="my-1" md="4">
          <b-card>
            <b-card-text>
              <strong>최상위/최하위</strong>
            </b-card-text>

            <h1>
              <strong>{{ results[results.length - 1].name }}</strong>
            </h1>

            <b-card-text>{{ results[0].name }}</b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-if="
          students.filter(
            (e) => !results.map((e) => e.studentId).includes(e.studentId)
          ).length > 0
        "
      >
        <b-col class="my-1">
          <b-card>
            <b-card-text>
              <strong>미제출자</strong>
            </b-card-text>

            <b-card-text class="text-center"
              ><div>
                <span
                  v-for="(student, idx) in students.filter(
                    (e) =>
                      !results.map((e) => e.studentId).includes(e.studentId)
                  )"
                  v-bind:key="student.studentId"
                  >{{ student.name }}
                  <span
                    v-if="
                      idx !=
                      students.filter(
                        (e) =>
                          !results.map((e) => e.studentId).includes(e.studentId)
                      ).length -
                        1
                    "
                    >·
                  </span></span
                >
              </div></b-card-text
            >
          </b-card>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-col>
          <div v-if="results != null && results.length > 0">
            <BarChart :chart-data="barchart.data" :options="barchart.options" />
          </div>
        </b-col>
      </b-row>

      <div v-if="analysisExam && analysisExam.length > 0">
        <hr />
        <BarChart
          :chart-data="analysisExamChart.data"
          :options="analysisExamChart.options"
        />
        <hr />

        <b-table hover :fields="analysisExamFields" :items="analysisExam">
          <template #cell(question)="row">
            <strong>{{ row.item.question }} 번 </strong>
          </template>
          <template #cell(correctAnswer)="row">
            {{ row.item.correctAnswer.join(", ") }}
          </template>
          <template #cell(percentage)="row">
            <strong
              >{{ Math.round(row.item.percentage * 1000) / 10 }} %
            </strong>
          </template>
          <template #cell(answers)="row">
            <span
              v-for="(e, i) in Object.entries(row.item.answers).sort(
                (a, b) => b[1] - a[1]
              )"
              :key="`answer-${row.index}-${i}`"
              >{{ e }}
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <div v-else>
      <NotFound />
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/Overview/BarChart.vue";

import NotFound from "@/components/Overview/NotFound.vue";

export default {
  components: {
    BarChart,
    NotFound,
  },
  props: {
    exam: Object,
    students: Array,
  },
  data() {
    return {
      results: null,
      analysisExam: null,
      analysisExamFields: [
        { key: "question", label: "번호", sortable: true },
        { key: "correctAnswer", label: "정답" },
        { key: "percentage", label: "오답률", sortable: true },
        { key: "answers", label: "학생오답 (오답,빈도)", sortable: true },
      ],
      barchart: {
        data: {},
        options: {},
      },
    };
  },
  mounted() {
    this.getResults();
    this.getExamAnalysis();
  },
  computed: {
    scoreAverage() {
      return this.average(this.results.map((e) => e.score));
    },
    analysisExamChart() {
      if (this.analysisExam) {
        const analysis = this.analysisExam.slice(0, 10);
        return {
          data: {
            labels: [...analysis.map((e) => `${e.question}번`)],
            datasets: [
              {
                label: "오답률",
                backgroundColor: "#CC5500",
                borderColor: "#6E260E",
                borderWidth: 1,
                data: [...analysis.map((e) => e.percentage * 100)],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              mode: "index",
              intersect: false,
            },
            hover: {
              mode: "nearest",
              intersect: true,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "오답률(%)",
                  },
                  ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                  },
                },
              ],
            },
          },
        };
      } else {
        return null;
      }
    },
  },
  methods: {
    // Utils
    average: function (array) {
      return array.reduce((a, b) => a + b) / array.length;
    },
    arraysEqual: function (a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },

    // API Requests
    getExamAnalysis: function () {
      const token = localStorage.getItem("token");

      const exam = {
        examId: this.exam.examId,
        classroomId: this.exam.classroomId,
      };

      fetch(`${this.$apiUrl}/scanner/analysis/exam`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);

          this.analysisExam = json.answers
            .map((answer, answerIdx) => {
              let wrongAnswers = [];
              json.results.forEach((result) => {
                let correct = this.arraysEqual(
                  answer,
                  result.answersChosen[answerIdx]
                );
                if (!correct) {
                  wrongAnswers.push(result.answersChosen[answerIdx]);
                }
              });
              return {
                question: answerIdx + 1,
                correctAnswer: answer,
                answers: wrongAnswers,
              };
            })
            .sort((a, b) => {
              return b.answers.length - a.answers.length;
            })
            .reduce((filtered, question) => {
              const percentage = question.answers.length / json.results.length;
              if (percentage) {
                filtered.push({
                  question: question.question,
                  correctAnswer: question.correctAnswer,
                  percentage: percentage,
                  // answers:  Array.from(new Set(e.answers.map(JSON.stringify)), JSON.parse)
                  answers: question.answers.reduce(
                    (cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt),
                    {}
                  ),
                });
              }
              return filtered;
            }, []);
        });
    },
    getResults: function () {
      const url = this.$url;
      let token = localStorage.getItem("token");

      const exam = {
        examId: this.exam.examId,
        classroomId: this.exam.classroomId,
      };

      fetch(url + `/api/class/overview/exam`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.results = json.results
              .map((e) => {
                let student = this.students.filter(
                  (s) => s.studentId == e.studentId
                )[0];
                return {
                  studentId: e.studentId,
                  score: e.score,
                  name: student ? student.name : "N/A",
                };
              })
              .sort((a, b) => a.score - b.score);

            this.barchart.data = {
              labels: [...this.results.map((e) => e.name)],
              datasets: [
                {
                  label: "시험 결과",
                  backgroundColor: "#38786d",
                  borderColor: "#4b7641",
                  // pointBackgroundColor: "#da3e2f",
                  borderWidth: 1,
                  // pointBorderColor: "#da3e2f",
                  data: [...this.results.map((e) => e.score)],
                },
              ],
            };

            this.barchart.options = {
              responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                mode: "index",
                intersect: false,
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: true,
                      labelString: "시험 점수",
                    },
                    ticks: {
                      beginAtZero: true,
                      suggestedMax: this.exam.totalScore,
                    },
                  },
                ],
              },
            };
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>