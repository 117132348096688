<template>
  <div>
    <!-- Create Classroom -->
    <b-modal
      id="create-classroom"
      ref="create-classroom-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">강의실 개설</strong></h3>
      </template>
      <CreateClassroom @added="added" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Classroom Overview -->
    <b-modal
      id="classroom-overview"
      ref="classroom-overview-modal"
      centered
      hide-footer
      hide-header
      scrollable
      size="xl"
    >
      <template #default="{ close }">
        <b-row align-h="end" class="mb-4">
          <b-col>
            <strong class="title">강의실 Overview</strong>
          </b-col>
          <b-col class="text-right">
            <b-icon-x @click="close()" style="cursor: pointer" />
          </b-col>
        </b-row>
        <Overview :classroomId="selected" />
        <small class="mt-3">
          문제를 겪고 계시다면,
          <b-link :href="`mailto:erudiolabs@gmail.com`"
            >여기로 연락 부탁드립니다</b-link
          >:)
        </small>
      </template>
    </b-modal>

    <!-- Edit Classroom -->
    <b-modal
      id="edit-classroom"
      ref="edit-classroom-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">강의실 설정</strong></h3>
      </template>
      <EditClassroom :classroomId="selected" @edited="edited" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Send Message -->
    <b-modal id="send-message" ref="send-message-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">결과 문자 전송</strong></h3>
      </template>
      <SendMessage :classroomId="selected" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- HIDDEN -->
    <!-- Classroom QR -->
    <b-modal
      id="classroom-qr-modal"
      ref="classroom-qr-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">강의실 QR코드 보기</strong></h3>
      </template>
      <ShowQR :data="`${originUrl}/class?id=${selected}`" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Add Exam -->
    <b-modal id="add-exam" ref="add-exam-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">시험 추가하기</strong></h3>
      </template>
      <AddExam @added="examAdded" :classroomId="classroomId" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Edit Students -->
    <b-modal id="edit-students" ref="edit-students-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">학생 추가/제거하기</strong></h3>
      </template>
      <EditStudents
        v-if="classroomId != null"
        :classroomId="classroomId"
        @edited="studentsEdited"
        :studentIds="
          classrooms.filter((e) => e.classroomId == classroomId)[0].studentIds
        "
      />
      <div v-else>
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <b-card class="text-center px-3" no-body>
      <b-container class="my-4" fluid>
        <div v-if="classrooms == null">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else-if="classrooms.length == 0">
          <b-row align-v="center">
            <b-col>
              <img
                src="@/assets/icons/connect.svg"
                class="mb-2"
                width="150rem"
                height="150rem"
              />
              <h3 class="my-2">
                <strong>
                  시험지 정보 분석 및 리포트 제작, 손쉬운 OMR 스캔과 함께
                  하세요.
                </strong>
              </h3>
              <b-button
                variant="primary"
                size="lg"
                class="my-3"
                v-b-modal.create-classroom
                >강의실 개설</b-button
              >
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row align-v="end" class="mb-4">
            <b-col class="text-left" md="4">
              <h3 class="my-1">
                Classrooms
                <b-button
                  variant="white"
                  id="assistant-classroom"
                  @click="openTeamScan(userId)"
                  ><b-icon-printer
                /></b-button>
              </h3>

              <b-tooltip :target="`assistant-classroom`" triggers="hover" top>
                나의 스캐너 링크
              </b-tooltip>
            </b-col>
            <b-col class="text-right" md="3" offset-md="2">
              <b-button
                variant="success"
                class="my-1 mx-1"
                target="_blank"
                block
                href="https://drive.google.com/file/d/1677RPmHxmQ0ooS1wGWXVfxSnHgdrRT-C/view?usp=sharing"
                ><b-icon icon="file-earmark-check"></b-icon> OMR 용지</b-button
              >
            </b-col>
            <b-col class="text-left" md="3">
              <b-button
                variant="success"
                class="my-1 mx-1"
                block
                v-b-modal.create-classroom
                ><b-icon icon="people-fill"></b-icon> 강의실 개설</b-button
              >
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col v-if="classrooms != null">
              <b-table
                stacked="sm"
                head-variant="dark"
                :fields="fields"
                :items="classrooms"
              >
                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="overview(row.item.classroomId)"
                    :id="`overview-classroom-${row.item.classroomId}`"
                    variant="white"
                  >
                    <b-icon-bar-chart-line />
                  </b-button>
                  <b-tooltip
                    :target="`overview-classroom-${row.item.classroomId}`"
                    triggers="hover"
                    top
                  >
                    Overview
                  </b-tooltip>

                  <b-button
                    size="sm"
                    @click="editClassroom(row.item.classroomId)"
                    :id="`edit-classroom-${row.item.classroomId}`"
                    variant="white"
                  >
                    <b-icon-gear />
                  </b-button>
                  <b-tooltip
                    :target="`edit-classroom-${row.item.classroomId}`"
                    triggers="hover"
                    top
                  >
                    강의실 설정
                  </b-tooltip>

                  <b-button
                    size="sm"
                    variant="white"
                    class="mx-1"
                    :id="`send-message-${row.item.classroomId}`"
                    @click="sendMessage(row.item.classroomId)"
                  >
                    <b-icon-chat-text />
                  </b-button>
                  <b-tooltip
                    :target="`send-message-${row.item.classroomId}`"
                    triggers="hover"
                    top
                  >
                    문자 전송
                  </b-tooltip>

                  <b-button
                    size="sm"
                    @click="row.toggleDetails"
                    :id="`show-classroom-${row.item.classroomId}`"
                    variant="white"
                  >
                    <b-icon-three-dots />
                  </b-button>
                  <b-tooltip
                    :target="`show-classroom-${row.item.classroomId}`"
                    triggers="hover"
                    top
                  >
                    강의실 자세히 보기
                  </b-tooltip>
                </template>

                <template #cell(name)="row">
                  {{ row.item.name }}
                </template>

                <!-- <template #cell(classroomCreated)="row">
                  {{
                    new Date(
                      row.item.classroomCreated * 1000
                    ).toLocaleDateString("en-US")
                  }}
                </template> -->

                <template #cell(public)="row">
                  {{ row.item.public ? "공개" : "비공개" }}

                  <span v-if="row.item.public">
                    <b-button
                      class="ml-1"
                      size="sm"
                      variant="white"
                      @click="openClassroom(row.item.classroomId)"
                    >
                      <b-icon-share />
                    </b-button>

                    <b-button
                      size="sm"
                      @click="showClassroomQR(row.item.classroomId)"
                      :id="`show-classroom-qr-${row.item.classroomId}`"
                      variant="white"
                    >
                      <b-icon-upc-scan />
                    </b-button>
                    <b-tooltip
                      :target="`show-classroom-qr-${row.item.classroomId}`"
                      triggers="hover"
                      top
                    >
                      강의실 QR 코드 보기
                    </b-tooltip>
                  </span>
                </template>

                <template #row-details="row">
                  <div class="my-3">
                    <div>
                      <b-row>
                        <b-col>
                          <Exams
                            :classroomId="row.item.classroomId"
                            :examIds="row.item.examIds"
                            @deleted="updateClassrooms"
                          />
                        </b-col>
                      </b-row>
                      <div v-if="row.item.studentIds.length > 0">
                        <hr />

                        <b-row>
                          <b-col>
                            <Students
                              :classroomId="row.item.classroomId"
                              @removed="studentRemoved"
                            />
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- <div v-else>
                      <NotFound obj="수강생" />
                    </div> -->

                    <b-row class="text-center">
                      <b-col>
                        <span class="text-white">
                          <small> {{ row.item.classroomId }}</small></span
                        >
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col xl="4">
                        <b-button
                          @click="switchPublic(row.item.classroomId)"
                          variant="success"
                          class="mx-2 my-1"
                          block
                        >
                          <b-icon
                            :icon="
                              row.item.public ? `unlock-fill` : `lock-fill`
                            "
                          ></b-icon>
                          {{ row.item.public ? "비공개 전환" : "공개 전환" }}
                        </b-button>
                      </b-col>

                      <b-col xl="4">
                        <b-button
                          @click="editStudents(row.item.classroomId)"
                          variant="success"
                          class="mx-2 my-1"
                          block
                        >
                          <b-icon icon="person"></b-icon> 학생 추가/제거
                        </b-button>
                      </b-col>

                      <b-col xl="4">
                        <b-button
                          @click="addExam(row.item.classroomId)"
                          variant="success"
                          class="mx-2 my-1"
                          block
                        >
                          <b-icon icon="card-heading"></b-icon> 시험 추가
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Overview from "@/components/Overview/Classroom.vue";
import ShowQR from "@/components/Home/ShowQR.vue";

import CreateClassroom from "@/components/Classroom/CreateClassroom.vue";
import EditClassroom from "@/components/Classroom/EditClassroom.vue";
import SendMessage from "@/components/Classroom/SendMessage.vue";

import EditStudents from "@/components/Classroom/EditStudents.vue";
import AddExam from "@/components/Classroom/AddExam.vue";

import Students from "@/components/Classroom/Students.vue";
import Exams from "@/components/Exam/Exams.vue";
// import NotFound from "@/components/Exam/NotFound.vue";

export default {
  components: {
    Overview,
    ShowQR,
    CreateClassroom,
    EditClassroom,
    SendMessage,
    AddExam,
    EditStudents,
    Students,
    Exams,
  },
  props: {
    userId: String,
    classroomIds: Array,
  },
  data() {
    return {
      originUrl: window.location.origin,
      fields: [
        {
          key: "code",
          label: "강의실 코드",
          sortable: true,
        },
        {
          key: "name",
          label: "강의실 이름",
          sortable: true,
        },

        // {
        //   key: "classroomCreated",
        //   label: "생성 시간",
        //   sortable: true,
        // },
        {
          key: "public",
          label: "공개 여부",
          sortable: true,
        },
        {
          key: "actions",
          label: "액션",
        },
      ],
      selected: null,
      classrooms: null,
      classroomId: null,
    };
  },
  methods: {
    // Show Modals
    showClassroomQR: function (cId) {
      this.selected = cId;
      this.$refs[`classroom-qr-modal`].show();
    },
    overview: function (cId) {
      this.selected = cId;
      this.$refs[`classroom-overview-modal`].show();
    },
    editClassroom: function (cId) {
      this.selected = cId;
      this.$refs[`edit-classroom-modal`].show();
    },
    sendMessage: function (cId) {
      this.selected = cId;
      this.$refs["send-message-modal"].show();
    },
    addExam: function (cId) {
      console.log(cId);
      this.classroomId = cId;
      this.$refs["add-exam-modal"].show();
    },
    editStudents: function (cId) {
      console.log(cId);
      this.classroomId = cId;
      this.$refs["edit-students-modal"].show();
    },

    examAdded: function () {
      this.$refs["add-exam-modal"].hide();
      this.getClassrooms();
    },
    added: function () {
      this.$refs["create-classroom-modal"].hide();
      this.getClassrooms();
    },
    edited: function () {
      this.$refs["edit-classroom-modal"].hide();
      this.getClassrooms();
    },

    studentRemoved: function (data) {
      console.log(data);
      let cIdx = this.classrooms.findIndex(
        (e) => e.classroomId == data.classroomId
      );
      console.log(cIdx);

      let classroom = this.classrooms[cIdx];
      console.log(classroom);
      classroom.studentIds = this.classrooms[cIdx].studentIds.filter(
        (e) => e != data.studentId
      );

      console.log(classroom);

      this.$set(this.classrooms, cIdx, classroom);
    },
    openTeamScan: function () {
      this.copyUrl(`/scan?id=${this.userId}`);
      window.open(`/scan?id=${this.userId}`, "_blank");
    },
    openClassroom: function (id) {
      this.copyUrl(`/class?id=${id}`);
      window.open(`/class?id=${id}`, "_blank");
    },

    studentsEdited: function () {
      this.$refs["edit-students-modal"].hide();
      this.getClassrooms();
    },

    switchPublic: function (cId) {
      this.$bvModal
        .msgBoxConfirm(
          this.classrooms.find((e) => e.classroomId == cId).public
            ? "해당 강의실을 비공개로 전환합니다"
            : "해당 강의실을 공개로 전환합니다",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "진행하기",
            cancelTitle: "취소",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((res) => {
          if (res) {
            const url = this.$url;

            const token = localStorage.getItem("token");

            const classroom = {
              classroomId: cId,
            };

            fetch(url + "/api/class/public", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: token,
              },
              body: JSON.stringify(classroom),
            })
              .then((res) => res.json())
              .then((json) => {
                if (!json.error) {
                  console.log(json);
                  // this.classrooms = json.classrooms.sort(
                  //   (a, b) => b.classroomCreated - a.classroomCreated
                  // );
                  this.getClassrooms();
                } else {
                  throw new Error(json.error);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }
        });
    },

    deleteClass: function (cId) {
      this.$bvModal
        .msgBoxConfirm(
          "해당 데이터 다운 받아두기를 권고합니다, 강의실과 관련된 모든 데이터들 (시험기록 등)이 삭제됩니다.",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "삭제하기",
            cancelTitle: "취소",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((res) => {
          if (res) {
            const url = this.$url;

            const token = localStorage.getItem("token");

            const classroom = {
              classroomId: cId,
            };

            console.log(classroom);

            fetch(url + "/api/class/delete", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: token,
              },
              body: JSON.stringify(classroom),
            })
              .then((res) => res.json())
              .then((json) => {
                if (!json.error) {
                  console.log(json);
                  this.classrooms = json.classrooms.sort(
                    (a, b) => b.classroomCreated - a.classroomCreated
                  );
                } else {
                  throw new Error(json.error);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }
        });
    },
    updateClassrooms: function () {
      this.getClassrooms();
    },

    // API
    getClassrooms: function () {
      const url = this.$url;

      const token = localStorage.getItem("token");

      fetch(url + "/api/class/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.classrooms = json.classrooms.sort(
              (a, b) => b.classroomCreated - a.classroomCreated
            );
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    // Utils
    copyUrl: function (path) {
      let url = window.location.href;
      this.fallbackCopyTextToClipboard(
        `${url.slice(0, url.length - 5)}${path}`
      );
      alert("클립보드에 URL 주소가 복사되었습니다.");
    },
    fallbackCopyTextToClipboard: function (text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  mounted() {
    this.getClassrooms();
  },
};
</script>