<template>
  <div>
    <b-overlay
      :show="overlay"
      spinner-variant="dark"
      style="min-height: 90vh"
      spinner-type="grow"
    >
      <div v-if="scanUser != null">
        <b-container fluid class="my-3 text-center">
          <b-row align-h="center" style="min-height: 80vh">
            <b-col lg="4" class="text-center my-auto">
              <div class="text-left">
                <h2>
                  <strong> {{ scanUser.name }} 선생님의 스캐너 </strong>
                </h2>
                <Scan :userId="userId" />
                {{ userId }}
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-overlay>
  </div>
</template>


<script>
import Scan from "@/components/Home/Scan.vue";
export default {
  components: {
    Scan,
  },
  data() {
    return {
      overlay: true,
      user: null,
      scanUser: null,
    };
  },
  computed: {
    userId() {
      return this.$route.query.id;
    },
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (!this.email) {
      alert("먼저 로그인을 해주시기 바랍니다.");
      this.$router.push({ path: "/" });
    } else {
      this.overlay = true;

      this.checkAuth();
      this.getScanUser();
    }
  },
  methods: {
    checkAuth: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/team/verify`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          userId: this.userId,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
          this.$router.push({ path: "/" });
        });
    },
    getScanUser: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/user/details`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({ userId: this.userId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.scanUser = json.user;
            this.overlay = false;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
          this.$router.push({ path: "/" });
        });
    },
  },
};
</script>