import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import Home from '../views/Home.vue'

import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentFail from '../views/PaymentFail.vue'

import Report from '../views/Report.vue'
import Class from '../views/Class.vue'
import UserScan from '../views/UserScan.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Demo from '../views/ScannerDemo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payment/fail',
    name: 'PaymentFail',
    component: PaymentFail
  },
  {
    path: '/result',
    name: 'Report',
    component: Report
  },
  {
    path: '/scan',
    name: 'Scan',
    component: UserScan
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo
  },
  {
    path: '/class',
    name: 'Class',
    component: Class
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
