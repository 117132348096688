import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index.js";

Vue.use(Vuex);

const url = ["8080", "3000"].includes(window.location.port)
  ? `${window.location.protocol}//${window.location.hostname}:3030`
  : `${window.location.protocol}//api.erudiolabs.com`;

export default new Vuex.Store({
  state: {
    email: false,
    name: false,
    lastActive: false,
    tokenChecked: false,
    teamName: false,
  },
  getters: {
    email(state) {
      return state.email;
    },
    name(state) {
      return state.name;
    },
    lastActive(state) {
      return state.lastActive;
    },
    teamName(state) {
      return state.teamName;
    },
    tokenChecked(state) {
      return state.tokenChecked;
    },
  },
  mutations: {
    setEmail(state, payload) {
      state.email = payload;
    },
    setTeamName(state, payload) {
      state.teamName = payload;
    },
    setName(state, payload) {
      state.name = payload;
    },
    setLastActive(state, payload) {
      state.lastActive = payload;
    },
    setTokenChecked(state, payload) {
      state.tokenChecked = payload;
    },
  },
  actions: {
    setTokenChecked(context, payload) {
      context.commit("setTokenChecked", payload);
    },
    setTeamNameAction(context, payload) {
      context.commit("setTeamName", payload);
    },
    setNameAction(context, payload) {
      context.commit("setName", payload);
    },
    setLastActiveAction(context, payload) {
      context.commit("setLastActive", payload);
    },
    setLoggedInAction(context, payload) {
      context.commit("setEmail", payload.email);
      context.commit("setName", payload.name);
    },
    logoutAction(context) {
      localStorage.removeItem("token");
      context.commit("setEmail", false);
      context.commit("setName", false);
      context.commit("setTeamName", false);

      router.push({ path: "/" });
    },
    verifyTokenAction(context, payload) {
      return new Promise((resolve, reject) => {
        fetch(url + "/v1/scanner/auth", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: payload.token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              context.commit("setEmail", json.email);
              context.commit("setName", json.name);
              context.commit("setLastActive", json.lastActive);

              resolve(json.email);
            } else {
              console.log(json.error);
              throw new Error(json.error);
            }
          })
          .catch((err) => {
            console.log("ERROR", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
  },
  modules: {},
});
