<script>
import { Radar } from "vue-chartjs";

export default {
  extends: Radar,
  props: {
    answers: Array,
    student: Array,
    results: Array,
  },
  mounted() {
    console.log(this.results);
    let rawLabels = this.answers.map((e) => e.questionCategory);
    let labels = [...new Set(rawLabels)];
    let labelCounts = labels.map((e) => rawLabels.filter((x) => x == e).length);

    let studentAnswers = this.answers.reduce((a, e) => {
      if (
        this.arraysEqual(e.questionAnswer, this.student[e.questionNumber - 1])
      ) {
        if (!a[e.questionCategory]) {
          a = Object.assign(a, { [e.questionCategory]: 1 });
        } else {
          a[e.questionCategory] += 1;
        }
      }
      return a;
    }, {});
    console.log(studentAnswers);

    let averageStudentAnswers = this.results
      .map((result) =>
        this.answers.reduce((a, e) => {
          if (
            this.arraysEqual(
              e.questionAnswer,
              result.answersChosen[e.questionNumber - 1]
            )
          ) {
            if (!a[e.questionCategory]) {
              a = Object.assign(a, { [e.questionCategory]: 1 });
            } else {
              a[e.questionCategory] += 1;
            }
          }
          return a;
        }, {})
      )
      .reduce((a, e, i, arr) => {
        Object.keys(e).forEach((category) => {
          if (!a[category]) {
            a = Object.assign(a, { [category]: e[category] / arr.length });
          } else {
            a[category] += e[category] / arr.length;
          }
        });

        return a;
      }, {});
    console.log(averageStudentAnswers);

    let gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    gradient.addColorStop(0, "rgba(255, 0,0, 0.8)");
    gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    gradient.addColorStop(1, "rgba(255, 0, 0, 0.15)");

    console.log(labels);
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels:
          labels.length == 1 && labels[0] == undefined ? ["기본"] : labels,
        datasets: [
          {
            label: "평균",

            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgb(54, 162, 235)",
            pointBackgroundColor: "rgb(54, 162, 235)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(54, 162, 235)",

            data: labels.map(
              (e, i) =>
                Math.round(
                  (averageStudentAnswers[e] / labelCounts[i]) * 100 * 100
                ) / 100
            ),
          },
          {
            label: "학생",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgb(255, 99, 132)",
            pointBackgroundColor: "rgb(255, 99, 132)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255, 99, 132)",

            data: labels.map(
              (e, i) =>
                Math.round((studentAnswers[e] / labelCounts[i]) * 100 * 100) /
                100
            ),
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: true,
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      }
    );
  },
  methods: {
    arraysEqual: function (a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
  },
};
</script>
