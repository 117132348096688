<template>
  <div>
    <div v-if="users != null">
      <b-list-group>
        <b-list-group-item
          v-for="user in users"
          v-bind:key="`user-info-${user.userId}`"
          :class="user.admin ? '' : ''"
        >
          <b-row align-h="between" align-v="center">
            <b-col lg="10">
              <span class="mr-2">
                <strong v-if="user.admin">* 관리자</strong>
                <strong v-else>팀원</strong>
              </span>
              <strong>{{ user.name }}</strong> {{ user.email }}</b-col
            >

            <b-col lg="2" class="text-right">
              <b-button
                v-if="!user.admin"
                @click="deleteUser(user.userId)"
                class="ml-auto my-1"
                size="sm"
                variant="danger"
              >
                <b-icon icon="x-circle-fill"></b-icon> 삭제하기
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" type="grow" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userIds: Array,
  },
  data() {
    return {
      users: null,
    };
  },
  watch: {
    userIds() {
      this.getUserInfo();
    },
  },

  methods: {
    deleteUser: function (userId) {
      this.$bvModal
        .msgBoxConfirm("팀원과 관련된 모든 데이터들이 삭제됩니다.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "삭제하기",
          cancelTitle: "취소",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((res) => {
          if (res) {
            const user = {
              userId: userId,
            };

            const token = localStorage.getItem("token");

            fetch(`${this.$apiUrl}/scanner/user/delete`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: token,
              },
              body: JSON.stringify(user),
            })
              .then((res) => res.json())
              .then((json) => {
                if (!json.error) {
                  alert("성공적으로 제거 됬습니다.");
                  this.$emit("user-deleted");
                } else {
                  console.log(json);
                  throw new Error(json.error);
                }
              })
              .catch((error) => {
                console.log(error);
                alert(error);
              });
          }
        });
    },
    getUserInfo: function () {
      let token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/team/users`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.users = json.users;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>