<template>
  <div class="text-center">
    <b-overlay
      :show="overlay"
      rounded="sm"
      style="margin-bottom: 10px"
      variant="white"
    >
      <b-form @submit="uploadFile" @submit.prevent>
        <!-- {{classroomId}} -->

        <!-- <b-card class="mb-2">
          <strong
            ><b-icon icon="exclamation-triangle-fill"></b-icon> 엑셀 파일 이름
            'as_20210208' 일치 확인 부탁드립니다
            <b-icon icon="exclamation-triangle-fill"></b-icon
          ></strong>
        </b-card> -->

        <b-card>
          <strong>새로운 엑셀 파일 다운받아주시기 바랍니다! </strong><br />
          <a href="/download/as"
            >이 링크를 눌러 엑셀 파일을 다운받아 <br />
            (다운 후 파일이름 수정 가능합니다)<br
          /></a>
          시험정보를 입력후 업로드 진행해주시기 바랍니다.
        </b-card>
        <b-form-group>
          <!-- Styled -->
          <b-form-file
            class="mt-2 text-left"
            v-model="file"
            :state="Boolean(file)"
            placeholder="정보가 추가된 엑셀 파일을 첨부해 주십시오..."
            drop-placeholder="Drop file here..."
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></b-form-file>
        </b-form-group>

        <div class="form-group text-left">
          <b-form-input
            type="text"
            size="lg"
            class="form-control"
            placeholder="시험 타이틀 *"
            name="exam-name"
            v-model="examName"
            :disabled="overlay"
            required
          />
        </div>

        <div class="form-group text-left">
          <b-form-input
            type="number"
            step="any"
            size="lg"
            class="form-control"
            placeholder="확인용 총 점수 *"
            name="exam-totalscore"
            v-model="examTotalScore"
            :disabled="overlay"
            required
          />
        </div>

        <div class="form-group text-left">
          <b-form-input
            type="number"
            min="0"
            max="9999"
            size="lg"
            class="form-control"
            placeholder="시험 코드 (0000 부터 9999 까지 선택) *"
            name="code"
            v-model="code"
            :disabled="overlay"
            required
          />
          <small> * 네자리 숫자가 아닌 경우 앞에 0이 생략됩니다. </small>
        </div>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="file == null || code == '' || overlay"
        >
          <strong class="sub-title">시험 추가하기</strong>
        </b-button>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import xlsx from "xlsx";
export default {
  props: {
    classroomId: String,
  },
  data() {
    return {
      overlay: false,
      processing: 0,
      examName: "",
      examTotalScore: "",
      examType: 0,
      code: "",
      file: null,
    };
  },
  methods: {
    uploadFile() {
      this.overlay = true;
      setTimeout(() => {
        this.readXlsx(this.file);
      }, 400);
    },
    getEvaluation: function (e, headers) {
      let evaluation = [...Array(5).keys()].reduce((result, i) => {
        if (e[headers[5 + i]]) {
          result[i + 1] = e[headers[5 + i]];
        }
        return result;
      }, {});

      if (Object.keys(evaluation).length > 0) {
        this.examType = 1;
        return evaluation;
      } else {
        return undefined;
      }
    },
    processExam: function (xlData, headers) {
      let calculatedTotalScore = xlData.reduce(
        (score, e) => Math.round((score += e[headers[2]]) * 1000) / 1000,
        0
      );

      if (this.examTotalScore == calculatedTotalScore) {
        return {
          name: this.examName,
          questionNum: xlData.length,
          answers: xlData.map((e) => {
            let evaluation = this.getEvaluation(e, headers);

            return {
              questionNumber: e[headers[0]],
              questionAnswer: e[headers[1]]
                .toString()
                .split(",")
                .map(Number)
                .sort()
                .filter((answer) => [1, 2, 3, 4, 5].includes(answer)),
              questionPoints: e[headers[2]],
              questionCategory: e[headers[3]] ? e[headers[3]] : "기본",
              desc: e[headers[4]] ? e[headers[4]] : "N/A",
              evaluation: evaluation,
            };
          }),
          totalScore: this.examTotalScore,
          type: this.examType,
        };
      } else {
        alert(
          `총합 시험점수 (${calculatedTotalScore})가 입력된 총점(${this.examTotalScore})과 같지 않습니다, 다시 확인해 주시기 바랍니다.`
        );
        return undefined;
      }
    },
    getXlsxHeader: function (ws) {
      const header = [];
      const columnCount = xlsx.utils.decode_range(ws["!ref"]).e.c + 1;
      for (let i = 0; i < columnCount; ++i) {
        header[i] = ws[`${xlsx.utils.encode_col(i)}1`].v;
      }
      return header;
    },
    readXlsx: function (file) {
      let reader = new FileReader();

      reader.onload = (e) => {
        let data = e.target.result;
        let workbook = xlsx.read(data, {
          type: "binary",
        });

        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          let xlData = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName]);
          let headers = this.getXlsxHeader(workbook.Sheets[sheetName]);

          console.log(headers);
          console.log(xlData);

          let filteredData = xlData.filter((e) => Object.keys(e).length > 2);

          if (filteredData.length > 0) {
            let exam = this.processExam(filteredData, headers);
            if (exam) {
              console.log(exam);
              this.uploadExam(exam);
            }
          } else {
            alert(
              `필수 기입영역이 완성되지 않았습니다. 엑셀파일의 정답,배점 영역을 다시 한번 확인 해주시기 바랍니다.`
            );
          }
          this.file = null;
          this.code = null;
          this.overlay = false;
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
        alert(`${ex}`);
      };

      reader.readAsBinaryString(file);
    },

    uploadExam: function (exam) {
      this.overlay = true;
      const url = this.$url;

      let token = localStorage.getItem("token");
      if (this.file != null) {
        fetch(
          url +
            `/api/class/import/exam/${this.classroomId}/${parseInt(
              this.code
            ).toString()}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: token,
              // "Content-Type": "multipart/form-data",
            },
            body: JSON.stringify({ exam }),
          }
        )
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              this.file = null;
              this.overlay = false;
              this.$emit("added");
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            this.overlay = false;
          });
      }
    },
  },
};
</script>