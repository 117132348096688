var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":[function($event){return _vm.processXlsx(_vm.file)},function($event){$event.preventDefault();}]}},[_c('b-card',[_c('a',{attrs:{"href":"/download/quiz"}},[_vm._v("이 링크를 눌러 엑셀 템플렛 파일을 다운받아 "),_c('br')]),_vm._v(" 단어 입력후 업로드 진행해주시기 바랍니다. ")]),_c('b-form-group',[_c('b-form-file',{staticClass:"mt-2 text-left",attrs:{"state":Boolean(_vm.file),"placeholder":"정보가 추가된 엑셀 파일을 첨부해 주십시오...","drop-placeholder":"Drop file here...","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('div',{staticClass:"form-group text-left"},[_c('label',{attrs:{"for":"download-method"}},[_vm._v("답안지 연동 방식")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.classroomSelected))]),_c('b-form-select',{attrs:{"id":"download-method","options":_vm.classrooms.map(function (e) {
            return { value: e.classroomId, text: ("'" + (e.name) + "' 에 추가") };
          })},model:{value:(_vm.classroomSelected),callback:function ($$v) {_vm.classroomSelected=$$v},expression:"classroomSelected"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("답안지 엑셀파일 다운로드")])],1)],1),_c('div',{staticClass:"form-group text-left"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","min":"0","size":"lg","placeholder":"시험 문제 수 *","required":""},model:{value:(_vm.questionNumbers),callback:function ($$v) {_vm.questionNumbers=$$v},expression:"questionNumbers"}})],1),_c('div',{staticClass:"form-group text-left"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","min":"0","size":"lg","placeholder":"시험 타이틀 *","required":""},model:{value:(_vm.examName),callback:function ($$v) {_vm.examName=$$v},expression:"examName"}})],1),(_vm.classroomSelected != null)?_c('div',{staticClass:"form-group text-left"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","min":"0","max":"9999","size":"lg","placeholder":"시험 코드 (0000 부터 9999 까지 선택) *","name":"code","disabled":_vm.overlay,"required":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',[_vm._v(" * 네자리 숫자가 아닌 경우 앞에 0이 생략됩니다. ")])],1):_vm._e(),_c('b-button',{attrs:{"block":"","type":"submit","variant":"dark","size":"lg","disabled":_vm.file == null ||
        _vm.questionNumbers == '' ||
        _vm.examName == '' ||
        _vm.classroomSelected != null
          ? _vm.code == ''
          : false}},[_c('strong',{staticClass:"sub-title"},[_vm._v("단어 시험 만들기")])]),_c('b-row',{staticClass:"text-right",attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.flipped),callback:function ($$v) {_vm.flipped=$$v},expression:"flipped"}},[_vm._v("단어-뜻 문제 SWAP")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }