<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="submit">
        <div class="form-group">
          <b-form-input
            type="number"
            size="lg"
            class="form-control"
            placeholder="시험 코드 *"
            name="code"
            v-model="code"
            :disabled="overlay"
            :state="code.length == 4 ? true : false"
            autocomplete="off"
            required
          />
        </div>
        <hr />

        <b-form-group
          :label="`${answerIdx + 1}번 문항`"
          v-for="(answer, answerIdx) in answers"
          v-bind:key="answerIdx"
        >
          <b-form-checkbox-group
            class="text-center"
            :id="`radio-group-${answerIdx}`"
            v-model="answers[answerIdx]"
            size="lg"
            :options="options"
            name="radio-options"
          ></b-form-checkbox-group>
        </b-form-group>

        <b-form-group
          :label="`${answerIdx + 22}번 문항`"
          v-for="(answer, answerIdx) in mathAnswers"
          v-bind:key="`math-${answerIdx}`"
        >
          <b-form-input
            type="number"
            size="lg"
            class="form-control"
            placeholder="단답형 정답 *"
            name="code"
            v-model="mathAnswers[answerIdx][0]"
            :disabled="overlay"
            autocomplete="off"
            min="0"
            max="999"
            required
          />
        </b-form-group>

        <b-button
          block
          type="submit"
          size="lg"
          variant="dark"
          class="mt-5"
          :disabled="overlay || code.length != 4"
          ><strong>답안 제출하기</strong></b-button
        >
        <small class="form-text text-muted text-right" style="font-size: 9px">
          제출함과 동시에, Erudio Labs의
          <b-link :to="{ path: '/' }" class="text-dark">서비스이용약관</b-link
          >과
          <b-link :to="{ path: '/' }" class="text-dark">개인정보처리방침</b-link
          >에 동의 합니다
        </small>
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    ip: String,
    math: Boolean,
    data: Object,
    student: Object,
  },
  data() {
    return {
      overlay: false,
      code: "",
      answers: [],
      mathAnswers: [],
      options: [1, 2, 3, 4, 5],
    };
  },
  mounted() {
    if (this.math) {
      this.createAnswers(21, true);
    } else {
      this.createAnswers(100);
    }
  },
  watch: {
    math(val) {
      if (val) {
        this.createAnswers(21, true);
      } else {
        this.createAnswers(100);
      }
    },
  },
  methods: {
    submit: function () {
      for (const x of Array(this.answers.length).keys()) {
        this.answers[x] = this.answers[x].sort((a, b) => a - b);
      }

      for (const x of Array(this.mathAnswers.length).keys()) {
        this.mathAnswers[x] = [parseInt(this.mathAnswers[x])];
      }

      let answers = {
        ip: this.ip,
        classroomId: this.data.classroom.classroomId,
        studentId: this.student.studentId,
        classroomCode: this.data.classroom.code,
        examCode: this.code,
        studentCode: this.student.code,
        studentAnswers: [...this.answers, ...this.mathAnswers],
      };

      fetch(
        `${this.$apiUrl}/scanner/scan/${this.data.classroom.classroomId}/manual`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(answers),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            alert("제출 되었습니다.");
            this.$bvModal
              .msgBoxConfirm("해당 시험지의 결과를 바로 확인 하시겠습니까?", {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: "확인하기",
                cancelTitle: "취소",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then((res) => {
                if (res) {
                  window.open(`/result?id=${json.resultId}`);
                }
              });
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
      console.log(answers);
    },
    createAnswers: function (questionNum, math = false) {
      this.answers = [];
      this.mathAnswers = [];

      for (const x of Array(questionNum).keys()) {
        console.log(x);
        this.answers.push([]);
      }

      if (math) {
        for (const x of Array(9).keys()) {
          console.log(x);
          this.mathAnswers.push([""]);
        }
      }
    },
  },
};
</script>