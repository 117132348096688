<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

    let gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    gradient.addColorStop(
      0,
      `rgba(${this.hexToRgb(this.chartData.datasets[0].backgroundColor)}, 0.95)`
    );
    gradient.addColorStop(
      0.5,
      `rgba(${this.hexToRgb(this.chartData.datasets[0].backgroundColor)}, 0.25)`
    );
    gradient.addColorStop(
      1,
      `rgba(${this.hexToRgb(this.chartData.datasets[0].backgroundColor)}, 0.15)`
    );

    this.chartData.datasets[0].backgroundColor = gradient;
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    hexToRgb: function (hex) {
      var bigint = parseInt(hex.replace("#", ""), 16);
      var r = (bigint >> 16) & 255;
      var g = (bigint >> 8) & 255;
      var b = bigint & 255;

      return r + "," + g + "," + b;
    },
  },
};
</script>