<template>
  <div>
    <b-form-file
      class="mt-2 text-left"
      v-model="image"
      :state="Boolean(image)"
      placeholder="정보가 추가된 엑셀 파일을 첨부해 주십시오..."
      drop-placeholder="Drop file here..."
      accept="image/*"
    ></b-form-file>

    <button @click="processImage">Process</button>
  </div>
</template>

<script>
const { createWorker } = require("tesseract.js");

// import * as tf from "@tensorflow/tfjs";

const worker = createWorker({
  logger: (m) => console.log(m), // Add logger here
});

export default {
  data() {
    return {
      image: null,
    };
  },
  methods: {
    processImage() {
      if (this.image) {
        (async () => {
          await worker.load();
          await worker.loadLanguage("eng");
          await worker.initialize("eng");
          const {
            data: { text },
          } = await worker.recognize(this.image);
          console.log(text);
          await worker.terminate();
        })();
      }
    },
  },
};
</script>