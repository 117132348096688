<template>
  <div id="landing">
    <!-- Register -->
    <b-modal
      id="partner-register"
      ref="partner-register-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">OMR 스캐너 가입</strong></h3>
        <!-- 아래 관련 정보들을 입력하여
        가입 해주시기 바랍니다. -->

        <strong>* 선생님용 계정</strong> 생성을 위해서는 <br />
            <strong>대표(원장)님께 문의</strong> 해주시기 바랍니다.
      </template>
      <Register />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Login -->
    <b-modal id="partner-login" ref="partner-login-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3><strong class="title">OMR 스캐너 로그인</strong></h3>
        아래 정보들을 입력하여 로그인 해주시기 바랍니다.
      </template>
      <Login />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <!-- Enquiry -->
    <b-modal
      id="partner-enquiry"
      ref="partner-enquiry-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">OMR 스캐너와 함깨</strong></h3>
        아래 정보들을 입력하여 문의 해주시기 바랍니다.
      </template>
      <Enquiry @submitted="submitted" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <Top :email="email" />

    <Section
      v-for="(section, sectionIdx) in sections"
      v-bind:key="sectionIdx"
      :content="section"
      :idx="sectionIdx"
    />
    <Pricing class="pt-5" @open-modal="openModal" />
    <WalletPrices/>
    <Faq />
    <Waitlist class="mt-5" />

    <Footer />
  </div>
</template>


<script>
import Top from "@/components/Landing/Top.vue";

import Section from "@/components/Landing/Section.vue";
import Waitlist from "@/components/Landing/Waitlist.vue";
import Footer from "@/components/Footer.vue";

import Pricing from "@/components/Landing/Pricing.vue";
import WalletPrices from "@/components/Landing/CoinOptions.vue";

import Faq from "@/components/Landing/Faq.vue";

import Register from "@/components/Register.vue";
import Login from "@/components/Login.vue";
import Enquiry from "@/components/Landing/Enquiry.vue";

export default {
  components: {
    Top,
    Register,
    Login,
    Enquiry,
    Section,
    WalletPrices,
    Pricing,
    Faq,
    Waitlist,
    Footer,
  },
  computed: {
    email() {
      return this.$store.getters.email;
    },
    name() {
      return this.$store.getters.name;
    },
  },
  methods: {
    submitted: function () {
      this.$refs["partner-enquiry-modal"].hide();
    },
    openModal: function (name) {
      this.$refs[name].show();
    },
  },
  mounted(){
    window.scrollTo(0, 0);

  },
  data() {
    return {
      sections: [
        {
          backgroundColor: "White",
          animation: "slideinleft 3s, fadein 1s",
          imageLeft: false,
          image: require("@/assets/scan-sm.jpg"),
          tags: ["OMR 스캔", "폰으로도", "모든기기"],
          title: "폰으로도 답안지 채점,<br /> 이제는 지원됩니다.",
          desc:
            "모든기기를 통한 OMR 스캔, 시험채점 및 관리의 패러다임 변화를 제공합니다.",

          buttons: [
            {
              title: "스캐너 데모 확인",
              path: "/demo",
            },
            {
              title: "OMR 용지",
              href: "https://drive.google.com/file/d/1677RPmHxmQ0ooS1wGWXVfxSnHgdrRT-C/view?usp=sharing"
            }
          ],
        },
        {
          backgroundColor: "Azure",
          animation: "slideinright 3s, fadein 1s",
          imageLeft: true,
          image: require("@/assets/report-sm.jpg"),
          tags: ["채점 리포트 제공", "링크 공유", "친환경"],
          title: "시험 채점결과 리포트,<br /> 학생들에게 쉽게 공유하세요.",
          desc:
            "링크 공유를 통한 손쉬운 채점 결과 공유,<br/> 이제는 에루디오 랩스를 통해서 무료로 제공해드립니다.",
          buttons: [
            {
              title: "리포트 데모 확인",
              path: "/result?id=6823cf9d-2f0d-4ef8-8018-eea7c5e7e831",
            },
          ],
        },

        {
          backgroundColor: "White",
          animation: "slideinleft 3s, fadein 1s",
          imageLeft: false,
          image: require("@/assets/side-by-side-sm.jpg"),
          tags: ["결과 분석", "데이터 시각화"],
          title:
            "데이터 분석을 통해<br />한눈에 학생 동향 파악 가능합니다.",
          desc:
            "그냥 놓치고 넘어가는 수업이 아닌,<br/> 채점 결과와 같은 데이터를 활용한 효율적인 복습의 가능성을 열어드립니다.",
        },

        // {
        //   backgroundColor: "White",
        //   animation: "slideinleft 3s, fadein 1s",
        //   imageLeft: false,
        //   image: require("@/assets/side-by-side-sm.jpg"),
        //   tags: ["채점 리포트 제공", "데이터 저장"],
        //   title: "학생 혼자서도,<br />빠른 채점과 결과 제공합니다.",
        //   desc:
        //     "그냥 놓치고 넘어가는 수업이 아닌,<br/> 채점 결과와 같은 데이터를 활용한 효율적인 복습의 가능성을 열어드립니다.",
        // },

        {
          backgroundColor: "Azure",
          animation: "slideinright 3s, fadein 1s",
          imageLeft: true,
          image: require("@/assets/pen-sm.jpg"),
          tags: ["혁신", "합리적", "최저가"],
          title: "이 모든 기능들을,<br />합리적인 가격으로 경험해보세요.",
          desc:
            "기존 솔루션들은 학생 수와 상관없이 많은 비용을 지불하며,<br/>   물리적인 스캐너의 불편함과 진입 장벽이 높습니다.",
        },
      ],
    };
  },
};

// 편리성을 희생하지 않고 선생님들의 자료를 안전하게 보호하는,<br/> 편리한 OMR 스캔 방식을 채택하였습니다.
</script>

<style scoped>
.main-jumbotron {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 1)
    ),
    url("../assets/omr.jpg");
  background-size: cover;
}
</style>