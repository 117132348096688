var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.results == null)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-spinner',{staticClass:"m-5",attrs:{"label":"Loading..."}})],1)]):(_vm.results.length != 0)?_c('div',{staticClass:"mb-3"},[_c('b-row',{staticClass:"text-center"},[_c('b-col',{staticClass:"my-1",attrs:{"md":"4"}},[_c('b-card',[_c('b-card-text',[_c('strong',[_vm._v("시험제출 현황 ")])]),_c('h1',[_c('strong',[_vm._v(_vm._s(Math.round((_vm.results.length / _vm.students.length) * 10000) / 100)+"%")])]),_c('b-card-text',[_vm._v("총 "+_vm._s(_vm.students.length)+"명 중 "+_vm._s(_vm.results.length)+"명 완료")])],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"4"}},[_c('b-card',[_c('b-card-text',[_c('strong',[_vm._v("시험 평균")])]),_c('h1',[_c('strong',[_vm._v(_vm._s(Math.round(_vm.scoreAverage * 100) / 100)+"점")])]),_c('b-card-text',[_vm._v("총 "+_vm._s(_vm.exam.questionNum)+" 문항, "+_vm._s(_vm.exam.totalScore)+"점")])],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"4"}},[_c('b-card',[_c('b-card-text',[_c('strong',[_vm._v("최상위/최하위")])]),_c('h1',[_c('strong',[_vm._v(_vm._s(_vm.results[_vm.results.length - 1].name))])]),_c('b-card-text',[_vm._v(_vm._s(_vm.results[0].name))])],1)],1)],1),(
        _vm.students.filter(
          function (e) { return !_vm.results.map(function (e) { return e.studentId; }).includes(e.studentId); }
        ).length > 0
      )?_c('b-row',[_c('b-col',{staticClass:"my-1"},[_c('b-card',[_c('b-card-text',[_c('strong',[_vm._v("미제출자")])]),_c('b-card-text',{staticClass:"text-center"},[_c('div',_vm._l((_vm.students.filter(
                  function (e) { return !_vm.results.map(function (e) { return e.studentId; }).includes(e.studentId); }
                )),function(student,idx){return _c('span',{key:student.studentId},[_vm._v(_vm._s(student.name)+" "),(
                    idx !=
                    _vm.students.filter(
                      function (e) { return !_vm.results.map(function (e) { return e.studentId; }).includes(e.studentId); }
                    ).length -
                      1
                  )?_c('span',[_vm._v("· ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"my-2"},[_c('b-col',[(_vm.results != null && _vm.results.length > 0)?_c('div',[_c('BarChart',{attrs:{"chart-data":_vm.barchart.data,"options":_vm.barchart.options}})],1):_vm._e()])],1),(_vm.analysisExam && _vm.analysisExam.length > 0)?_c('div',[_c('hr'),_c('BarChart',{attrs:{"chart-data":_vm.analysisExamChart.data,"options":_vm.analysisExamChart.options}}),_c('hr'),_c('b-table',{attrs:{"hover":"","fields":_vm.analysisExamFields,"items":_vm.analysisExam},scopedSlots:_vm._u([{key:"cell(question)",fn:function(row){return [_c('strong',[_vm._v(_vm._s(row.item.question)+" 번 ")])]}},{key:"cell(correctAnswer)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.correctAnswer.join(", "))+" ")]}},{key:"cell(percentage)",fn:function(row){return [_c('strong',[_vm._v(_vm._s(Math.round(row.item.percentage * 1000) / 10)+" % ")])]}},{key:"cell(answers)",fn:function(row){return _vm._l((Object.entries(row.item.answers).sort(
              function (a, b) { return b[1] - a[1]; }
            )),function(e,i){return _c('span',{key:("answer-" + (row.index) + "-" + i)},[_vm._v(_vm._s(e)+" ")])})}}],null,false,3184147400)})],1):_vm._e()],1):_c('div',[_c('NotFound')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }