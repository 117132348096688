var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{staticStyle:{"margin-bottom":"10px"},attrs:{"show":_vm.overlay,"rounded":"sm","variant":"white"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}}),(_vm.selected.length !== 0)?_c('div',{staticClass:"mt-3"},[_c('h5',{staticClass:"px-4",attrs:{"id":"cancel-label"}},[_vm._v(" 전송 중 ... ("+_vm._s(_vm.sentCount)+"/"+_vm._s(_vm.selected.length)+") ")]),_c('b-progress',{attrs:{"value":(_vm.sentCount / _vm.selected.length) * 100,"striped":"","animated":"","variant":"dark"}})],1):_vm._e()],1)]},proxy:true}])},[(_vm.results == null)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-spinner',{staticClass:"m-5",attrs:{"label":"Loading..."}})],1)]):(_vm.results.length != 0)?_c('div',{staticClass:"mb-3"},[_c('b-row',{staticClass:"text-center"},[_c('b-col',{},[_c('b-card',[_c('b-card-text',[_c('strong',[_vm._v("시험제출 현황 : "+_vm._s(Math.round((_vm.results.length / _vm.students.length) * 10000) / 100)+"%")]),_c('br'),_c('small',[_vm._v("총 "+_vm._s(_vm.students.length)+"명 중 "+_vm._s(_vm.results.length)+"명 완료")])])],1)],1)],1),(
          _vm.students.filter(
            function (e) { return !_vm.results.map(function (e) { return e.studentId; }).includes(e.studentId); }
          ).length > 0
        )?_c('b-row',[_c('b-col',{staticClass:"my-1"},[_c('b-card',[_c('b-card-text',[_c('strong',[_vm._v("미제출자")])]),_c('b-card-text',{staticClass:"text-center"},[_c('div',_vm._l((_vm.students.filter(
                    function (e) { return !_vm.results.map(function (e) { return e.studentId; }).includes(e.studentId); }
                  )),function(student,idx){return _c('span',{key:student.studentId},[_vm._v(_vm._s(student.name)+" "),(
                      idx !=
                      _vm.students.filter(
                        function (e) { return !_vm.results
                            .map(function (e) { return e.studentId; })
                            .includes(e.studentId); }
                      ).length -
                        1
                    )?_c('span',[_vm._v("· ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{staticClass:"my-2",attrs:{"variant":"success","block":""},on:{"click":_vm.toggleSelectAll}},[_c('b-icon',{attrs:{"icon":"check"}}),_vm._v(" "+_vm._s(_vm.selected.length === _vm.filteredStudent.length ? "선택해제" : "전체선택")+" ")],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-input',{staticClass:"my-2",attrs:{"placeholder":"학생 검색","autocomplete":"off"},model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})],1)],1),_c('b-list-group',[_c('b-form-checkbox-group',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.filteredStudent.filter(
              function (e) { return e.name.includes(_vm.filterQuery) || e.number.includes(_vm.filterQuery); }
            )),function(student){return _c('b-list-group-item',{key:student.studentId},[_c('b-form-checkbox',{attrs:{"value":student.studentId}},[_vm._v(_vm._s(student.name)+" | 전송 번호: "+_vm._s(student.number))])],1)}),1)],1),_c('hr'),_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("문자 상단 문구")])]),_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"문자 상단문구를 입력해주세요...","rows":"5","max-rows":"6"},model:{value:(_vm.messageContent),callback:function ($$v) {_vm.messageContent=$$v},expression:"messageContent"}}),_c('b-button',{staticClass:"mt-2",attrs:{"block":"","disabled":_vm.selected.length === 0,"type":"submit","size":"lg","variant":"dark"},on:{"click":_vm.showSendMessageModal}},[_c('strong',[_vm._v(_vm._s(_vm.selected.length)+"명에게 결과 메세지 전송")])]),_c('b-modal',{ref:"modal-confirm",attrs:{"title":"전송 컨펌 필요!","centered":"","size":"md","hide-header":""},on:{"ok":_vm.confirmed},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
            var ok = ref.ok;
            var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" 취소 ")]),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v(" 전송하기 ")])]}}])},[_c('p',{staticClass:"mt-2"},[_c('b-form-textarea',{attrs:{"id":"textarea","value":(_vm.messageContent + "\n[학생이름] 결과링크: [학생 리포트 주소링크]\n* 해당 번호는 발신전용 번호입니다"),"placeholder":"문자 상단문구를 입력해주세요...","readonly":"","rows":"5","max-rows":"6"}})],1),_c('strong',{staticClass:"mt-3"},[_vm._v(" 위와 같이 "+_vm._s(_vm.selected.length)+"명에게 문자가 전송 됩니다. ('[]'는 전송시 링크로 바뀝니다.) ")])])],1):_c('div',[_c('NotFound')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }