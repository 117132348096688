<template>
  <b-container class="faq-container py-5 my-5">
    <b-row class="justify-content-md-center" cols="1">
      <b-col class="text-left">
        <h3 class="text-left">
          <strong class="sub-title">자주 묻는 질문</strong>
        </h3>
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mt-2" cols="1">
      <b-col col md="12" lg="11" class="mt-4 text-left">
        <div role="tablist">
          <b-list-group flush>
                
              <b-list-group-item v-for="(question, questionIdx) in questions" v-bind:key="questionIdx" v-b-toggle="question.id" button>
                <b-row class="justify-content-md-center mt-2">
                  <b-col cols="10">
                    <h5>
                      <strong>{{ question.question }}</strong>
                    </h5>
                  </b-col>
                  <b-col class="text-right">
                    <b-icon-plus></b-icon-plus>
                  </b-col>
                </b-row>
                <b-collapse :id="question.id" accordion="acc" role="tabpanel">
                <div class="ml-4 my-4">
                  {{ question.answer }}
                </div>
              </b-collapse>
              </b-list-group-item>

          </b-list-group>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          id: "acc",
          question: "정말 무료 가입이 가능한지, 숨겨진 비용 있는지 궁금해요!",
          answer:
            "네, 무료 플랜을 선택하시면 월 정액 추가 비용 없이 코인 추가를 통한 사용이 가능합니다. 자세한 내용은 문의 해주세요 :)",
        },
        {
          id: "acc_coin",
          question: "무료 가입후 코인 충전은 어떻게 하나요?",
          answer:
            "erudiolabs@gmail.com으로 문의주시면 계좌 이체 형태나 현금결제로 진행됩니다. 자세한 내용은 문의 해주세요 :)",
        },
        {
          id: "acc2",
          question: "프리미엄의 장점은 무엇인가요?",
          answer:
            "프리미엄에서는 관리자 사이트를 별도로 제공하고, 재공지 기능, 강의 및 사용자 통계 등의 고급 기능을 제공합니다. 강력한 학습 소통 기능 또는 통합 관리 기능이 필요하시면, 프리미엄을 사용해보세요. 자세한 내용은 문의 해주세요 :)",
        },
        {
          id: "acc3",
          question: "100여명 규모의 학원을 운영하고 있습니다. 월간 사용자 수는 어떻게 측정되나요?",
          answer:
            "학생 수와 별개로 학생의 시험 스캔 한건당 1 코인 기준으로 측정합니다. 문의 해주시면 케이스-바이-케이스로 자세한 답변을 드리겠습니다 :)",
        }
      ],
    };
  },
};
</script>