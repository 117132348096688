<template>
  <div>
    <div v-if="exam == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else>
      <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
        <form @submit.prevent="editExam">
          <div class="form-group">
            <label for="title">시험 타이틀 </label>

            <b-form-input
              type="text"
              size="lg"
              class="form-control"
              placeholder="시험 타이틀 *"
              name="title"
              v-model="name"
              :disabled="overlay"
              autocomplete="off"
              required
            />
          </div>

          <div class="form-group">
            <label for="code">시험 코드 (4자리 숫자)</label>

            <b-form-input
              type="number"
              size="lg"
              class="form-control"
              placeholder="시험 코드 *"
              v-model="code"
              name="code"
              :disabled="overlay"
              autocomplete="off"
              min="0"
              max="9999"
              required
            />
          </div>

          <div class="form-group">
            <label for="code">시험 총점</label>

            <b-form-input
              type="number"
              size="lg"
              class="form-control"
              placeholder="시험 총점 *"
              v-model="score"
              name="score"
              :disabled="overlay"
              autocomplete="off"
              min="0"
              required
            />
          </div>

          <div class="form-group">
            <label for="code">시험 결과 리포트에 추가 링크 (https 포함)</label>

            <b-form-input
              type="text"
              size="lg"
              class="form-control"
              v-model="url"
              placeholder="예) 정답 PDF URL (구글 드라이브 링크)"
              name="answerPDF"
              :disabled="overlay"
              autocomplete="off"
            />
          </div>

          <b-row class="mt-3" align-h="center">
            <b-col>
              <b-button
                block
                @click="deleteExam(examId)"
                variant="danger"
                :disabled="overlay"
                ><b-icon icon="exclamation-triangle-fill" class="mr-1"></b-icon>
                <strong>시험 삭제하기</strong></b-button
              >
            </b-col>
            <b-col>
              <b-button block type="submit" variant="dark" :disabled="overlay"
                ><strong>저장하기</strong></b-button
              >
            </b-col>
          </b-row>
          <small>{{ examId }}</small>
        </form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examId: String,
    classroomId: String,
  },
  data() {
    return {
      code: "",
      score: "",
      name: "",
      url: "",
      overlay: false,
      exam: null,
    };
  },
  mounted() {
    this.getExam();
  },
  methods: {
    editExam: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const exam = {
        examId: this.examId,
        classroomId: this.classroomId,
        totalScore: this.score,
        code: parseInt(this.code).toString(),
        name: this.name,
        url: this.url,
      };

      fetch(url + `/api/class/edit/exam`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            if (json.status) {
              this.$emit("edited");
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.code = this.exam.code;
          this.name = this.exam.name;
          this.url = this.exam.url;
        });
    },
    getExam: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const exam = { examId: this.examId, classroomId: this.classroomId };

      fetch(url + `/api/class/exam/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.exam = json.exam;
            this.code = json.exam.code;
            this.name = json.exam.name;
            this.score = json.exam.totalScore;
            this.url = json.exam.url;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    deleteExam: function (examId) {
      this.$bvModal
        .msgBoxConfirm(
          "해당 데이터 다운 받아두기를 권고합니다, 시험과 관련된 모든 데이터들 (시험기록 등)이 삭제됩니다.",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "삭제하기",
            cancelTitle: "취소",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((res) => {
          if (res) {
            const url = this.$url;

            let token = localStorage.getItem("token");
            const exam = { examId: examId, classroomId: this.classroomId };

            fetch(url + `/api/class/delete/exam`, {
              method: "POST",
              headers: {
                authorization: token,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(exam),
            })
              .then((res) => res.json())
              .then((json) => {
                if (!json.error) {
                  if (json.status) {
                    this.$emit("deleted");
                  } else {
                    alert("Something went wrong :/");
                  }
                } else {
                  throw new Error(json.error);
                }
              })
              .catch((error) => {
                alert(error);
              });
          }
        });
    },
  },
};
</script>