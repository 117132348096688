<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="uploadStudents">
        <div class="form-group">
          <label for="name">학생 이름</label>
          <b-form-input
            type="text"
            id="name"
            size="lg"
            class="form-control"
            placeholder="학생 이름 *"
            name="name"
            v-model="name"
            :disabled="overlay"
            autocomplete="off"
            required
          />
        </div>

        <div class="form-group">
          <label for="name"
            >학생 코드 (<strong>8자리 숫자</strong>, 예) 010을
            <strong>제외한</strong> 학생 번호)</label
          >

          <b-form-input
            type="text"
            size="lg"
            class="form-control"
            placeholder="학생 코드 *"
            name="code"
            v-model="codeValue"
            :disabled="overlay"
            autocomplete="off"
            :state="code.length == 8 ? true : false"
            required
          />
          <b-form-invalid-feedback id="input-live-feedback">
            학생 코드 8자리를 입력해주십시오
          </b-form-invalid-feedback>
        </div>

        <div class="form-group">
          <label for="name">결과 수신 번호 <strong>(010 포함)</strong></label>

          <b-form-input
            type="text"
            size="lg"
            name="number"
            class="form-control"
            placeholder="결과 수신 번호 *"
            v-model="numberValue"
            autocomplete="off"
            :disabled="overlay"
            :state="number.length == 11 ? true : false"
            required
          />
          <b-form-invalid-feedback id="input-live-feedback">
            010을 포함한 전화번호 11자리를 입력해주십시오
          </b-form-invalid-feedback>
        </div>

        <b-row class="mt-3" align-h="center">
          <b-col>
            <b-button
              block
              size="lg"
              type="submit"
              variant="dark"
              :disabled="overlay || number.length != 11 || code.length != 8"
              ><strong>학생 추가하기</strong></b-button
            >
          </b-col>
        </b-row>
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "addStudent",
  data: function () {
    return {
      name: "",
      code: "",
      number: "",
      overlay: false,
    };
  },
  computed: {
    codeValue: {
      get: function () {
        return this.code;
      },
      set: function (value) {
        this.code = value.replace(/\D/g, "");
      },
    },
    numberValue: {
      get: function () {
        return this.number;
      },
      set: function (value) {
        this.number = value.replace(/\D/g, "");
      },
    },
  },
  methods: {
    uploadStudents: function () {
      this.overlay = true;
      let token = localStorage.getItem("token");

      this.code = this.code.replace(/\D/g, "");
      this.number = this.number.replace(/\D/g, "");

      console.log({
        name: this.name,
        code: this.code,
        number: this.number,
      });

      if (this.number.length == 11 && this.code.length == 8) {
        fetch(`${this.$apiUrl}/scanner/student/add`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
            // "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify({
            students: [
              {
                name: this.name,
                code: this.code,
                number: this.number,
              },
            ],
          }),
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              this.file = null;
              this.overlay = false;
              this.$emit("imported");
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            this.overlay = false;
          });
      } else {
        alert(
          "학생 번호 혹은 결과 수신 번호에 오류가 발견되었습니다, 수정 부탁 드립니다."
        );
      }
    },
    edit: function (e) {
      if (this.code.length == 8 && this.number.length == 11) {
        this.overlay = true;
        e.preventDefault();

        const student = {
          studentId: this.studentId,
          name: this.name,
          code: this.code,
          number: this.number,
        };

        let token = localStorage.getItem("token");

        fetch(`${this.$apiUrl}/scanner/student/update`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(student),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.error) {
              throw new Error(json.error);
            } else {
              this.$emit("edited");
            }
          })
          .catch((error) => {
            alert(error);
            this.name = this.student.name;
            this.code = this.student.code;
            this.number = this.student.number;
            this.overlay = false;
          });
      }
    },
  },
};
</script>
