<template>
  <div>
    <!-- Update Password -->
    <b-modal
      id="update-password"
      ref="update-password-modal"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">비밀번호 변경</strong></h3>
      </template>
      <UpdatePassword @updated="updated" />
      <small>
        문제를 겪고 계시다면,
        <b-link :href="`mailto:erudiolabs@gmail.com`"
          >여기로 연락 부탁드립니다</b-link
        >:)
      </small>
    </b-modal>

    <b-card class="text-center">
      <b-container class="my-2" fluid>
        <div v-if="user == null">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else>
          <b-row align-v="end" class="mb-1">
            <b-col class="text-left" md="4">
              <h3>User details</h3>
            </b-col>
            <b-col class="text-right" md="3" offset-md="5">
              <b-button
                variant="success"
                class="mx-1 my-1"
                block
                v-b-modal.update-password
                ><b-icon-shield-check /> 비밀번호 변경</b-button
              >
            </b-col>
          </b-row>
          <hr />
          <b-overlay
            :show="overlay"
            rounded="sm"
            class="text-left"
            style="margin-bottom: 10px"
          >
            <form @submit.prevent @submit="updateUser">
              <div class="text-left">
                <label for="teamName">이름</label>
                <b-form-input
                  id="teamName"
                  v-model="name"
                  type="text"
                  required
                ></b-form-input>

                <!-- <label for="teamName" class="mt-2">관리자 계정</label>

                <b-list-group>
                  <b-list-group-item
                    v-for="adminId in team.adminIds"
                    v-bind:key="`admin-${adminId}`"
                    >{{ adminId }}</b-list-group-item
                  >
                </b-list-group> -->

                <label for="teamName" class="mt-2">이메일</label>
                <b-list-group>
                  <b-list-group-item>
                    <div>{{ user.email }}</div></b-list-group-item
                  >
                </b-list-group>

                <div>
                  <label for="teamName" class="mt-2">계정 생성 시간</label>
                  <b-list-group>
                    <b-list-group-item>
                      <div>
                        {{ new Date(user.userCreated * 1000) }}
                      </div></b-list-group-item
                    >
                  </b-list-group>
                </div>

                <small class="text-white">{{ user.userId }}</small>
              </div>

              <b-button
                type="submit"
                variant="dark"
                size="lg"
                :disabled="overlay || user.name == name"
              >
                <strong class="sub-title">업데이트 하기</strong>
              </b-button>
            </form>
          </b-overlay>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import UpdatePassword from "@/components/User/UpdatePassword";
export default {
  components: {
    UpdatePassword,
  },
  data() {
    return {
      user: null,
      overlay: true,
      name: "",
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    updated: function () {
      this.$refs["update-password-modal"].hide();
    },
    updateUser: function () {
      this.overlay = true;

      const user = {
        name: this.name,
      };

      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/user/update`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(user),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            alert("성공적으로 업데이트 됬습니다.");
            this.$emit("refresh");
            this.$store.dispatch("setNameAction", json.user.name);
            this.getUser();
          } else {
            console.log(json);
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          console.log(error);

          alert(error);
          this.overlay = false;
          this.name = "";
        });
    },
    getUser: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/user/details`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.user = json.user;
            this.name = json.user.name;
            this.overlay = false;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
};
</script>