<template>
  <div>
    <div>
      <div class="d-flex justify-content-center mb-3">
        <div class="text-center">
          <b-spinner
            class="mt-5 mb-4"
            label="Loading..."
            style="width: 3rem; height: 3rem"
          >
          </b-spinner>
          <h5 class="px-4">Transaction Failed, Redirecting...</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    let params = this.$route.query;
    alert(`${params.code}: ${params.message}`);
    this.$router.push({ path: "/home" });
  },
};
</script>