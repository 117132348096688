<template>
  <div>
    <b-overlay
      :show="overlay"
      rounded="sm"
      style="margin-bottom: 10px"
      variant="white"
    >
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..."></b-spinner>
          <div class="mt-3" v-if="selected.length !== 0">
            <h5 id="cancel-label" class="px-4">
              전송 중 ... ({{ sentCount }}/{{ selected.length }})
            </h5>
            <b-progress
              :value="(sentCount / selected.length) * 100"
              striped
              animated
              variant="dark"
            ></b-progress>
          </div>
        </div>
      </template>

      <div v-if="results == null">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner class="m-5" label="Loading..."></b-spinner>
        </div>
      </div>

      <div v-else-if="results.length != 0" class="mb-3">
        <b-row class="text-center">
          <b-col class="">
            <b-card>
              <b-card-text>
                <strong
                  >시험제출 현황 :
                  {{
                    Math.round((results.length / students.length) * 10000) /
                    100
                  }}%</strong
                >
                <br />
                <small
                  >총 {{ students.length }}명 중 {{ results.length }}명
                  완료</small
                >
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <b-row
          v-if="
            students.filter(
              (e) => !results.map((e) => e.studentId).includes(e.studentId)
            ).length > 0
          "
        >
          <b-col class="my-1">
            <b-card>
              <b-card-text>
                <strong>미제출자</strong>
              </b-card-text>

              <b-card-text class="text-center"
                ><div>
                  <span
                    v-for="(student, idx) in students.filter(
                      (e) =>
                        !results.map((e) => e.studentId).includes(e.studentId)
                    )"
                    v-bind:key="student.studentId"
                    >{{ student.name }}
                    <span
                      v-if="
                        idx !=
                        students.filter(
                          (e) =>
                            !results
                              .map((e) => e.studentId)
                              .includes(e.studentId)
                        ).length -
                          1
                      "
                      >·
                    </span></span
                  >
                </div></b-card-text
              >
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-button
              variant="success"
              class="my-2"
              block
              @click="toggleSelectAll"
              ><b-icon icon="check"></b-icon>
              {{
                selected.length === filteredStudent.length
                  ? "선택해제"
                  : "전체선택"
              }}
            </b-button>
          </b-col>
          <b-col cols="8"
            ><b-form-input
              v-model="filterQuery"
              placeholder="학생 검색"
              autocomplete="off"
              class="my-2"
            ></b-form-input
          ></b-col>
        </b-row>

        <b-list-group>
          <b-form-checkbox-group v-model="selected">
            <b-list-group-item
              v-for="student in filteredStudent.filter(
                (e) =>
                  e.name.includes(filterQuery) || e.number.includes(filterQuery)
              )"
              v-bind:key="student.studentId"
              ><b-form-checkbox :value="student.studentId"
                >{{ student.name }} | 전송 번호:
                {{ student.number }}</b-form-checkbox
              ></b-list-group-item
            >
          </b-form-checkbox-group>
        </b-list-group>
        <hr />
        <div class="mb-2">
          <strong>문자 상단 문구</strong>
        </div>
        <b-form-textarea
          id="textarea"
          v-model="messageContent"
          placeholder="문자 상단문구를 입력해주세요..."
          rows="5"
          max-rows="6"
        ></b-form-textarea>

        <b-button
          block
          :disabled="selected.length === 0"
          type="submit"
          @click="showSendMessageModal"
          size="lg"
          class="mt-2"
          variant="dark"
          ><strong
            >{{ selected.length }}명에게 결과 메세지 전송</strong
          ></b-button
        >

        <b-modal
          ref="modal-confirm"
          title="전송 컨펌 필요!"
          centered
          size="md"
          hide-header
          @ok="confirmed"
        >
          <p class="mt-2">
            <b-form-textarea
              id="textarea"
              :value="`${messageContent}\n[학생이름] 결과링크: [학생 리포트 주소링크]\n* 해당 번호는 발신전용 번호입니다`"
              placeholder="문자 상단문구를 입력해주세요..."
              readonly
              rows="5"
              max-rows="6"
            ></b-form-textarea>
          </p>

          <strong class="mt-3">
            위와 같이 {{ selected.length }}명에게 문자가 전송 됩니다. ('[]'는
            전송시 링크로 바뀝니다.)
          </strong>

          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="danger" @click="cancel()">
              취소
            </b-button>
            <b-button size="sm" variant="success" @click="ok()">
              전송하기
            </b-button>
          </template>
        </b-modal>
      </div>

      <div v-else>
        <NotFound />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import NotFound from "@/components/Overview/NotFound.vue";

export default {
  components: {
    NotFound,
  },
  props: {
    exam: Object,
    user: String,
    classroom: String,
    students: Array,
  },
  data() {
    return {
      overlay: true,
      results: null,
      sentCount: 0,
      selected: [],
      filterQuery: "",
      messageContent: "",
    };
  },
  mounted() {
    this.getResults();
    console.log(this.classroom);
    this.messageContent = `안녕하세요.\n${this.user} 선생님 ${this.classroom} "${this.exam.name}" 시험 결과 보내드립니다.`;
    this.overlay = false;
  },
  computed: {
    filteredStudent() {
      return (
        this.students.filter((e) =>
          this.results.map((e) => e.studentId).includes(e.studentId)
        ) || []
      );
    },
  },
  methods: {
    toggleSelectAll: function () {
      if (this.selected.length !== this.filteredStudent.length) {
        this.selected = this.filteredStudent.map((e) => e.studentId);
      } else {
        this.selected = [];
      }
    },

    showSendMessageModal: function () {
      this.$refs["modal-confirm"].show();
    },
    confirmed: function () {
      this.overlay = true;
      this.sendMessages();
    },

    sendMessages: function () {
      const url = this.$apiUrl;
      let token = localStorage.getItem("token");

      for (let studentId of this.selected) {
        this.sentCount = this.sentCount + 1;

        let selectedStudent = this.students.filter(
          (student) => student.studentId === studentId
        )[0];

        let selectedStudentResult = this.results.filter(
          (result) => result.studentId === studentId
        )[0];

        console.log(selectedStudent);
        console.log(selectedStudentResult);

        if (selectedStudent && selectedStudentResult) {
          let message = `${this.messageContent}\n${selectedStudent.name} 결과링크: https://scan.erudiolabs.com/result?id=${selectedStudentResult.resultId}\n* 해당 번호는 발신전용 번호입니다`;

          fetch(url + `/message`, {
            method: "POST",
            headers: {
              authorization: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              recipient: selectedStudent.number,
              message: message,
            }),
          })
            .then((res) => res.json())
            .then((json) => {
              if (!json.error) {
                console.log(json);
              } else {
                throw new Error(json.error);
              }
            })
            .catch((error) => {
              alert(error);
            });
        }
      }

      setTimeout(() => {
        alert("전송이 완료되었습니다!");
        this.overlay = false;
        this.selected = [];
        this.sentCount = 0;
      }, 1000);
    },

    // API Requests
    getResults: function () {
      const url = this.$url;
      let token = localStorage.getItem("token");

      const exam = {
        examId: this.exam.examId,
        classroomId: this.exam.classroomId,
      };

      fetch(url + `/api/class/overview/exam`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exam),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);

            this.results = json.results;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>