<template>
  <div id="register">
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent @submit="register">
        <div class="form-group">
          <b-form-input
            type="text"
            size="lg"
            name="name"
            class="form-control"
            placeholder="대표님 성함 *"
            v-model="name"
            :state="name.length == 0 ? null : 1 < name.length ? true : false"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="email"
            size="lg"
            class="form-control"
            placeholder="이메일 주소 *"
            name="email"
            v-model="email"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="password"
            size="lg"
            name="password"
            class="form-control password"
            placeholder="비밀번호 *"
            v-model="password"
            :state="
              password.length == 0 ? null : 1 < password.length ? true : false
            "
            autocomplete="on"
            :disabled="overlay"
            required
          />
        </div>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="overlay"
        >
          <strong class="sub-title">가입하기</strong>
        </b-button>

        <small class="form-text text-muted text-right" style="font-size: 9px">
          (가입과 동시에, Erudio Labs의
          <b-link :to="{ path: '/terms' }" class="text-dark"
            >서비스이용약관</b-link
          >과
          <b-link :to="{ path: '/privacy' }" class="text-dark"
            >개인정보처리방침</b-link
          >에 동의 합니다)
        </small>
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "register",
  data: function () {
    return {
      overlay: false,
      password: "",
      name: "",
      email: "",
    };
  },
  methods: {
    register: function (e) {
      e.preventDefault();
      this.overlay = true;
      const url = this.$apiUrl;

      const user = {
        email: this.email,
        password: this.password,
        name: this.name,
      };

      fetch(`${url}/scanner/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            alert("성공적으로 가입되셨습니다, 메인 페이지로 이동합니다.");
            localStorage.setItem("token", "Bearer " + json["token"]);
            this.$store.dispatch("setLoggedInAction", {
              email: json.email,
              name: json.name,
            });
            this.$router.push({ path: "/home" });
            // this.$emit("registered");
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
          this.email = "";
          this.name = "";
          this.password = "";
        });

      // if (this.passwordState) {
      // fetch(url + "/api/p/register", {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify(user)
      // })
      //   .then(res => res.json())
      //   .then(json => {
      //     console.log(json)
      //     this.overlay = false;
      //     if (!json.error) {
      //       console.log(json)
      //       localStorage.setItem("token", "Bearer " + json["token"])
      //       this.$store.dispatch("setUserAction",json.user)
      //       this.$router.push({path: "/admin"})
      //     } else {
      //       throw new Error(json.error);
      //     }
      //   })
      //   .catch(error => {
      //     alert(error);
      //     this.overlay = false;
      //     this.password = "";
      //   });
    },
  },
};
</script>