<template>
    <div class="text-center">
        <b-container class="my-4">
          <b-row align-v="center">
            <b-col>
              <img
              v-if="image"
                src="@/assets/icons/file.svg"
                class="mb-2"
                width="150rem"
                height="150rem"
              />
              <h3 class="my-2 ">
                <strong>
                 {{obj}}이 발견되지 않았습니다, 아래 버튼을 통해 {{obj}}을 추가 해주십시오.
                </strong>
              </h3>
            </b-col>
          </b-row>
        </b-container>
      </div>
</template>

<script>
export default {
  props: {
    image: {
    default: true,
    type: Boolean
  },
    obj: String
  }
}
</script>