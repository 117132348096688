<template>
  <div>
    <div v-if="classroom == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else>
      <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
        <form @submit.prevent="editClassroom">
          <div class="form-group">
            <label for="title">강의실 이름 </label>

            <b-form-input
              type="text"
              size="lg"
              class="form-control"
              placeholder="강의실 이름 *"
              name="title"
              v-model="name"
              :disabled="overlay"
              autocomplete="off"
              required
            />
          </div>

          <div class="form-group">
            <label for="code">강의실 코드 (4자리 숫자)</label>

            <b-form-input
              type="number"
              size="lg"
              class="form-control"
              placeholder="시험 코드 *"
              v-model="code"
              name="code"
              :disabled="overlay"
              autocomplete="off"
              min="0"
              max="9999"
              required
            />
          </div>

          <b-row class="mt-3" align-h="center">
            <b-col>
              <b-button
                block
                @click="deleteClassroom(classroomId)"
                variant="danger"
                :disabled="overlay"
                ><b-icon icon="exclamation-triangle-fill" class="mr-1"></b-icon>
                <strong>강의실 삭제하기</strong></b-button
              >
            </b-col>
            <b-col>
              <b-button block type="submit" variant="dark" :disabled="overlay"
                ><strong>저장하기</strong></b-button
              >
            </b-col>
          </b-row>
        </form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classroomId: String,
  },
  data() {
    return {
      code: "",
      name: "",
      overlay: false,
      classroom: null,
    };
  },
  mounted() {
    this.getClassroom();
  },
  methods: {
    deleteClassroom: function (cId) {
      this.$bvModal
        .msgBoxConfirm(
          "해당 데이터 다운 받아두기를 권고합니다, 강의실과 관련된 모든 데이터들 (시험기록 등)이 삭제됩니다.",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "삭제하기",
            cancelTitle: "취소",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((res) => {
          if (res) {
            const url = this.$url;

            const token = localStorage.getItem("token");

            const classroom = {
              classroomId: cId,
            };

            console.log(classroom);

            fetch(url + "/api/class/delete", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: token,
              },
              body: JSON.stringify(classroom),
            })
              .then((res) => res.json())
              .then((json) => {
                if (!json.error) {
                  console.log(json);
                  this.$emit("edited");

                  this.classrooms = json.classrooms.sort(
                    (a, b) => b.classroomCreated - a.classroomCreated
                  );
                } else {
                  throw new Error(json.error);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }
        });
    },
    editClassroom: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const classroom = {
        classroomId: this.classroomId,
        code: parseInt(this.code).toString(),
        name: this.name,
      };

      fetch(url + `/api/class/edit/classroom`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(classroom),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            if (json.status) {
              this.$emit("edited");
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.code = this.classroom.code;
          this.name = this.classroom.name;
        });
    },
    getClassroom: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const classroom = { classroomId: this.classroomId };

      fetch(url + `/api/class/classroom/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(classroom),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.classroom = json.classroom;
            this.code = json.classroom.code;
            this.name = json.classroom.name;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>