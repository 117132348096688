<template>
  <div>
    <div v-if="overlay">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else>
      <form @submit.prevent="editStudents">
        <div v-if="selected == null"></div>
        <div v-else>
          <div v-if="selected.length == 0">
            선택된 학생이 없습니다, 아래 양식을 통해 선택 해주십시오
            <hr />
          </div>
          <!-- <div v-else>
        {{ selected }}
        </div> -->

          <b-form-select v-model="tagSelected" :options="tags">
            <b-form-select-option :value="null"
              >태그 선택 해주십시오 (Select All)</b-form-select-option
            >
          </b-form-select>

          <b-form-input
            v-model="filter"
            placeholder="학생 검색"
            autocomplete="off"
            class="my-2"
          ></b-form-input>

          <b-list-group>
            <b-form-checkbox-group v-model="selected">
              <b-list-group-item
                v-for="student in students.filter((e) => {
                  if (tagSelected != null) {
                    return (
                      e.tags.includes(tagSelected) &&
                      (e.name.includes(filter) || e.code.includes(filter))
                    );
                  }
                  return e.name.includes(filter) || e.code.includes(filter);
                })"
                v-bind:key="student.studentId"
                ><b-form-checkbox :value="student"
                  >{{ student.name }} | {{ student.code }}</b-form-checkbox
                ></b-list-group-item
              >
            </b-form-checkbox-group>
          </b-list-group>

          <b-button
            block
            type="submit"
            size="lg"
            class="mt-2"
            variant="dark"
            :disabled="overlay"
            ><strong>학생 추가/제거하기</strong></b-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classroomId: String,
    studentIds: Array,
  },
  data() {
    return {
      overlay: true,
      filter: "",
      selected: null,
      students: [],
      tags: [],
      tagSelected: null,
    };
  },
  mounted() {
    this.getStudents();
  },
  methods: {
    setSelected: function () {
      this.selected = [];
      this.students.map((e) => {
        if (this.studentIds.includes(e.studentId)) {
          this.selected.push(e);
        }
      });
      this.overlay = false;
    },
    editStudents: function () {
      this.overlay = true;
      const url = this.$url;

      const token = localStorage.getItem("token");

      const studentIds = {
        studentIds: this.selected.map((e) => {
          return e.studentId;
        }),
        classroomId: this.classroomId,
      };

      fetch(url + "/api/class/edit/students", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(studentIds),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.overlay = false;
            this.$emit("edited");
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getStudents: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/student`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.students = json.students;
            this.tags = json.students.reduce(
              (a, e, i) => {
                if (i == 0) {
                  a = e.tags;
                } else {
                  a = [...new Set([...e.tags, ...a])];
                }
                return a;
              },
              [null]
            );
            this.setSelected();
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
};
</script>