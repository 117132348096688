<template>
  <b-container class="pricing-container my-5" ref="pricing-container">
    <b-row class="justify-content-md-center mb-5">
      <b-col class="text-center">
        <h1 class="sub-title mb-3">
          <strong
            >OMR 스캐너™를 통해 효율적으로 채점, 시험결과 관리하세요.</strong
          >
        </h1>
        <h4>
          소규모 개인 사용자부터 대형 학원, 기업 및 기관까지 맞춤형 요금제를
          제공합니다.
        </h4>
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      align-v="center"
      cols="1"
      :style="
        animation ? `animation: fadein 2s, slidein 1s` : `visibility:hidden`
      "
    >
      <b-col
        col
        lg="4"
        id="pricing"
        class="text-center my-3 d-flex align-items-stretch"
        v-for="(card, cardIndex) in cards"
        v-bind:key="cardIndex"
      >
        <b-card
          style="
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            min-width: 100%;
          "
          border-variant="light"
        >
          <h3>
            <strong>{{ card.title }}</strong>
          </h3>
          <p class="my-4" style="min-height: 70px" v-html="card.desc"></p>
          <h2 class="text-left ml-2">
            <strong class="sub-title">{{ card.price }}</strong>
          </h2>

          <b-list-group class="text-left" style="min-height: 140px" flush>
            <b-list-group-item
              v-for="(feature, featureIndex) in card.features"
              v-bind:key="featureIndex"
              class="d-flex align-items-center"
              ><b-icon-check2-square class="pr-4" />
              {{ feature }}</b-list-group-item
            >
          </b-list-group>
          <p></p>
          <b-button
            :variant="card.actionButton.variant"
            class="mt-4 mb-2"
            block
            :disabled="name != false"
            @click="openModal(card.actionButton.modal)"
            >{{ card.actionButton.content }}</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  computed: {
    name() {
      return this.$store.getters.name;
    },
  },
  mounted() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    let observer = new IntersectionObserver((e) => {
      console.log(e);
      if (e[0].isIntersecting) {
        this.animation = true;
      } else {
        this.animation = false;
      }
      console.log(this.animation);
    }, options);

    observer.observe(this.$refs["pricing-container"]);
  },
  data() {
    return {
      animation: false,
      cards: [
        {
          title: "모든기능 무료! 🚀",
          desc: "지금 바로 <mark><strong>에루디오 랩스</strong></mark>와 함께<br/> 문자전송 제외 <strong>OMR 스캐너의 모든 기능</strong>들을 무료로 누려보세요.",
          price: "Free",
          features: [
            "무제한 강의 및 시험 개설",
            "무제한 학생 추가",
            "OMR Scanner™만의 리포트",
          ],
          actionButton: {
            variant: "primary",
            content: "무료로 시작하기",
            modal: "partner-register-modal",
          },
        },
        {
          title: "프리미엄 (학원)",
          desc: "통계 및 분석, 강의 통합 관리를 통하여 여러분의 학습 현장에 놀라운 변화를 만들어보세요.",
          price: "가격 문의",
          features: [
            "모든 무료 가입 기능",
            "무제한 팀원 추가",
            "강의 및 사용자 분석",
          ],
          actionButton: {
            variant: "danger",
            content: "문의하기",
            modal: "partner-enquiry-modal",
          },
        },
        {
          title: "엔터프라이즈",
          desc: "기업 혹은 학교 단위의 대규모 도입을 원하시나요? 맞춤형 상담을 제공합니다. 망설이지 말고 문의해주세요.",
          price: "가격 문의",
          features: ["모든 프리미엄 기능", "엔터프라이즈 연동"],
          actionButton: {
            variant: "primary",
            content: "문의하기",
            modal: "partner-enquiry-modal",
          },
        },
      ],
    };
  },
  methods: {
    openModal(name) {
      this.$emit("open-modal", name);
    },
  },
};
</script>


<style scoped>
#pricing {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

#pricing:hover {
  top: -20px;
}
</style>