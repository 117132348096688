<template>
  <div id="waitlist">
    <b-jumbotron style="padding-top: 4.5rem; margin: 0" class="withus-hero rounded-0">
      <b-row class="justify-content-md-center text-center" style="color: white">
        <!-- <b-card style="max-width: 50rem; min-width: 70vw" class="my-auto"> -->
          <div class="my-4">
            <h1 >
              <strong class="title">선생님과 학생들 모두를 위한 채점 솔루션, 지금 바로 시작하세요</strong>
            </h1>
            <strong class="sub-title"
              >OMR 스캐너™ 도입을 아직도 망설이고 계시나요?  무료로 부담 없이 경험해 보세요</strong
            >
            <br/>
            <b-button
                variant="light"
                size="lg"
                class="title mt-5"
                v-b-modal.partner-register
                :disabled="name != false"
                ><strong>무료로 가입하기</strong></b-button
              >
          </div>

        <!-- </b-card> -->
      </b-row>
    </b-jumbotron>

    <!-- <b-row class="justify-content-md-center mt-4">
              <b-col md="7">
                <b-form>
                  <b-form-input
                    class="text-center"
                    placeholder="대표님의 이메일 주소를 입력 해주세요 :)"
                    name="email"
                    type="email"
                    autocomplete="on"
                  ></b-form-input>
                </b-form>
              </b-col>
            </b-row> -->


  </div>
</template>

<script>
export default {
  computed: {
    name() {
      return this.$store.getters.name;
    },
  },
  data() {
    return {
      content: "<br/> :) ",
    };
  },
};
</script>

<style scoped>
.withus-hero {
  background-color: black;
}
</style>