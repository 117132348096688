<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="edit">
        <div class="form-group">
          <label for="name">학생 이름</label>
          <b-form-input
            type="text"
            id="name"
            size="lg"
            class="form-control"
            placeholder="학생 이름 *"
            name="name"
            v-model="name"
            :disabled="overlay"
            autocomplete="off"
            required
          />
        </div>

        <div class="form-group">
          <label for="name">학생 코드 (8자리 숫자)</label>

          <b-form-input
            type="text"
            size="lg"
            class="form-control"
            placeholder="학생 코드 *"
            name="code"
            v-model="codeValue"
            :disabled="overlay"
            autocomplete="off"
            :state="code.length == 8 ? true : false"
            required
          />
          <b-form-invalid-feedback id="input-live-feedback">
            학생 코드 8자리를 입력해주십시오
          </b-form-invalid-feedback>
        </div>

        <div class="form-group">
          <label for="name">결과 수신 번호 (010 포함)</label>

          <b-form-input
            type="text"
            size="lg"
            name="number"
            class="form-control"
            placeholder="결과 수신 번호 *"
            v-model="numberValue"
            autocomplete="off"
            :disabled="overlay"
            :state="number.length == 11 ? true : false"
            required
          />
          <b-form-invalid-feedback id="input-live-feedback">
            010을 포함한 전화번호 11자리를 입력해주십시오
          </b-form-invalid-feedback>
        </div>

        <b-row class="mt-3" align-h="center">
          <b-col>
            <b-button
              @click="deleteStudent(student.studentId)"
              variant="danger"
              block
              ><b-icon icon="exclamation-triangle-fill"></b-icon> 학생
              삭제하기</b-button
            >
          </b-col>
          <b-col>
            <b-button
              block
              type="submit"
              variant="dark"
              :disabled="
                overlay ||
                number.length != 11 ||
                code.length != 8 ||
                changed == false
              "
              ><strong>수정하기</strong></b-button
            >
          </b-col>
        </b-row>
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "editStudentInfo",
  props: {
    student: Object,
  },
  data: function () {
    return {
      changed: false,
      studentId: "",
      name: "",
      code: "",
      number: "",
      overlay: false,
    };
  },
  watch: {
    name(val) {
      if (
        val == this.student.name &&
        this.code == this.student.code &&
        this.number == this.student.number
      ) {
        this.changed = false;
      } else {
        this.changed = true;
      }
    },
    code(val) {
      if (
        val == this.student.code &&
        this.name == this.student.name &&
        this.number == this.student.number
      ) {
        this.changed = false;
      } else {
        this.changed = true;
      }
    },
    number(val) {
      if (
        val == this.student.number &&
        this.name == this.student.name &&
        this.code == this.student.code
      ) {
        this.changed = false;
      } else {
        this.changed = true;
      }
    },
  },
  mounted() {
    this.studentId = this.student.studentId;
    this.name = this.student.name;
    this.code = this.student.code;
    this.number = this.student.number;
  },
  computed: {
    codeValue: {
      get: function () {
        return this.code;
      },
      set: function (value) {
        this.code = value.replace(/\D/g, "");
      },
    },
    numberValue: {
      get: function () {
        return this.number;
      },
      set: function (value) {
        this.number = value.replace(/\D/g, "");
      },
    },
  },
  methods: {
    deleteStudent: function (sId) {
      this.$bvModal
        .msgBoxConfirm(
          "해당 학생과 관련된 모든 데이터들 (시험기록 등)이 삭제됩니다.",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "삭제하기",
            cancelTitle: "취소",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((res) => {
          if (res) {
            const token = localStorage.getItem("token");

            fetch(`${this.$apiUrl}/scanner/student/delete`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: token,
              },
              body: JSON.stringify({
                studentId: sId,
              }),
            })
              .then((res) => res.json())
              .then((json) => {
                if (!json.error) {
                  console.log(json);
                  alert("성공적으로 학생이 삭제되었습니다.");
                  this.$emit("edited");
                } else {
                  throw new Error(json.error);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }
        });
    },
    edit: function (e) {
      if (this.code.length == 8 && this.number.length == 11) {
        this.overlay = true;
        e.preventDefault();

        const student = {
          studentId: this.studentId,
          name: this.name,
          code: this.code,
          number: this.number,
        };

        let token = localStorage.getItem("token");

        fetch(`${this.$apiUrl}/scanner/student/update`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(student),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.error) {
              throw new Error(json.error);
            } else {
              this.$emit("edited");
            }
          })
          .catch((error) => {
            alert(error);
            this.name = this.student.name;
            this.code = this.student.code;
            this.number = this.student.number;
            this.overlay = false;
          });
      }
    },
  },
};
</script>
