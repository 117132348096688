<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="login">
        <div class="form-group">
          <b-form-input
            type="email"
            size="lg"
            class="form-control"
            placeholder="이메일 주소 *"
            name="email"
            v-model="email"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="password"
            size="lg"
            name="password"
            class="form-control password"
            placeholder="비밀번호 *"
            v-model="password"
            :state="
              password.length == 0 ? null : 1 < password.length ? true : false
            "
            autocomplete="on"
            :disabled="overlay"
            required
          />
        </div>

        <b-button
          block
          type="submit"
          size="lg"
          variant="dark"
          :disabled="overlay"
          ><strong>로그인</strong></b-button
        >
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
      overlay: false,
    };
  },
  methods: {
    login: function (e) {
      this.overlay = true;
      e.preventDefault();

      const user = {
        email: this.email,
        password: this.password,
      };

      fetch(`${this.$apiUrl}/scanner/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            throw new Error(json.error);
          } else {
            localStorage.setItem("token", "Bearer " + json["token"]);
            this.$store.dispatch("setLoggedInAction", {
              email: json.email,
              name: json.name,
            });
            this.$router.push({ path: "/home" });
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
          this.email = "";
          this.password = "";
        });
    },
  },
};
</script>
