<template>
  <div>
    <form @submit.prevent="search">
      <b-input-group class="my-3" prepend="단어 사전">
        <b-form-input v-model="query" autocomplete="off"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="Button" variant="success" type="submit"
            >Search</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </form>

    <hr />

    <div v-if="response != ''">
      <b-row class="text-center">
        <b-col><strong>definition</strong></b-col>
        <b-col cols="10">{{ response.definition }}</b-col>
      </b-row>
      <b-row class="text-center">
        <b-col><strong>synonyms (ML)</strong></b-col>
        <b-col cols="10">{{ response.synonyms }}</b-col>
      </b-row>
      <b-row class="text-center">
        <b-col><strong>antonyms (ML)</strong></b-col>
        <b-col cols="10">{{ response.antonyms }}</b-col>
      </b-row>
      <b-row class="text-center">
        <b-col><strong>examples</strong></b-col>
        <b-col cols="10">{{ response.examples }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: "",
      response: "",
    };
  },
  methods: {
    search: function () {
      const url = `${this.$apiUrl}/dict/${this.query}`;

      fetch(`${url}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.response = json;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    },
  },
};
</script>