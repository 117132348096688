var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.overlay)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-spinner',{staticClass:"m-5",attrs:{"label":"Loading..."}})],1)]):_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.editStudents($event)}}},[(_vm.selected == null)?_c('div'):_c('div',[(_vm.selected.length == 0)?_c('div',[_vm._v(" 선택된 학생이 없습니다, 아래 양식을 통해 선택 해주십시오 "),_c('hr')]):_vm._e(),_c('b-form-select',{attrs:{"options":_vm.tags},model:{value:(_vm.tagSelected),callback:function ($$v) {_vm.tagSelected=$$v},expression:"tagSelected"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("태그 선택 해주십시오 (Select All)")])],1),_c('b-form-input',{staticClass:"my-2",attrs:{"placeholder":"학생 검색","autocomplete":"off"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-list-group',[_c('b-form-checkbox-group',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.students.filter(function (e) {
                if (_vm.tagSelected != null) {
                  return (
                    e.tags.includes(_vm.tagSelected) &&
                    (e.name.includes(_vm.filter) || e.code.includes(_vm.filter))
                  );
                }
                return e.name.includes(_vm.filter) || e.code.includes(_vm.filter);
              })),function(student){return _c('b-list-group-item',{key:student.studentId},[_c('b-form-checkbox',{attrs:{"value":student}},[_vm._v(_vm._s(student.name)+" | "+_vm._s(student.code))])],1)}),1)],1),_c('b-button',{staticClass:"mt-2",attrs:{"block":"","type":"submit","size":"lg","variant":"dark","disabled":_vm.overlay}},[_c('strong',[_vm._v("학생 추가/제거하기")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }