<template>
  <div>
    <b-form @submit="processXlsx(file)" @submit.prevent>
      <b-card>
        <a href="/download/quiz"
          >이 링크를 눌러 엑셀 템플렛 파일을 다운받아 <br />
        </a>
        단어 입력후 업로드 진행해주시기 바랍니다.
      </b-card>
      <b-form-group>
        <!-- Styled -->
        <b-form-file
          class="mt-2 text-left"
          v-model="file"
          :state="Boolean(file)"
          placeholder="정보가 추가된 엑셀 파일을 첨부해 주십시오..."
          drop-placeholder="Drop file here..."
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ></b-form-file>
      </b-form-group>

      <div class="form-group text-left">
        <label for="download-method">답안지 연동 방식</label>

        <span class="text-white">{{ classroomSelected }}</span>
        <b-form-select
          id="download-method"
          v-model="classroomSelected"
          :options="
            classrooms.map((e) => {
              return { value: e.classroomId, text: `'${e.name}' 에 추가` };
            })
          "
        >
          <b-form-select-option :value="null"
            >답안지 엑셀파일 다운로드</b-form-select-option
          >
        </b-form-select>
      </div>

      <div class="form-group text-left">
        <b-form-input
          type="number"
          min="0"
          size="lg"
          class="form-control"
          placeholder="시험 문제 수 *"
          v-model="questionNumbers"
          required
        />
      </div>

      <div class="form-group text-left">
        <b-form-input
          type="text"
          min="0"
          size="lg"
          class="form-control"
          placeholder="시험 타이틀 *"
          v-model="examName"
          required
        />
      </div>

      <div class="form-group text-left" v-if="classroomSelected != null">
        <b-form-input
          type="number"
          min="0"
          max="9999"
          size="lg"
          class="form-control"
          placeholder="시험 코드 (0000 부터 9999 까지 선택) *"
          name="code"
          v-model="code"
          :disabled="overlay"
          required
        />
        <small> * 네자리 숫자가 아닌 경우 앞에 0이 생략됩니다. </small>
      </div>

      <b-button
        block
        type="submit"
        variant="dark"
        size="lg"
        :disabled="
          file == null ||
          questionNumbers == '' ||
          examName == '' ||
          classroomSelected != null
            ? code == ''
            : false
        "
      >
        <strong class="sub-title">단어 시험 만들기</strong>
      </b-button>

      <b-row align-h="end" class="text-right">
        <b-col cols="4">
          <b-form-checkbox switch size="lg" v-model="flipped"
            >단어-뜻 문제 SWAP</b-form-checkbox
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import xlsx from "xlsx";
import * as docx from "docx";
import { v4 as uuidv4 } from "uuid";
const saveAs = require("file-saver");
export default {
  data() {
    return {
      file: null,
      overlay: false,
      questionNumbers: "",
      flipped: false,
      categories: [],
      words: [],
      code: "",
      examName: "",
      classroomSelected: null,
      classrooms: [],
    };
  },
  mounted() {
    this.getClassrooms();
  },
  methods: {
    getClassrooms: function () {
      const token = localStorage.getItem("token");

      fetch(`${this.$url}/api/class/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.classrooms = json.classrooms.sort(
              (a, b) => b.classroomCreated - a.classroomCreated
            );
          } else {
            throw new Error(json.error);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    convertQuiztoExam: function (quiz) {
      return {
        name: this.examName,
        questionNum: quiz.length,
        answers: quiz.map((question, idx) => {
          return {
            questionNumber: idx + 1,
            questionAnswer: [question.answerLoc],
            questionPoints: 1,
            questionCategory: question.category,
            desc: question.desc,
          };
        }),
        totalScore: quiz.length,
        type: 0,
      };
    },
    makeAnswerSheet: function (quiz, docName) {
      var ws_name = "SheetJS";
      var wb = xlsx.utils.book_new();

      /* make worksheet */
      var ws_data = [
        ["문항번호", "정답", "배점", "유형", "단어"],
        ...quiz.map((question, idx) => {
          return [
            idx + 1,
            question.answerLoc,
            1,
            question.category,
            question.desc,
          ];
        }),
      ];
      var ws = xlsx.utils.aoa_to_sheet(ws_data);

      /* Add the worksheet to the workbook */
      xlsx.utils.book_append_sheet(wb, ws, ws_name);

      var wopts = { bookType: "xlsx", bookSST: false, type: "array" };

      var wbout = xlsx.write(wb, wopts);
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        `${docName}-answer.xlsx`
      );
    },
    shuffleArray: function (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    getRandomInt: function (min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getRandomIdxs: function (n, array, idx = -1) {
      let idxArray = [];
      while (idxArray.length != n) {
        let randNum = this.getRandomInt(0, array.length - 1);
        if (randNum != idx) {
          if (!idxArray.includes(randNum)) {
            idxArray.push(randNum);
          }
        }
      }

      return idxArray;
    },
    generateQuestions: function (words, flipped) {
      let questions = [];
      if (words.length > 5) {
        words.forEach((word, idx) => {
          let idxs = this.getRandomIdxs(4, words, idx);
          let answerIdx = this.getRandomInt(0, 4);

          if (!flipped) {
            let choices = idxs.map((e) => words[e].meaning);
            choices.splice(answerIdx, 0, word.meaning);
            questions.push({
              category: word.category,
              desc: `${word.word}/${word.meaning}`,
              question: word.word,
              answer: word.meaning,
              answerLoc: answerIdx + 1,
              choices: choices,
              from: word.from,
            });
          } else {
            let choices = idxs.map((e) => words[e].word);
            choices.splice(answerIdx, 0, word.word);
            questions.push({
              category: word.category,
              desc: `${word.word}/${word.meaning}`,
              question: word.meaning,
              answer: word.word,
              answerLoc: answerIdx + 1,
              choices: choices,
              from: word.from,
            });
          }
        });
      } else {
        console.log("Not enough words in the list");
      }
      return questions;
    },
    makeQuiz: function (categories, words, flipped = false) {
      let questions = [];
      for (let category of categories) {
        console.log(category);
        let categoryQuestions = this.generateQuestions(
          words.filter((e) => e.category == category),
          flipped
        );
        questions.push(...categoryQuestions);
      }

      questions = this.shuffleArray(questions);

      if (this.questionNumbers < questions.length) {
        questions = questions.slice(0, this.questionNumbers);
      } else {
        alert("문제 수가 단어 수보다 적습니다, 최대 문제수로 만들어집니다.");
      }

      return questions;
    },

    makeDocx: async function (quiz) {
      let docName = `${this.examName ? this.examName : "quiz"}-${uuidv4()}`;
      let success = true;

      if (this.classroomSelected != null) {
        let exam = this.convertQuiztoExam(quiz);
        success = await this.uploadExam(exam);
        console.log(`upload: ${success}`);
      }

      if (success) {
        this.makeAnswerSheet(quiz, docName);

        const doc = new docx.Document({
          sections: [
            {
              properties: {},
              children: [
                ...quiz.map((question, idx) => {
                  return new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: `${idx + 1}) `,
                        font: "Cambria",
                        size: 13.5 * 2,
                      }),
                      new docx.TextRun({
                        text: `${question.question}`,
                        font: "Cambria",
                        size: 13.5 * 2,
                        bold: true,
                      }),
                      new docx.TextRun({
                        text: `\t(${question.from})`,
                        font: "Cambria",
                        size: 8 * 2,
                        italics: true,
                      }),

                      new docx.TextRun({
                        text: `① ${question.choices[0]}`,
                        font: "Cambria",
                        size: 9 * 2,
                        break: 1,
                      }),
                      new docx.TextRun({
                        text: `② ${question.choices[1]} `,
                        font: "Cambria",
                        size: 9 * 2,
                        break: 1,
                      }),
                      new docx.TextRun({
                        text: `③ ${question.choices[2]}`,
                        font: "Cambria",
                        size: 9 * 2,
                        break: 1,
                      }),
                      new docx.TextRun({
                        text: `④ ${question.choices[3]}`,
                        font: "Cambria",
                        size: 9 * 2,
                        break: 1,
                      }),
                      new docx.TextRun({
                        text: `⑤ ${question.choices[4]}`,
                        font: "Cambria",
                        size: 9 * 2,
                        break: 1,
                      }),
                    ],
                    spacing: {
                      line: 300,
                      after: 500,
                    },
                  });
                }),
              ],
            },

            {
              properties: {
                type: docx.SectionType.NEXT_PAGE,
              },
              children: [
                new docx.Paragraph({
                  children: [
                    new docx.TextRun({
                      text: `Answers`,
                      font: "Cambria",
                      size: 20 * 2,
                      underline: true,
                    }),
                  ],
                  spacing: {
                    line: 200,
                  },
                }),
                ...quiz.map((question, idx) => {
                  return new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: `${idx + 1}) ${question.answerLoc}`,
                        font: "Cambria",
                        size: 8 * 2,
                      }),
                    ],
                    spacing: {
                      line: 200,
                    },
                  });
                }),
              ],
            },
          ],
        });

        console.log(doc);

        docx.Packer.toBlob(doc).then((blob) => {
          // saveAs from FileSaver will download the file
          saveAs(blob, `${docName}.docx`);
        });

        this.categories = [];
        this.words = [];
      }
    },
    xlDataToArray: function (xlData) {
      const xlKeys = Object.keys(xlData[0]);
      for (let word of xlData) {
        if (!this.categories.includes(word[xlKeys[0]])) {
          this.categories.push(word[xlKeys[0]]);
        }
        this.words.push({
          category: word[xlKeys[0]],
          word: word[xlKeys[1]],
          meaning: word[xlKeys[2]],
          from: word[xlKeys[3]],
        });
      }
      console.log(this.words);
      console.log(this.categories);

      let quiz = this.makeQuiz(this.categories, this.words, this.flipped);
      console.log(quiz);
      this.makeDocx(quiz);
    },
    processXlsx: function (file) {
      //   this.makeDocx();
      let reader = new FileReader();

      reader.onload = (e) => {
        let data = e.target.result;
        let workbook = xlsx.read(data, {
          type: "binary",
        });

        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          let xlData = xlsx.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );

          console.log(xlData);
          this.xlDataToArray(xlData);
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
        alert(`${ex}`);
      };

      reader.readAsBinaryString(file);
    },

    uploadExam: function (exam) {
      this.overlay = true;
      const url = this.$url;

      let token = localStorage.getItem("token");
      return fetch(
        url +
          `/api/class/import/exam/${this.classroomSelected}/${parseInt(
            this.code
          ).toString()}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
            // "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify({ exam }),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.$emit("added");
            alert(
              `시험 '${this.examName}'이 강의실 '${
                this.classrooms.filter(
                  (e) => e.classroomId == this.classroomSelected
                )[0].name
              }'에 성공적으로 추가되었습니다.`
            );
            this.code = "";
            this.overlay = false;
            return true;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.code = "";
          this.overlay = false;
          return false;
        });
    },
  },
};
</script>