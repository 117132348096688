<template>
  <div id="store-qr-show" class="mb-2">
    <b-row class="justify-content-md-center">
      <b-col class="text-center">
        <b-img :src="img" fluid style="width: 100%" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button variant="dark" size="lg" :href="img" :download="`class-qr.jpg`" block> QR 코드 다운받기 </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  props: {
    data: String,
  },
  data() {
    return {
      img: "",
    };
  },
  methods: {
    async genQr(data) {
      this.img = await QRCode.toDataURL(data);
    },
    download() {
      let url = this.img.replace(
        /^data:image\/[^;]+/,
        "data:application/octet-stream"
      );
      window.open(url);
    },
  },
  mounted() {
    this.genQr(this.data);
  },
};
</script>