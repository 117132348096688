<template>
  <div>
    <!-- Student Mistakes -->
    <b-modal
      :id="`student-mistakes-${studentId}`"
      :ref="`student-mistakes-${studentId}-modal`"
      centered
      hide-footer
    >
      <template v-slot:modal-title>
        <h3><strong class="title">오답 유형</strong></h3>
      </template>
      <StudentMistakes :resultId="selectedResult" />
    </b-modal>

    <!-- Result Info -->
    <div v-if="results == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" type="grow" label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else-if="results.length == 0">
      <NoResultFound />
    </div>
    <div v-else>
      <b-row align-v="center">
        <b-col class="text-left my-1" md="9">
          <h3 class="ml-3">학생 시험내역</h3>
        </b-col>
        <b-col class="text-right my-1" md="3">
          <b-form-input v-model="filter" placeholder="시험 검색"></b-form-input>
        </b-col>
      </b-row>
      <hr />
      <div
        v-for="classroom in classrooms"
        v-bind:key="`classroom-${classroom.classroomId}`"
      >
        <h5 class="display text-left ml-3 mb-3">
          <strong
            >{{ classroom.name }} ({{ classroom.code }})

            <b-button
              class="ml-1"
              @click="downloadResults(classroom.classroomId)"
              size="sm"
              variant="white"
            >
              <b-icon-cloud-download scale="0.95" />
            </b-button>
          </strong>
        </h5>

        <b-table
          :items="
            results
              .filter((e) => e.classroomId == classroom.classroomId)
              .map((e) => {
                return {
                  resultId: e.resultId,
                  name: exams.find((exam) => exam.examId == e.examId).name,
                  totalScore: exams.find((exam) => exam.examId == e.examId)
                    .totalScore,
                  code: exams.find((exam) => exam.examId == e.examId).code,
                  score: e.score,
                  lastUpdated: e.lastUpdated,
                };
              })
          "
          :fields="fields"
          :filter="filter"
        >
          <template #cell(name)="row">
            {{ row.item.name }}
            ({{ row.item.code }})
          </template>

          <template #cell(score)="row">
            {{ row.item.score }}/{{ row.item.totalScore }}
          </template>

          <template #cell(lastUpdated)="row">
            {{
              new Date(row.item.lastUpdated * 1000).toLocaleDateString("en-US")
            }}
            {{
              new Date(row.item.lastUpdated * 1000).toLocaleTimeString("en-US")
            }}
          </template>

          <template #cell(report)="row">
            <b-tooltip
              :target="`report-${studentId}-${row.item.resultId}`"
              triggers="hover"
              top
            >
              테스트 리포트
            </b-tooltip>
            <b-button
              :id="`report-${studentId}-${row.item.resultId}`"
              size="sm"
              variant="white"
              class="mx-1"
              @click="showResultInfo(row.item.resultId)"
            >
              <b-icon-share />
            </b-button>

            <b-tooltip
              :target="`student-mistakes-${studentId}-${row.item.resultId}`"
              triggers="hover"
              top
            >
              틀린 유형/단어 보기
            </b-tooltip>

            <b-button
              :id="`student-mistakes-${studentId}-${row.item.resultId}`"
              size="sm"
              variant="white"
              v-b-modal="`student-mistakes-${studentId}`"
              class="mx-1"
              @click="selectResult(row.item.resultId)"
            >
              <b-icon icon="exclamation-circle"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
const saveAs = require("file-saver");
import NoResultFound from "@/components/Student/NoResultFound.vue";
import StudentMistakes from "@/components/Student/StudentMistakes.vue";

export default {
  props: {
    studentId: String,
  },
  components: {
    NoResultFound,
    StudentMistakes,
  },
  data() {
    return {
      fields: [
        {
          key: "report",
          label: "리포트",
        },
        {
          key: "name",
          label: "시험 이름",
          sortable: true,
        },
        {
          key: "score",
          label: "점수/총점",
          sortable: true,
        },
        // {
        //   key: "lastUpdated",
        //   label: "마지막 업데이트",
        //   sortable: true,
        // },
      ],
      filter: "",
      selectedResult: null,
      results: null,
      exams: null,
      classrooms: null,
      data: null,
    };
  },
  computed: {
    items: function () {
      return this.selectedResult.answersChosen;
    },
  },
  methods: {
    downloadResults: function (classroomId) {
      const url = `${this.$apiUrl}/scanner/download/result`;

      let token = localStorage.getItem("token");

      fetch(url + `/student`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          classroomId: classroomId,
          studentId: this.studentId,
        }),
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          saveAs(blob, `student-${classroomId}.csv`);
        })
        .catch((error) => {
          alert(error);
        });
    },

    selectResult: function (result) {
      this.selectedResult = result;
    },
    showResultInfo: function (resultId) {
      window.open(`/result?id=${resultId}`, "_blank");
    },
    getExamResults: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      const studentId = {
        studentId: this.studentId,
      };

      console.log(studentId);

      fetch(url + `/api/result/student/exams/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(studentId),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.results = json.examResults;
            this.exams = json.exams;
            this.classrooms = json.classrooms;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  mounted() {
    this.getExamResults();
  },
};
</script>