<template>
  <div class="my-4">
    <b-container>
      <b-row align-h="center">
        <b-col>
          <h3>
            <strong> 에루디오 랩스 개인정보처리방침 (Privacy Policy)</strong>
          </h3>

          <h5>Last Updated: January 1, 2021</h5>

          <hr />
          <p>
            <br />1.개인 정보의 처리 목적
            <br />
            <br />에루디오랩스 주식회사 및 el-omr.com은 다음의 목적을 위하여
            개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지
            않습니다.
            <br />
            <br />
            2. 정보주체의 권리, 의무 및 그 행사방법 이용자는 개인정보주체로서
            다음과 같은 권리를 행사할 수 있습니다.
            <br />
            <br />① 정보주체는 (에루디오랩스 주식회사 및 el-omr.com)에 대해
            언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
            <br />1) 개인정보 열람 요구 <br />2) 오류 등이 있을 경우 정정 요구
            <br />3) 삭제 요구 <br />4) 처리정지 요구
            <br />
            <br />3. 처리하는 개인정보의 항목 작성 <br />
            <br />- 성명 <br />- 이메일<br />- 학생 정보 (번호, 학부모 번호)
            <br />
            <br />4. 개인정보의 파기
            <br />
            <br />(에루디오랩스 주식회사 및 el-omr.com)는 원칙적으로 개인정보
            처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.
            파기의 절차, 기한 및 방법은 다음과 같습니다.
            <br />
            <br />- 회원탈퇴 시 더 이상 위 권한을 사용하지 않습니다.<br />- 위
            권한을 사용하여 어떠한 정보도 수집하지 않고, 처리하지 않습니다.
            <br />
            <br />5. 개인정보의 안전성 확보 조치
            <br />
            <br />
            (에루디오랩스 주식회사 및 el-omr.com)는 개인정보보호법 제 29조에
            따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를
            하고 있습니다.
            <br />
            <br />① 내부관리계획의 수립 및 시행
            <br />
            <br />- 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
            시행하고 있습니다.
            <br />
            <br />② 개인정보에 대한 접근 제한
            <br />
            <br />- 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
            부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
            조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
            접근을 통제하고 있습니다.
            <br />
            <br />③ 비인가자에 대한 출입 통제
            <br />
            <br />- 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에
            대해 출입통제 절차를 수립, 운영하고 있습니다.
            <br/>
            <br/>6. 개인정보보호 책임자
            <br />
            <br />① 주식회사 이들은 개인정보 처리에 관한 업무를 총괄해서
            책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            <br />

            <br />▶ 개인정보 보호책임자 및 담당부서
            <br />
            <br />성명 : 황성진 <br />직책 : 대표 <br />연락처 :
            hwaspc2@gmail.com / 010-6213-6199
            <br />
            <br />② 정보주체께서는 (에루디오랩스 주식회사 및 el-omr.com)의
            서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련
            문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
            담당부서로 문의하실 수 있습니다. 주식회사 이들은 정보주체의 문의에
            대해 지체없이 답변 및 처리해드릴 것입니다.
            <br />
            <br />7. 개인정보 처리방침 변경
            <br />
            <br />① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에
            따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행
            7일 전부터 공지사항을 통하여 고지할 것입니다.
            <br />
            <br />이 개인정보처리방침은 2021년 01월 01일부터 적용됩니다.
            
            
            
          </p>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>