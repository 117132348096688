<template>
  <div id="enquiry">
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent @submit="enquiry">
        <div class="form-group">
          <b-form-input
            type="text"
            size="lg"
            name="name"
            class="form-control"
            placeholder="성함 *"
            v-model="name"
            :state="name.length == 0 ? null : 1 < name.length ? true : false"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="email"
            size="lg"
            class="form-control"
            placeholder="이메일 주소 *"
            name="email"
            v-model="email"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="text"
            size="lg"
            class="form-control"
            placeholder="전화 번호 *"
            name="number"
            v-model="number"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>

        <div class="form-group">
          <b-form-textarea
            id="textarea"
            size="lg"
            class="form-control"
            v-model="content"
            placeholder="문의 사항을 입력해 주십시오... *"
            rows="6"
            max-rows="10"
            :disabled="overlay"
            required
          ></b-form-textarea>
        </div>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="overlay"
        >
          <strong class="sub-title">문의하기</strong>
        </b-button>
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "enquiry",
  props: {
    userName: String,
    userEmail: String,
  },
  data: function () {
    return {
      overlay: false,
      name: "",
      number: "",
      email: "",
      content: "",
    };
  },
  mounted() {
    this.name = this.userName;
    this.email = this.userEmail;
  },
  methods: {
    enquiry: function (e) {
      e.preventDefault();
      this.overlay = true;
      const enquiry = {
        email: this.email,
        name: this.name,
        content: this.number + "\n\n" + this.content,
      };

      fetch(`${this.$apiUrl}/scanner/enquiry`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(enquiry),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            alert(
              "성공적으로 문의가 전송되었으며, 가능한 빠른 시일 내로 연락드리겠습니다."
            );
            // this.$router.push({ path: "/" });
            this.$emit("submitted");
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
          this.content = "";
        });
    },
  },
};
</script>