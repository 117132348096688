<template>
  <b-jumbotron
    :style="`background-image: url('${imageUrl}'); min-height: 20rem; background-size: cover;`"
    class="rounded-0"
  >
    <b-row class="justify-content-md-center">
      <b-col xl="10">
        <b-card
          tag="article"
          style="max-width: 27.5rem; min-height: 14rem"
          class="mb-2 justify-content-center"
        >
          <b-row class="py-3">
            <b-col>
              <b-card-text>
                {{ teamName }}, {{ userName }} 선생님 ({{
                  new Date(resultCreated * 1000).toLocaleDateString("en-US")
                }})
              </b-card-text>
              <strong>{{ classroomName }}</strong>
              <h2 class="mt-1">
                <strong>{{ name }} 의 테스트 리포트</strong>
              </h2>
              <b-card-text class="mt-2">{{ examName }} </b-card-text>
              <b-card-text class="lead mt-3">
                <b-button
                  class="mx-2"
                  v-if="url"
                  variant="outline-dark"
                  :href="url"
                >
                  <strong> 해설 다운로드 받기</strong>
                </b-button>
                <b-icon-bookmark-check class="mx-2" id="bookmark" />
                <b-popover
                  target="bookmark"
                  triggers="hover"
                  placement="bottomright"
                >
                  Updated @
                  {{ new Date(lastUpdated * 1000).toLocaleDateString("en-US") }}
                  {{ new Date(lastUpdated * 1000).toLocaleTimeString("en-US") }}
                </b-popover>
                <b-icon-share
                  @click="copyUrl"
                  class="mx-2"
                  style="cursor: pointer"
                />
                <b-icon-cloud-download
                  @click="download"
                  class="mx-2"
                  style="cursor: pointer"
                />
                <b-icon-trash
                  v-if="admin"
                  @click="deleteReport"
                  class="mx-2"
                  style="cursor: pointer"
                />
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-jumbotron>
</template>

<script>
export default {
  props: {
    url: undefined,
    teamName: String,
    userName: String,
    classroomName: String,
    examName: String,
    name: String,
    resultCreated: Number,
    lastUpdated: Number,
    imageUrl: String,
    admin: Boolean,
  },
  methods: {
    download: function () {
      alert("지원되지 않는 기능입니다.");
    },
    copyUrl: function () {
      let url = window.location.href;
      this.fallbackCopyTextToClipboard(url);
      alert("클립보드에 리포트 주소가 복사되었습니다.");
    },
    deleteReport: function() {
      this.$emit("deleteReport");
    },
    fallbackCopyTextToClipboard: function (text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>