<template>
  <div class="text-center">
    <div v-if="students.length == 0">
      <h4>학생(들)을 먼저 선택 해주십시오</h4>
    </div>
    <div v-else>
      <form @submit.prevent="edit">
        <div class="form-group">
          <b-form-tags
            v-model="tags"
            separator=" ,;"
            remove-on-delete
            size="lg"
            tag-variant="dark"
          ></b-form-tags>
        </div>

        <b-button
          block
          type="submit"
          size="lg"
          :variant="students.length == 1 ? 'dark' : 'danger'"
          :disabled="overlay"
          ><strong v-if="students.length > 1"
            >{{ students.length }}명 전체 태그 수정하기</strong
          ><strong v-else>
            {{ students[0].name }}의 태그 추가/수정하기</strong
          ></b-button
        >
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    students: Array,
  },
  mounted() {
    if (this.students.length == 1) {
      this.tags = this.students[0].tags;
    } else {
      this.tags = this.students.reduce((a, e, i) => {
        if (i == 0) {
          a = e.tags;
        } else {
          a = a.filter((tag) => e.tags.includes(tag));
        }
        return a;
      }, []);
    }
  },
  data() {
    return {
      overlay: false,
      tags: [],
    };
  },
  methods: {
    edit: function () {
      const students = {
        studentIds: this.students.map((e) => e.studentId),
        tags: this.tags,
      };
      this.overlay = true;
      let token = localStorage.getItem("token");

      console.log(students);

      fetch(`${this.$apiUrl}/scanner/student/update/tags`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(students),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            throw new Error(json.error);
          } else {
            console.log(json);
            this.$emit("edited");
          }
        })
        .catch((error) => {
          alert(error);
          this.overlay = false;
        });
    },
  },
};
</script>