<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="update">
        <div class="form-group">
          <b-form-input
            type="password"
            size="lg"
            class="form-control password"
            placeholder="현재 비밀번호 *"
            name="password"
            v-model="currentPassword"
            :disabled="overlay"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="password"
            size="lg"
            name="password"
            class="form-control password"
            placeholder="새로운 비밀번호 *"
            v-model="newPassword"
            :state="
              newPassword.length == 0
                ? null
                : 1 < newPassword.length
                ? true
                : false
            "
            :disabled="overlay"
            required
          />
        </div>

        <b-button
          block
          type="submit"
          size="lg"
          variant="dark"
          :disabled="overlay"
          ><strong>변경하기</strong></b-button
        >
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      currentPassword: "",
      newPassword: "",
    };
  },
  methods: {
    update: function () {
      this.overlay = true;

      const passwords = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };

      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/user/update/password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(passwords),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            alert("성공적으로 변경 되었습니다.");
            this.$emit("updated");
          } else {
            console.log(json);
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          console.log(error);

          alert(error);
          this.overlay = false;
          this.currentPassword = "";
          this.newPassword = "";
        });
    },
  },
};
</script>