<template>
  <div>
    <b-button
      variant="white"
      size="sm"
      id="notice"
      @click="openNotice"
      class="my-0"
    >
      <b-icon-inbox-fill />
      <b-badge variant="white">{{ unread }}</b-badge>
    </b-button>
    <b-tooltip :target="`notice`" triggers="hover" top> 공지사항 </b-tooltip>

    <!--  Notice -->
    <b-modal
      id="notice-modal"
      :ref="`notice-modal`"
      scrollable
      size="lg"
      centered
      hide-footer
      hide-header
    >
      <template #default="{ close }">
        <b-row align-h="end">
          <b-col>
            <strong class="title" style="font-size: 2rem">공지사항</strong>
          </b-col>
          <b-col class="text-right">
            <b-icon-x @click="close()" style="cursor: pointer" />
          </b-col>
        </b-row>

        <div class="mt-2">
          <a
            href="https://drive.google.com/file/d/1pw_AX-dvRPqfmgR5LVQx86WbHOzIQghY/view?usp=sharing"
            target="_blank"
            ><strong>· 조교용 OMR 매뉴얼 다운받기</strong>
          </a>
        </div>
        <div v-if="notices == null">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="m-5" label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else class="mb-1">
          <div>
            <b-card
              no-body
              v-for="(notice, noticeIdx) in notices"
              v-bind:key="noticeIdx"
              class="my-3"
            >
              <b-button block variant="white" v-b-toggle="`acc-${noticeIdx}`">
                <b-row class="text-left" align-v="center">
                  <b-col>
                    <h5>
                      <strong>{{ notice.title }}</strong>
                    </h5></b-col
                  >
                  <b-col>
                    <b-row cols="1" align-h="end" class="text-right"
                      ><b-col
                        ><small>{{ notice.from }}</small></b-col
                      >
                      <b-col
                        ><small>{{
                          new Date(notice.timestamp * 1000).toLocaleDateString()
                        }}</small></b-col
                      ></b-row
                    >
                  </b-col>
                </b-row>
              </b-button>

              <b-collapse
                :visible="noticeIdx == 0"
                :id="`acc-${noticeIdx}`"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="notice.content"></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <small>
          문제를 겪고 계시다면,
          <b-link :href="`mailto:erudiolabs@gmail.com`"
            >여기로 연락 부탁드립니다</b-link
          >:)
        </small>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notices: null,
      unread: null,
    };
  },
  computed: {
    lastActive() {
      return this.$store.getters.lastActive;
    },
  },
  watch: {
    "$route.path": function (path) {
      if (path == "/home") {
        if (this.unread > 0) {
          console.log(this.unread);
          this.openNotice();
        }
      }
    },
  },
  mounted() {
    this.getNotices();
  },
  methods: {
    openNotice: function () {
      this.unread = 0;
      this.$refs["notice-modal"].show();
    },
    getNotices: function () {
      const url = `${this.$apiUrl}/scanner/notices`;

      //   let token = localStorage.getItem("token");

      fetch(`${url}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            this.notices = json.notices;
            this.unread = this.notices.reduce((a, e) => {
              if (e.timestamp > this.lastActive) {
                a += 1;
              }
              return a;
            }, 0);

            if (this.$route.path == "/home") {
              if (this.unread > 0) {
                this.openNotice();
              }
            }
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>