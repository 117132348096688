<template>
  <div id="footer" class="py-5">
    <b-container>
      <b-row class="mx-auto text-center">
        <b-col>
          © {{ year }} Erudio Labs All rights reserved. |
          <b-link :to="{ path: '/terms' }" class="text-dark"
            >서비스이용약관</b-link
          >
          |
          <b-link :to="{ path: '/privacy' }" class="text-dark"
            >개인정보처리방침</b-link
          >
          | erudiolabs@gmail.com
        </b-col>
      </b-row>
      <b-row class="mx-auto text-center">
        <b-col>
          <small class="text-muted">
            회사명 | 에루디오 랩스 (주) · 대표 | 황성진·
            사업자 등록번호 | 650-81-02137 · 본사 | 서울 양천구 목동서로 77
            현대월드타워 2221호 · 번호 | 010-6213-6199
          </small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>