<template>
  <div>
    <div v-if="exams != null">
      <div v-if="exams.length > 0">
        <b-row align-v="center">
          <b-col class="text-left my-1" md="9">
            <h3 class="ml-3">강의 시험내역</h3>
          </b-col>
          <b-col class="text-right my-1" md="3">
            <b-form-input
              v-model="filter"
              placeholder="시험 검색"
            ></b-form-input>
          </b-col>
        </b-row>

        <hr />

        <b-row align-v="center">
          <b-col style="width: 100%; overflow-x: auto">
            <b-table hover :items="exams" :fields="fields" :filter="filter">
              <template #cell(name)="row">
                {{ row.item.name }}({{ row.item.questionNum }})
              </template>

              <template #cell(examCreated)="row">
                {{
                  new Date(row.item.examCreated * 1000).toLocaleDateString(
                    "en-US"
                  )
                }}
              </template>

              <template #cell(action)="row">
                <b-button
                  size="sm"
                  variant="white"
                  class="mx-1"
                  :id="`dl-exam-result-${row.item.examId}`"
                  @click="downloadResults(row.item.examId)"
                >
                  <b-icon-cloud-download />
                </b-button>
                <b-tooltip
                  :target="`dl-exam-result-${row.item.examId}`"
                  triggers="hover"
                  top
                >
                  결과 엑셀 다운로드
                </b-tooltip>
                <!-- <b-button
                size="sm"
                variant="danger"
                class="mx-1"
                @click="deleteExam(row.item.examId)"
              >
                <b-icon-trash />
              </b-button> -->
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <div v-else><NotFound obj="시험" /></div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import NotFound from "@/components/Exam/NotFound.vue";
const saveAs = require("file-saver");
export default {
  components: {
    NotFound,
  },
  props: {
    classroomId: String,
    examIds: Array,
    filter: String,
  },
  data() {
    return {
      examSelected: null,
      exams: null,
      fields: [
        {
          key: "code",
          label: "시험 코드",
          sortable: true,
        },
        {
          key: "name",
          label: "시험 이름",
          sortable: true,
        },

        // {
        //   key: "questionNum",
        //   label: "문항 수",
        //   sortable: true,
        // },
        {
          key: "examCreated",
          label: "생성 시점",
          sortable: true,
        },
        {
          key: "action",
          label: "액션",
        },
      ],
    };
  },
  methods: {
    downloadResults: function (examId) {
      const url = `${this.$apiUrl}/scanner/download/result`;

      let token = localStorage.getItem("token");

      fetch(url + `/exam`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ examId }),
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          saveAs(blob, `exam-${examId}.csv`);
        })
        .catch((error) => {
          alert(error);
        });
    },
    getExams: function () {
      const url = this.$url;

      let token = localStorage.getItem("token");

      fetch(url + `/api/class/team/exams/`, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ classroomId: this.classroomId }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.exams = json.exams;
          } else {
            throw new Error(json.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  mounted() {
    this.getExams();
  },
};
</script>