<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="create">
        <div class="form-group">
          <b-form-input
            type="text"
            size="lg"
            class="form-control"
            placeholder="강의실 이름 *"
            name="name"
            v-model="name"
            :disabled="overlay"
            required
          />
        </div>

        <div class="form-group">
          <b-form-input
            type="number"
            min="0"
            max="9999"
            size="lg"
            class="form-control"
            placeholder="강의실 코드 (0000 부터 9999 까지 선택) *"
            name="code"
            v-model="code"
            :disabled="overlay"
            required
          />
          <small> * 네자리 숫자가 아닌 경우 앞에 0이 생략됩니다. </small>
        </div>

        <b-button
          block
          type="submit"
          size="lg"
          variant="dark"
          :disabled="overlay || name.length < 1 || code.length < 1"
          ><strong>개설하기</strong></b-button
        >
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "createClassroom",
  data: function () {
    return {
      name: "",
      code: "",
      overlay: false,
    };
  },
  methods: {
    create: function (e) {
      const url = this.$url;

      this.overlay = true;
      e.preventDefault();

      const classroom = {
        name: this.name,
        code: parseInt(this.code).toString(),
      };

      let token = localStorage.getItem("token");

      fetch(url + "/api/class/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(classroom),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            throw new Error(json.error);
          } else {
            console.log(json);
            this.$emit("added");
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          this.overlay = false;
          this.name = "";
          this.code = "";
        });
    },
  },
};
</script>
