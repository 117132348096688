<template>
  <div id="app">
    <Navbar />
    <router-view v-if="tokenChecked" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import ChannelService from "./ChannelService.js";

export default {
  components: {
    Navbar,
  },
  created() {
    console.log("created", this.$apiUrl);
    console.log("created", this.$url);
  },
  computed: {
    tokenChecked() {
      return this.$store.getters.tokenChecked;
    },

    name() {
      return this.$store.getters.name;
    },

    email() {
      return this.$store.getters.email;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (this.name != false && this.email != false) {
      ChannelService.boot({
        pluginKey: "46970e6f-ad3f-456a-bdbe-577520715687", //please fill with your plugin key
        profile: {
          name: `${this.name} (${this.email})`, //fill with user name
          email: this.email, //fill with user phone number
        },
      });
    } else {
      ChannelService.boot({
        pluginKey: "46970e6f-ad3f-456a-bdbe-577520715687",
      });
    }
  },
  methods: {},
  beforeCreate() {
    const token = localStorage.getItem("token");
    if (token) {
      this.$store
        .dispatch("verifyTokenAction", { token })
        .then(() => {
          this.$store.dispatch("setTokenChecked", true);
        })
        .catch((res) => {
          console.log(res);
          location.reload();
        });
    } else {
      this.$store.dispatch("setTokenChecked", true);
    }
  },
};
</script>



<style>
@import "./app.css";
</style>