import Vue from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$url = ["8080", "3000"].includes(window.location.port) ?
    `${window.location.protocol}//localhost:3000` :
    `${window.location.protocol}//scan.erudiolabs.com`

Vue.prototype.$apiUrl = ["8080", "3000"].includes(window.location.port) ?
    `${window.location.protocol}//${window.location.hostname}:3030/v1` :
    `${window.location.protocol}//api.erudiolabs.com/v1`

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')