<template>
    <div>
        <b-container fluid class="my-3 text-center">
          <b-row align-h="center" style="min-height: 80vh">
            <b-col lg="4" class="text-center my-auto">
                <Scan />

            </b-col>
          </b-row>
        </b-container>
    </div>
</template>

<script>
import Scan from "@/components/Demo/Scan.vue"
export default {
    components: {
        Scan
    }
   
}
</script>