<template>
  <div>
    <b-overlay :show="overlay" rounded="sm" style="margin-bottom: 10px">
      <form @submit.prevent="claim">
        <div class="form-group">
          <b-form-input
            type="text"
            size="lg"
            class="form-control"
            placeholder="쿠폰 코드 *"
            name="code"
            v-model="code"
            :disabled="overlay"
            autocomplete="on"
            required
          />
        </div>
        <b-button
          block
          type="submit"
          size="lg"
          variant="dark"
          :disabled="overlay"
          ><strong>사용하기</strong></b-button
        >
      </form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "claimCoupon",
  data: function () {
    return {
      code: "",
      overlay: false,
    };
  },
  methods: {
    claim: function (e) {
      this.overlay = true;
      e.preventDefault();

      const code = {
        code: this.code,
      };

      const token = localStorage.getItem("token");

      fetch(`${this.$apiUrl}/scanner/claim/coupon`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify(code),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            throw new Error(json.error);
          } else {
            alert(`Coin Wallet에 ${json.coupon.value} 코인이 추가되었습니다.`);
            this.$emit("claimed");
            this.overlay = false;
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          this.code = "";
          this.overlay = false;
        });
    },
  },
};
</script>
