<template>
  <div class="text-center">
    <b-overlay
      :show="overlay"
      rounded="sm"
      style="margin-bottom: 10px"
      variant="white"
    >
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..."></b-spinner>
          <div class="mt-3" v-if="file != null">
            <h5 id="cancel-label" class="px-4">스캔 중 ...</h5>
            <b-progress
              :value="100"
              striped
              animated
              variant="dark"
            ></b-progress>
          </div>
        </div>
      </template>

      <b-form @submit="scan" @submit.prevent>
        <b-form-group>
          <!-- Styled -->
          <b-form-file
            class="mt-1 text-left"
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
            :capture="camera"
          ></b-form-file>

          <!-- <div class="mt-3">Selected file: {{ file ? file.name : "" }}</div> -->
        </b-form-group>

        <p class="text-left">
          <small
            >* 측면 사진, 혹은 어두운 환경일 시 스캔 결과가 부정확할 수 있으니,
            밝은 환경에서 정면 사진을 찍어주시기 바랍니다. | {{ ip }}
          </small>
        </p>

        <b-button
          block
          type="submit"
          variant="dark"
          size="lg"
          :disabled="file == null || overlay"
        >
          <strong class="sub-title">답안 제출하기</strong>
        </b-button>
        <small class="form-text text-muted text-right" style="font-size: 9px">
          제출함과 동시에, Erudio Labs의
          <b-link :to="{ path: '/terms' }" class="text-dark"
            >서비스이용약관</b-link
          >과
          <b-link :to="{ path: '/privacy' }" class="text-dark"
            >개인정보처리방침</b-link
          >에 동의 합니다
        </small>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    ip: String,
    student: Object,
    camera: Boolean,
    classroomId: String,
  },
  data() {
    return {
      overlay: false,
      processed: 0,
      file: null,
    };
  },
  mounted() {},
  methods: {
    scan: function () {
      this.overlay = true;
      this.uploadFile(this.file);
    },
    uploadFile: function (file) {
      if (file != null) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("name", file.name);
        formData.append("createdBy", this.ip);
        formData.append("studentId", this.student.studentId);

        fetch(`${this.$apiUrl}/scanner/scan/${this.classroomId}`, {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((json) => {
            if (!json.error) {
              console.log(json);
              alert("제출 되었습니다.");

              this.$bvModal
                .msgBoxConfirm("해당 시험지의 결과를 바로 확인 하시겠습니까?", {
                  title: "Please Confirm",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "success",
                  okTitle: "확인하기",
                  cancelTitle: "취소",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                  centered: true,
                })
                .then((res) => {
                  if (res) {
                    window.open(`/result?id=${json.resultId}`);
                  }
                });
              this.overlay = false;
              this.file = null;
            } else {
              throw new Error(json.error);
            }
          })
          .catch((error) => {
            alert(error);
            console.log(error);
            this.overlay = false;
            this.file = null;
          });
      }
    },
  },
};
</script>