<template>
  <b-jumbotron
    style="min-height: 35vh; padding-top: 9.5vh; padding-bottom: 5vh; margin: 0"
    class="main-jumbotron rounded-0 text-white"
  >
    <b-modal id="class-scan-modal" centered hide-footer>
      <template v-slot:modal-title>
        <h3>강의실 QR 코드 스캔</h3>
      </template>
      <ClassQRScan />
    </b-modal>
    <b-row
      class="justify-content-md-center"
      style="animation: slideinleft 1s, fadein 3s"
    >
      <b-col lg="10">
        <div style="max-width: 50rem" class="my-auto text-left">
          <div class="mb-3" >
            <b-button variant="outline-light"
            v-b-modal.class-scan-modal
              ><strong> 강의실 QR 스캔하기 </strong>
              <b-icon-arrow-right-circle-fill class="ml-2" />
            </b-button>
          </div>

          <p class="lead">시험 채점 및 관리의 패러다임 변화</p>
          <h1 style="font-size: calc(21px + 3.5vw)">
            <strong class="title"
              >손쉬운 채점,<br />
              OMR 스캔</strong
            >
          </h1>
          <!-- <hr /> -->
          <div class="my-4">
            <h4 class="mb-2">
              <strong class="sub-title">
                힘들고 오래 걸리는 채점, 이젠 모든 기기를 통해 간편 스캔하고
                공유하세요.
              </strong>
            </h4>

            <!-- <p>
                OMR 스캔은 시험 결과를 손쉽게 채점 가능하게 해주는 서비스를
                시작으로, 사용자들과의 지속적인 소통을 통한 업데이트를 실현하며
                원활한 UX(사용자 경험)에 대한 많은 투자를 진행하고 있습니다.
                또한, 인공지능을 이용한 여러가지 데이터 자체 분석 툴들을 활용을
                통한 리포트 제작, 학생들에게 체계적 관리 제공으로 학습 효율성
                향상을 가능하게 도와줍니다.
              </p> -->
          </div>

          <b-button-group class="mt-2" v-if="!email">
            <b-button variant="light" size="lg" v-b-modal.partner-register
              ><strong>무료가입 후 간편 스캔하기</strong></b-button
            >
            <b-button variant="outline-light" size="lg" v-b-modal.partner-login
              ><strong>로그인</strong></b-button
            >
          </b-button-group>
          <b-button-group class="mt-2" v-else>
            <b-button
              variant="light"
              size="lg"
              @click="$router.push({ path: '/home' })"
              ><strong>관리자 페이지로 이동</strong></b-button
            >
          </b-button-group>
        </div>
      </b-col>
    </b-row>

    <Stats />
  </b-jumbotron>
</template>

<script>
import ClassQRScan from "@/components/Landing/ClassQRScan.vue"
import Stats from "@/components/Landing/Stats.vue";

export default {
  components: {
    ClassQRScan,
    Stats,
  },
  props: {
    email: undefined,
  },
};
</script>