<template>
  <div>
        <b-row align-v="center">
          <b-col>
            <img
              src="@/assets/icons/contacts.svg"
              class="mb-2"
              width="150rem"
              height="150rem"
            />
            <h3 class="my-2">
              <strong>
                학생 정답 DB 구축을 통한 데이터 분석, 지금 시작하세요.
              </strong>
            </h3>
            <b-row align-h="center">
              <b-col class="text-right">
            <b-button
              variant="primary"
              size="lg"
              class="my-3"
              v-b-modal.import-students
              >학생정보 추가하기</b-button
            >
              </b-col>
              <b-col class="text-left">
                <b-button
              variant="success"
              size="lg"
              class="my-3"
              v-b-modal.claim-coupon
              ><b-icon-gift-fill class="mr-2"/> 쿠폰 사용하기</b-button
            >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
  </div>
</template>

<script>

export default {
  methods: {
    

  }
};
</script>