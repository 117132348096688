<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object


    let gradient = this.$refs.canvas
    .getContext('2d')
    .createLinearGradient(0, 0, 0, 450)

    gradient.addColorStop(0, 'rgba(255, 0,0, 0.8)')
    gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 0.15)');


    this.chartData.datasets[0].backgroundColor = gradient
    this.renderChart(this.chartData, this.options);
    
  },
};
</script>