<template>
  <div>
    <div class="text-center">
      <div v-if="mistakes == null">
        <b-skeleton-wrapper :loading="mistakes == null">
          <template #loading>
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </template>
        </b-skeleton-wrapper>
      </div>

      <div v-else-if="mistakes != null && mistakes.length != 0">
        <b-table
          striped
          hover
          :items="mistakes"
          :fields="['category', 'desc']"
        ></b-table>
      </div>
      <div v-else-if="mistakes.length == 0" class="mb-3">
        <strong>오답이 없습니다! </strong>
      </div>
    </div>
    <small>
      문제를 겪고 계시다면,
      <b-link :href="`mailto:erudiolabs@gmail.com`"
        >여기로 연락 부탁드립니다</b-link
      >:)
    </small>
  </div>
</template>

<script>
export default {
  props: {
    resultId: String,
  },
  data() {
    return {
      mistakes: null,
    };
  },
  mounted() {
    console.log("hello");
    this.showStudentMistakes(this.resultId);
  },
  methods: {
    arraysEqual: function (a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    showStudentMistakes: function (resultId) {
      console.log(resultId);
      const url = this.$url;

      fetch(`${url}/api/result/exam/${resultId}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (!json.error) {
            console.log(json);
            this.data = json;

            this.mistakes = this.data.exam.answers
              .filter((e, idx) => {
                if (
                  this.arraysEqual(
                    e.questionAnswer,
                    this.data.result.answersChosen[idx]
                  )
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              .map((e) => {
                return { category: e.questionCategory, desc: e.desc };
              });

            // this.mistakes = this.data.exam.answers.filter((e,idx) => {
            //   if(this.arraysEqual(this.data.result.answersChosen[idx], e.questionAnswer)){
            //     return true
            //   }
            // }).map(e => {return {category: e.questionCategory, desc: e.desc}})

            console.log(this.mistakes);
          }
        });
    },
  },
};
</script>

